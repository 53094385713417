// React imports
import React, { useState, useContext } from 'react';

// Material imports
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

// project imports
import { OrganizationContext } from '../../../utils/OrganizationContext.js';


export default function OrganizationName({organization=null}) {
  const [currentOrganization, setCurrentOrganization] = useContext(OrganizationContext);
	const [displayOrg, setDisplayOrg] = useState(organization==null ? currentOrganization : organization)

	if (currentOrganization === null && organization === null ) {
		return ;
	}

	return (
		<React.Fragment>
			{(displayOrg!=null && displayOrg!=undefined)&&
			<Grid container direction='row' alignItems='center'>
				<Box sx={{marginTop:1, marginLeft:1, paddingRight: 1}}>
					<img src={displayOrg.darkLogo} alt='Logo'  height={24} />
				</Box>
				<Box >
					<Typography variant='h1'>{displayOrg.name}</Typography>
				</Box>
			</Grid>
			}
		</React.Fragment>
	)
}