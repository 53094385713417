export const primaryColors = {
	spotlight: '#D8471E',
	curtain: '#BE7444',
	shadow: '#170507',
	stage: '#D7B489',
	night: '#111D78'
}

export const spotlightColor = {
	10: '#1B0904',
	20: '#511A0B',
	30: '#872C12',
	40: '#BC3D1A',
	50: '#D8471E',
	60: '#E77150',
	70: '#EB886D',
	80: '#F3B8A7',
	90: '#FBE7E2'
}

export const curtainColor = {
	10: '#301D11',
	20: '#603B22',
	30: '#905833',
	40: '#BE7444',
	50: '#C6875D',
	60: '#D6A98B',
	70: '#DFBBA3',
	80: '#EFDDD1',
	90: '#fcf4eb'
}

export const shadowColor = {
	10: '#0A0203',
	20: '#170507',
	30: '#470F16',
	40: '#781A25',
	50: '#AA2535',
	60: '#D3384A',
	70: '#DE6A78',
	80: '#E99CA5',
	90: '#F4CDD2'
}

export const stageColor = {
	10: '#422E16',
	20: '#624522',
	30: '#835C2D',
	40: '#A47338',
	50: '#CB9F68',
	60: '#D7B489',
	70: '#E1C7A7',
	80: '#F1E3D3',
	90: '#FAF6F0'
}

export const nightColor = {
	10: '#04071E',
	20: '#0B124B',
	30: '#111D78',
	40: '#1D31CA',
	50: '#167AF3',
	60: '#519BF6',
	70: '#8BBCF9',
	80: '#C5DEFC',
	90: '#ECF4Fe'
}

export const beachColor = {
	10: '#052F39',
	20: '#095E71',
	30: '#0C7E97',
	40: '#0F9DBD',
	50: '#11ADD0',
	60: '#1AC8ED',
	70: '#68D9F3',
	80: '#B4ECF9',
	90: '#ECFAFD'
}

export const errorColor = {
	10: '#330000',
	20: '#650101',
	30: '#980101',
	40: '#CA0202',
	50: '#FD0202',
	60: '#FD3535',
	70: '#FE6767',
	80: '#FFCCCC',
	90: '#FFE6E6'
}

export const successColor = {
	10: '#003302',
	20: '#016505',
	30: '#019807',
	40: '#02CA0A',
	50: '#02FD0C',
	60: '#35FD3D',
	70: '#9AFE9E',
	80: '#CCFFCE',
	90: '#E6FFE7'
}

export const monoColor = {
	'black': '#000000',
	10: '#1A1A1A',
	20: '#333333',
	30: '#4D4D4D',
	40: '#666666',
	50: '#808080',
	60: '#999999',
	70: '#B3B3B3',
	80: '#CCCCCC',
	90: '#E6E6E6',
	95: '#F2F2F2',
	'white': '#FFFFFF'
}