// Material
import { Grid, TextField, Typography } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';

// react
import { useContext, useState } from "react";

// project
import ArtiztaIconButton from "../../../common/ArtiztaIconButton";
import { AuthContext } from "../../../utils/AuthContext";
import PeopleClient from "../../people/PeopleClient";
import ArtiztaSnackbar from "../../../common/ArtiztaSnackbar";
import RecommendationClient from "../../../utils/RecommendationsClient";


export default function OpenAISettings() {
  // context
  const [ currentUser, setCurrentUser ] = useContext(AuthContext)
  
  // state
  const [ isLoading, setIsLoading ] = useState(false)
  const [ saveSettingsDisabled, setSaveSettingsDisabled ] = useState((currentUser.person.settings == undefined || currentUser.person.settings == null) ? false : true);
  const [ openSnackbar, setOpenSnackbar] = useState(false)
  const [ snackbarTitle, setSnackbarTitle ] = useState("")
  const [ snackbarAlert, setSnackbarAlert ] = useState("success")

  // Make save button disabled if there is no changes
  const handleSettingsInputChange = () => {
		const openAiAPIKey = document.getElementById("oaAPIKey").value;
    if (currentUser.person.settings != undefined || currentUser.person.settings != null) {
		  setSaveSettingsDisabled((openAiAPIKey === currentUser.person.settings.openAiAPIKey));
    }
	}

  // Saving Daniels Online
  const handleDanielsOnlineSettingSave = async () => {
    setIsLoading(true)
		const theAPIKey = document.getElementById("oaAPIKey").value;
    RecommendationClient.validate(theAPIKey)
      .then(response => {
        console.log(response)
        if (response == true){
          // Save the person settings
          const person = currentUser.person;

          if (person.settings === null || person.settings === undefined) {
            person.settings = {
              openAiAPIKey: theAPIKey
            }
          }
          else {
            person.settings.openAiAPIKey = theAPIKey;
          }

          // save the person... 
          setCurrentUser(currentUser)
          PeopleClient.update(currentUser.person);
          setSaveSettingsDisabled(true)

          // Snackbar
          setSnackbarTitle("Success, credentials are valid")
          setSnackbarAlert("success")
          setOpenSnackbar(true)
        }else{
          // Show error message
          setSnackbarTitle("Credentials Failed to validate")
          setSnackbarAlert('error')
          setOpenSnackbar(true)
        }
        setIsLoading(false)
      })
		
	}

  return (
    <Grid container direction={'row'} spacing={2} alignItems={'center'}>
      <Grid item>
        <Typography>OpenAI API Key</Typography>
        <TextField 
          id="oaAPIKey"
          name="oaAPIKey"
          onChange={handleSettingsInputChange}
          error={snackbarAlert=='error'}
          label={snackbarAlert=='error'?'Invalid':""}
          defaultValue={currentUser.person.settings !== undefined ? currentUser.person.settings.openAiAPIKey : null}
        />
      </Grid>
      <Grid item>
      <ArtiztaIconButton toolTip={"Save"} onClick={handleDanielsOnlineSettingSave} disabled={isLoading}>
          <CheckIcon />
        </ArtiztaIconButton>
      </Grid>

      {/* Snackbar */}
			<ArtiztaSnackbar message={snackbarTitle} open={openSnackbar} setOpen={setOpenSnackbar} alert={snackbarAlert}/>
    </Grid>
  )
}