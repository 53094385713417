// Material Imports
import { Autocomplete, Box, Button, Chip, Divider, Grid, Stack, TextField } from "@mui/material";

// React Imports
import React, { useContext, useState } from "react";

// Project Imports
import { SettingsContext } from "../../../utils/SettingsContext";
import ArtiztaBorderBox from "../../../common/ArtiztaBorderBox";


export default function PerformanceSkillsSelector(props) {
  // Context
  const [settings, setSettings, performanceSkills, setPerformanceSkills] = useContext(SettingsContext);
  // const [selectedPerformanceSkills, setSelectedPerformanceSkills] = useState([])

  // Helper to handling the skills
  function handleSkills(section, item) {
    for (let i in props.selectedPerformanceSkills){
      if (props.selectedPerformanceSkills[i]['skill'] === item){return}
    }
    const newSkill = {
      'section': section,
      'skill': item
    }
    props.setSelectedPerformanceSkills([...props.selectedPerformanceSkills, newSkill])
  }
  
  // Helper to Disable buttons
  // function checkSkills(skill) {
  //   for (let i in performanceSkills){
  //     if (performanceSkills[i]['skill'] === skill){return true}
  //   } 
  //   return false
  // }

  // helper for removing performance skills
  function handleDelete(skill) {
    props.setSelectedPerformanceSkills(props.selectedPerformanceSkills.filter(e => e !== skill))
  }


  return (
    <React.Fragment>
      <Grid item xs={8}> 
        <h3>Performance Skills</h3>
        <Autocomplete 
          options={performanceSkills}
          groupBy={(option) => option.category}
          getOptionLabel={(option) => option.skill}
          renderInput={(params) => <TextField {...params} label="Performance Skills" />}
          onChange={(event, newValue) => {
            if (newValue != null) {handleSkills(newValue.category, newValue.skill)}
          }}
        />
        <ArtiztaBorderBox>
          {
            
            (props.selectedPerformanceSkills.length == 0) &&
            <i>No performance skills selected</i>
          }
          {
            props.selectedPerformanceSkills.map(function(skill){
              return (
                <Chip sx={{m:1}} key={skill.skill} label={skill.skill} onDelete={() => handleDelete(skill)}/>
              )
            })
          }
          {/* <Chip sx={{m:1}} key={'key'} label="sacsophone" onDelete={() => handleDelete("skill")}/>
          <Chip sx={{m:1}} key={'key'} label="floot" onDelete={() => handleDelete("skill")}/>
          <Chip sx={{m:1}} key={'key'} label="ohbow" onDelete={() => handleDelete("skill")}/> */}
        </ArtiztaBorderBox>
      </Grid>
      
    </React.Fragment>


    // <Grid container sx={{marginTop: '2em'}}>
    //   <Grid container justifyContent={'space-between'}>
    //     <h3>Select Performance Skills</h3>

    //     Displays the skills
    //     <Stack direction={"row"} spacing={1}>
    //       {
    //         performanceSkills.map(function(skill){
    //           return (
    //             <Chip key={skill['skill']} label={skill['skill']} onDelete={() => handleDelete(skill)} />
    //           )
    //         })
    //       }
    //     </Stack>
    //   </Grid>
      
    //   The Skill Selector
    //   { 
    //     settings[0]['content']['performanceSkills'].map(function(category){
    //       return (
    //         <div key={category[Object.keys(category)[0]]}>
    //           <h4 >{Object.keys(category)[0]}</h4>
    //           {
    //             category[Object.keys(category)[0]].map(function(item){
    //               return (
    //                 <Button 
    //                   sx={{fontWeight: 'regular', margin:'0.5em'}} 
    //                   key={item} 
    //                   onClick={() => handleSkills(Object.keys(category)[0],item)} 
    //                   value={item} 
    //                   disabled={checkSkills(item)}
    //                 >
    //                   {item}
    //                 </Button>
    //               )
    //             })
    //           }
    //           <Divider />
    //         </div>
    //       )
    //     })
    //   }
    // </Grid>
  )
}