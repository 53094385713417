import {v4 as uuidv4} from 'uuid';

// Firebase
import { getStorage, ref, uploadBytes, getDownloadURL, listAll } from 'firebase/storage';

/*
	ImageUploadAPI - used to upload images to Firestore.
	  upload() - uploads a file to firestore and returns the public URL in the callback function
		   - file - the file, need to be a file type. 
			 - urlCallback - OPTIONAL - will be called with the image URL
	Here is an example with 'handleFileCapture' as the 'onChange' event for a file input:
		const handleFileCapture = ({ target }) => {
			const fileReader = new FileReader();
			const theFile = files[0];
			ImageUploadAPI.upload(theFile, (url) => {
				// url will contain the public firebase url, do something with it
			});
*/
const addSeparators = (location) => {
	if (location === undefined || location === null ) {
		return '/';
	}
	var properLocation = location;
	if (! properLocation.startsWith('/')) {
		properLocation = '/'+properLocation;
	}
	if (! properLocation.endsWith('/')) {
		properLocation = properLocation+'/';
	}
	return properLocation;
}

export class FirebaseFileClient {
	static upload(file, location, urlCallback=null) {

		// calculate a unique name
		const id = uuidv4();
		const parts = file.name.split('.');
		const extension = '.'+parts[parts.length-1];
		const properLocation = addSeparators(location);
		const fileName = properLocation+id+extension;
		const metaData = {contentType: file.type};
		const storage = getStorage();
		const storageRef = ref(storage, fileName);
		uploadBytes(storageRef, file, metaData)
			.then(result => {
				getDownloadURL(result.ref).then(url => {
					if (urlCallback !== null) {
						urlCallback(url);
					}
				});
			})
			.catch(error => {
				console.log(error);
			});
	}

	/**
	 * sends a list of filenames (array of strings) to the callback function
	 */
	static getFilenames = async (location) => {

	const properLocation = addSeparators(location);
	const storage = getStorage();
	const listRef = ref(storage, properLocation);
	const returnVal = [];
	const response = await listAll(listRef);
	response.items.forEach((item) =>{
		returnVal.push(item.name);
	});

	return returnVal;

	}

	/**
	 * sends an array of json to the callback function
	 * each item will have {filename: 'thefile name', content: {json contents}}
	 */
	static getJSONFiles = async (location) => {

		const returnValue = [];
		const filenames = await FirebaseFileClient.getFilenames(location);
		for (let i=0; i<filenames.length; i++) {
			const filename = filenames[i];
			const json = await FirebaseFileClient.getJSONForFilename(location,filename)
			returnValue.push({filename:filename, content:json});
		}

		return returnValue;
	}

	static getJSONForFilename = async (location, name) => {
		const properLocation = addSeparators(location)+name;
		const storage = getStorage();
		const fileRef = ref(storage, properLocation);

		try {
			const url = await getDownloadURL(fileRef)
			const response = await fetch(url)
			const json = await response.json()
			return json;
		}
		catch (error) {
			console.log('caught an error', error);
			return null;
		}
	}
}