import React, {useState, useEffect, useContext} from 'react';
import {Link, useLocation} from 'react-router-dom';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { Avatar, Button, AppBar, Toolbar, Hidden, Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

//import {Menu, MenuItem, ListItemText, ListItemIcon, Divider, SwipeableDrawer, IconButton} from '@mui/material';
//import useMediaQuery from '@mui/material/useMediaQuery';
//icons
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';

// project files
import ArtiztaMenuDrawer from './ArtiztaMenuDrawer';
import ArtiztaTabMenu from './ArtiztaTabMenu';
import ArtiztaLogoButton from './ArtiztaLogoButton';
import LoginWindow from '../pages/login/LoginWindow';
import ContentBackground from '../common/ContentBackground';
import {AuthContext} from '../utils/AuthContext';
import { OrganizationContext } from '../utils/OrganizationContext.js';
import FeedbackButton from './FeedbackButton.js';
import { SettingsContext } from '../utils/SettingsContext.js';
import { LocationsContext } from '../App.js';
import SummerProgressions from '../pages/seasonal/SummerProgressions.js';

function ElevationScroll(props) {
    const { children } = props;
 
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0
    });
  
    return React.cloneElement(children, {
      elevation: trigger ? 8 : 0,
    });
  }


// Component
export default function ArtiztaHeader(props) {
    //const classes = useStyles();
    const theme = useTheme();
    // Responsivness
    //const matches = useMediaQuery(theme.breakpoints.down('md'));

    // State
    const [currentTab, setCurrentTab] = useState(0);
    const [currentUser, setCurrentUser] = useContext(AuthContext);
		const [currentOrganization, setCurrentOrganization] = useContext(OrganizationContext);
		const [currentSettings, setCurrentSettings] = useContext(SettingsContext);
		const [performanceSkills, setPerformanceSkills] = useContext(SettingsContext);
		const [locations, setLocations] = useContext(LocationsContext);

    const location = useLocation().pathname



		const handleLogout = () => {
			setCurrentUser(null);
			setCurrentOrganization(null);
			setLocations(null);
			setPerformanceSkills(null);
			setCurrentSettings(null);
		}

    const handleChange = (e, value) => {
      setCurrentTab(value);
    }

    useEffect(() => {
      switch (window.location.pathname) {
        case '/':
          setCurrentTab(0); 
          break;
        case '/organizations':
          setCurrentTab(1); 
          break;
        case '/locations':
          setCurrentTab(1); 
          break;
        case '/people':
          setCurrentTab(2); 
          break;        
        case '/events':
          setCurrentTab(3); 
          break;
				case '/seasonDesigner':
					setCurrentTab(4); 
					break;        
        case '/works':
          setCurrentTab(5); 
          break;
        case '/settings':
          setCurrentTab(6); 
          break;
        default:
          break;
      }
    }, [currentTab]);

    // UI
    return (
      <React.Fragment >
				{currentUser !== null && currentUser !== undefined &&
					<ElevationScroll >
            <AppBar position="fixed" color="secondary" sx={{
                zIndex: theme.zIndex.modal + 1,
                px:'0em'}}>
                <Toolbar sx={{
                    ...theme.mixins.toolbar,
                    marginTop: '.5em',
                    marginBottom: '.5em',
                    paddingLeft:'0em',
                    marginLeft:'0em',
                    [theme.breakpoints.down('md')]: { marginBottom:'.0em', marginTop: '0em'},
                    [theme.breakpoints.down('sm')]: { marginBottom:'0em', marginTop: '0em'}}}>
                  {(currentUser !== null) &&
                    <Hidden lgUp>
                      <ArtiztaMenuDrawer onChange={handleChange} currentValue={currentTab}/>
                    </Hidden>
                  }
                  <ArtiztaLogoButton action={()=>setCurrentTab(0)}/>
									<SummerProgressions />
                  {(currentUser !== null) &&
                    <Hidden lgDown>
                      <ArtiztaTabMenu onChange={handleChange} currentValue={currentTab}/>
                    </Hidden>
                  }

                  {currentUser === null ? 
                    <LoginWindow/> 
                  : 
                    <Grid container sx={{marginLeft:'auto'}} spacing='1em' alignItems='center'>
                      <FeedbackButton currentUser={currentUser} location={location}/>
                      <Grid>
                        {(currentOrganization !== null) &&
													<img src={currentOrganization.lightLogo} alt='Logo'  height={24} />
												}
                      </Grid>
                      <Grid >
												{currentUser.user !== null && currentUser.user !== undefined &&
													<Avatar src={currentUser.user.photoURL} />
												}
                      </Grid>
                      <Grid >
                        <Button variant="contained" color="primary" component={Link} to='/' sx={{borderRadius:10}} onClick={handleLogout}>
                          <LogoutRoundedIcon/><Typography sx={{paddingLeft:'0.5em'}}><b>Logout</b></Typography>
                        </Button>
                      </Grid>
                    </Grid>
                  }
                </Toolbar>
            </AppBar>
					</ElevationScroll>
				}
        <ContentBackground/>
      </React.Fragment>
    )
}