// Material
import { Grid, List, Typography } from "@mui/material"

// Project
import PeopleCell from "./PeopleCell";
import { OrganizationContext } from "../../../utils/OrganizationContext";

// React
import React, { useContext, useEffect, useState } from "react";
import OrganizationName from "../../organizations/components/OrganizationName";


export default function PeopleListOrg({ onDelete }) {
  // Context 
  const [currentOrganization, setCurrentOrganization] = useContext(OrganizationContext)
  const [peopleList, setPeopleList] = useState(currentOrganization.people);

	useEffect(() => {
    // Get Key people to show up first
    var sortedList = []
    var keyPersonIndexs = []

    // Find the positions of the key people
		if (currentOrganization.people !== undefined && currentOrganization.people !==null){
			for (var i in currentOrganization.people){
				if (currentOrganization.people[i].isKeyPerson == true){
					keyPersonIndexs.push(i)
				}
			}
			if (keyPersonIndexs.length == 0){  // if there's no key people, don't worry about it
				sortedList = currentOrganization.people 
			}else{
				// Put the key people in first
				for (var k in keyPersonIndexs) {
					sortedList.push(currentOrganization.people[keyPersonIndexs[k]])
				}
				// Put the rest in - skipping the key people
				for (var p in currentOrganization.people){
					if (keyPersonIndexs.includes(p) == false){
						sortedList.push(currentOrganization.people[p])
					}
				}
			}
		}

    // Set the state
		setPeopleList(sortedList);
	}, [currentOrganization]);
  // Add new peron (now on people page)
  // const [open, setOpen] = useState(false)
  // // const handleClickOpen = () => {
  // //   setOpen(true);
  // // }

  // // function setPeopleList(){

  // // }

  return (
    <React.Fragment>
      <Grid container justifyContent='flex-end' alignItems='center' direction='row'>
        {/* <TextField placeholder='search' sx={{'marginTop':'1em'}}/> */}
        {/* <p>Sort bar placeholder</p> */}
        <OrganizationName />
      </Grid>
      
      {/* Check if people exist */}
      {
        (currentOrganization.people == undefined) ?
        <Typography>No People</Typography>
        :
        <List>
        {peopleList.map(function(person) {
          return (
            <PeopleCell key={person.id} person={person} onDelete={onDelete} />
          )
        })}
      </List>
      }
      
    </React.Fragment>
  )
}
