// React imports
import React, {useState} from 'react';

// material
import { 
	Box, 
	Typography, 
	Grid,
	Accordion,
	AccordionSummary,
	AccordionDetails } from '@mui/material'

// project imports

/**
 * Displays an AccordionTable with data and custom detail component
 * @param {[]} data the data to be displayed, expecting an array of dictionaries
 * @param {string} rowKey optional - the unique key for each row in the data. If not spedified, expects a key named 'key'
 * @param {[]} columns array of the columns to be displayed. 
 * @param {integer} columns.xs the xs value for the column, 1-12
 * @param {string} column.headerText the text for the header for the column
 * @param {string} column.cell the key for the column value in the data array
 * @param {function} column.headerComponent optional function that will return the component to be rendered for the column header. If not supplied, will render the headerText
 * @param {function} column.cellComponent optional function that will return the component to be rendered for the column header. If not supplied, will render the column.cell
 */
export default function AccordionTable(props) {

	const [selectedRow, setSelectedRow] = useState(null);

	const handleClick = (event, row) => {
		setSelectedRow(row);
	};

	const isRowSelected = (id) => { 
		if (selectedRow !== null && selectedRow.key === id) {
			return true;
		}
		return false;
	}

	const AccordionTableHeader = (props) => {

		return (
			<Grid item container direction='row' > 
				{props.columns.map((column)=>{ return (
					<Grid item key={column.cell} sx={{p:0}} xs={column.xs}>
						<Box >
							{column.headerComponent !== null && column.headerComponent !== undefined ? 
								column.headerComponent(column)
							:
								<Box sx={{paddingLeft:'.5em'}}>
									<Typography ><b>{column.headerText}</b></Typography>
								</Box>
							}
						</Box>
					</Grid>
				)})}
			</Grid>
		);
	}

	const AccordionTableRow = (props) => {
		const row = props.row;

		return (
			<Box sx={{ borderTop:1}} >
				<Accordion elevation={0} border={1} disableGutters={true} >
					<AccordionSummary
						sx={{ cursor: 'pointer', m:0, s:0, p:0 }} 
						>
						{props.columns.map((column)=> { return (
							<Grid item key={column.cell}  xs={column.xs} >
								{column.cellComponent !== null && column.cellComponent !== undefined ?
									column.cellComponent(row)
								:
									<Box sx={{paddingLeft:'.5em'}}>
										<Typography>{row[column.cell]}</Typography>
									</Box>
								}
							</Grid>
						)})}
					</AccordionSummary>
					<AccordionDetails sx={{m:0, p:0}}>
						{typeof props.detailsPanel === 'function' &&
							props.detailsPanel(row)
						}
					</AccordionDetails>
				</Accordion>
			</Box>
		)
	}

	return (
		<Box border={1} >
			<Grid container direction='column' >
				<AccordionTableHeader columns={props.columns}/>
				{props.data.map((row)=>{ 
					const key = (props.rowKey !== null && props.rowKey !== undefined) ? props.rowKey : 'key';

					return (
						<AccordionTableRow 
							columns={props.columns} 
							data={props.data} 
							row={row} 
							detailsPanel={props.detailsPanel}
							key={row[key]}/>
				)})}
			</Grid>
		</Box>
	)
}