import React, { useState, useEffect } from 'react';

// material
import { Dialog, DialogContent, DialogTitle } from '@mui/material';

// project imports
import ArtiztaEditComponent from './ArtiztaEditComponent.js';

export default function ArtiztaEditDialog(props) {
	const [showDialog, setShowDialog] = useState(false);

	const newProps = {
		...props,
		item: props.item,
		onSave: (item) => {
			if (props.onSave !== null && props.onSave !== undefined) {
				props.onSave(item);
			}
		},
		onCancel: (item) => {
			if (props.onCancel !== null && props.onCancel !== undefined) {
				props.onCancel(item);
			}
		}
	}

	useEffect(() => {
		setShowDialog(props.item !== null);
	}, [props.item]);

	return (
		<React.Fragment>
			{props.item !== null &&
				<Dialog open={showDialog} maxWidth={(props.maxWidth==undefined) ? 'xl' : props.maxWidth} fullWidth>
					<DialogContent>
						<ArtiztaEditComponent {...newProps}/>
					</DialogContent>
				</Dialog>
			}
		</React.Fragment>
	)
}