// React
import React, { useEffect, useState, useContext } from "react";

// Material
import { Button, Grid, Typography, LinearProgress, Box } from "@mui/material";

// Project
import DanielsOnlineIcon from '../../../assets/DanielsOnline.svg'
import ArtiztaBorderBox from "../../../common/ArtiztaBorderBox";
import DanielsOnlineSearchResults from "./DanielsOnlineSearchResults";
import DanielsOnlineClient from "../DanielsOnlineClient";
import { AuthContext } from "../../../utils/AuthContext";

export default function DanielsOnlineSearchComponent({ composerName, workTitle, onWorkSelected=null }) {
  // state
  // const [ showSelf, setShowSelf ] = useState(false) // decides wether or not to show the spotify search result
  const [shouldShowSearchButton, setShouldShowSearchButton] = useState(null);
	const [searchTitle, setSearchTitle] = useState(null);
	const [searchComposer, setSearchComposer] = useState(null);
	const [searchResults, setSearchResults] = useState(null);
	const [isSearching, setIsSearching] = useState(false);
	const [currentUser, setCurrentUser] = useContext(AuthContext);
	const [danielsOnlineID, setDanielsOnlineID] = useState(null);
	const [danielsOnlineAPIKey, setDanielsOnlineAPIKey] = useState(null);


  const handleDanielsOnlineSearch = async () => {
		setIsSearching(true);
		setSearchTitle(workTitle);
		setSearchComposer(composerName);
		DanielsOnlineClient.searchForComposerNameAndTitle(danielsOnlineID, danielsOnlineAPIKey, composerName, workTitle, true, (results)=>{
			setIsSearching(false);
			setSearchResults(results);
		}); 
	}

	const handleSelectDanielsRecord = (danielsRecord) => {
		// convert to a proper Work object
		const work = DanielsOnlineClient.getWorkFromDanielsRecord(danielsRecord);
		setSearchResults(null);
		onWorkSelected(work);
	}

	useEffect(()=>{
		if (currentUser !== null && currentUser !== undefined) {
			const personSettings = currentUser.person.settings;
			if (personSettings !== null && personSettings !== undefined) {
				if (personSettings.danielsOnlineID !== null && personSettings.danielsOnlineID !== undefined) {
					setDanielsOnlineID(personSettings.danielsOnlineID);
				}
				if (personSettings.danielsOnlineAPIKey !== null && personSettings.danielsOnlineAPIKey !== undefined) {
					setDanielsOnlineAPIKey(personSettings.danielsOnlineAPIKey);
				}
			}
		}
	},[currentUser]);

	const determineIShowSearchButton = () => {
		if (composerName !== null && composerName !== undefined && composerName.length>=2 &&
				workTitle !== null && workTitle !== undefined && workTitle.length>=2) {
			setShouldShowSearchButton(true);
		}
		else {
			setShouldShowSearchButton(false);
		}
	}

	useEffect(()=>{
		determineIShowSearchButton();
		if (searchComposer !== composerName) {
			// invalidate the results
			setSearchResults(null);
		}
	}, [composerName])

	useEffect(()=>{
		determineIShowSearchButton();
		if (searchTitle !== workTitle) {
			// invalidate the results
			setSearchResults(null);
		}
	}, [workTitle])
  // UI
  return (
		<React.Fragment>
			{danielsOnlineAPIKey!==null && danielsOnlineAPIKey!==undefined  && danielsOnlineID!==null && danielsOnlineID!==undefined ?
				<React.Fragment>
					{isSearching ?
						<Grid container direction='column' justifyContent='center' alignItems='center'>
							<Grid item>
								<Typography variant='h3'>Searching Daniels Online for '{searchTitle}' by '{searchComposer}'.</Typography>
							</Grid>
							<Grid item xs={12} sx={{width:'100%'}}>
									<LinearProgress/>
							</Grid>
						</Grid>
					:
						<React.Fragment>
							{searchResults !== null && searchResults !== undefined ?
								<ArtiztaBorderBox>
									<Typography variant='h3'>Daniels Online results for '{searchTitle}' by '{searchComposer}'</Typography>
									{(searchResults.length < 1) ?
										<Typography>No results found. This sometimes happens if the criteria is too specific, try only using the Composer's last name and only the first few letters of the title.</Typography>	
									:
										<DanielsOnlineSearchResults
											searchResults={searchResults}
											onSelect={handleSelectDanielsRecord}
										/>
								}
								</ArtiztaBorderBox>
							:
								<React.Fragment>
									{shouldShowSearchButton ?
										<Grid container alignItems={'center'}>
											<Button onClick={handleDanielsOnlineSearch} sx={{borderRadius:4}}>
												<img src={DanielsOnlineIcon} width={20} height={20}/>  
												<Typography sx={{marginLeft: 1}}>Search DanielsOnline for {workTitle} by {composerName}</Typography>
											</Button>
										</Grid>
									:
										<Typography><i>Enter at least two characters for Work Title and Composer to search DanielsOnline</i></Typography>
									}
								</React.Fragment>
							}
						</React.Fragment>
					}
				</React.Fragment>
			:
				<Typography><i>To Search Daniels Online, enter your DanielsOnline API Key and ID in the Settings Page</i></Typography>
			}
		</React.Fragment>
  )
}