// Material
import { Grid, TextField, Tooltip, Button, FormControlLabel, Checkbox, Typography } from "@mui/material";
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';

// Project
import { ItemContext } from "../../../common/ArtiztaEditComponent"
import { AuthContext } from "../../../utils/AuthContext";

// React
import { useContext, useState } from "react"

export default function PersonOrganizationComponent() {
  // context
  const [ editingItem, setEditingItem ] = useContext(ItemContext)
  const [ currentUser ] = useContext(AuthContext)

  // Key person star
  const [ isKeyPerson, setIsKeyPerson ] = useState(editingItem!=null ? editingItem.isKeyPerson : false)
  const handleClickKeyPerson = () => {
    // State
    setIsKeyPerson(!isKeyPerson);

    // Context
    setEditingItem({...editingItem, ...{isKeyPerson: !isKeyPerson}})
  }

  // Temp, until api is set up
	const allRoles = [
    "Organization Admin",
    "Leadership",
    "Librarian",
    "Personnel Manager",
    "Staff",
    "Performer"
  ]
  // Role stuff
  const [checkedBoxes, setCheckedBoxes] = useState({
		"Organization Admin": editingItem.roles.includes("Organization Admin"),
		"Leadership": editingItem.roles.includes("Leadership"),
    "Librarian": editingItem.roles.includes("Librarian"),
    "Personnel Manager": editingItem.roles.includes("Personnel Manager"),
    "Staff": editingItem.roles.includes("Staff"),
    "Performer": editingItem.roles.includes("Performer")
	})
  const handleRoleChange = (event) => {
    // Change Check boxes
		const { name, checked } = event.target;
		const change = {};
		change[name]=checked;
		const newCheckedBoxes = {...checkedBoxes, ...change};
		setCheckedBoxes( newCheckedBoxes );

    // Update context
    var newRoles = []
    for (var k in newCheckedBoxes){
      if (newCheckedBoxes[k] == true){
        newRoles.push(k)
      }
    }
    setEditingItem({...editingItem, ...{roles: newRoles}})
	}

  return (
    <Grid>
      <Grid container justifyContent={'space-between'} alignItems={'center'}>
        <Typography sx={{marginBottom: 2}}>
          {editingItem.email}
        </Typography>
        {/* Key Person */}
        <Tooltip title="Key Person Toggle">
          <Button onClick={handleClickKeyPerson}>
            {(isKeyPerson) ?
              <StarIcon />
              :
              <StarBorderIcon />
            }
          </Button>
        </Tooltip>
      </Grid>

      {/* Title */}
      <TextField 
        sx={{marginTop: 2}}
        id="title"
        name="title"
        label="Title"
        fullWidth
        defaultValue={editingItem.title}
        onChange={(e) => setEditingItem({...editingItem, ...{title: e.target.value}})}
      />

      {/* Roles */}
      <Grid item xs container direction={'column'}>
        <h3>Roles</h3>
        {
          allRoles.map((role) => {
            return (
              <FormControlLabel 
                key={role} 
                name={role} 
                id={role} 
                control={<Checkbox checked={checkedBoxes[role]}/>} 
                label={role} 
                onChange={handleRoleChange}
              />
            )
          })
        }
      </Grid>
    </Grid>
  )
}