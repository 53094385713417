// React imports
import React from 'react';

// material
import { Box, useTheme } from '@mui/material';

export default function ArtiztaBorderBox({ children, sx }) {

	const theme = useTheme();

	const defaultSX = {
		marginTop:'1em', 
		p:'1em', 
		border:0.5, 
		borderColor:theme.palette.text.primary, 
		borderRadius:3, 
		minWidth:0
	}

	const finalSX = {...defaultSX, ...sx};

	return (
		<Box sx={finalSX}>
			{children}
		</Box>
	)
}