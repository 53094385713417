// React imports
import React, { useEffect, useContext, useState } from 'react';

// material
import { Box } from '@mui/material';

// project imports
import { AuthContext } from '../../utils/AuthContext';
import NotificationsClient from './NotificationsClient';
import NotificationCard from './NotificationCard';

export default function NotificationsDisplay() {
	const [notifications, setNotifications] = useState(null);
	const [currentUser, setCurrentUser] = useContext(AuthContext);

	const fetchNotifications = async () => {
		const person = currentUser.person;
		const notificationsArray = await NotificationsClient.getAll(person);
		setNotifications(notificationsArray);
	}

	const dismissNotification = async (notification) => {
		const person = currentUser.person;
		notification.isDismissed=true;
		NotificationsClient.update(person, notification);
		setNotifications(notifications.filter(item=>item.id!==notification.id));
	}

	useEffect(()=>{
		if (currentUser!== null || currentUser!== undefined){
			fetchNotifications();
		}
	},[]);

	if (notifications === null || notifications === undefined) {
		return;
	}

	return (
		<Box>
			{notifications.map((notification)=>{
				return (
					<NotificationCard notification={notification} onClose={dismissNotification} key={notification.text+notification.notificationDate.toString()}/>
				)
			})}
		</Box>
	)
}