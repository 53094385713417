// project imports
import APIClientManager from '../../utils/APIClientManager';

/**
 * 	PeopleClient encapsulates the API calls to People
 * 		getAll - returns all people
 *    searchForLastName - searches for a person where the last name starts with whatever is entered
 *    get - returns a person given an ID
 *    add - adds a person, the only required field is email
 * 			email must be unique. id must not be provided
 *    update - updates an existing person
 * 			email can only be changed if auth_uid is not present. valid id must be provided
 *    delete - deletes a person
 * 			id provided must be valid.
 */

export default class PeopleClient {
	static baseURL = 'http://'+process.env.REACT_APP_API_URL+'/api/';

	static searchForName = async (searchString) => {
		const response = await APIClientManager.get('people', {lastName: searchString});
		const json = await response.json();
		return json;
	}

	static searchForEmail = async (searchString) => {
		const response = await APIClientManager.get('people', {email: searchString});
		const json = await response.json();
		return json;
	}

	static getAll = async () => {
		const response = await APIClientManager.get('people');
		const json = await response.json();
		return json;
	}

	static get = async (id) => {
		const response = await APIClientManager.get(`people/${id}`);
		const json = await response.json();
		return json;
	}

	static add = async (person) => {
		const response = await APIClientManager.post('people', person);
		const json = await response.json();
		return json;
	}

	static update = async (person) => {
		const response = await APIClientManager.put('people/${person.id}', person);
		const json = await response.json();
		return json;
	}

	static delete = async (id) => {
		const response = await APIClientManager.delete(`people/${id}`);
		const json = await response.json();
		return json;
	}
}