// React imports
import React from 'react';

// material
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';


export default function ArtiztaInputBox({ children, sx }) {

	const theme = useTheme();

	const defaultSX = {
		marginTop:'0em', 
		p:'1em', 
		border:0.5, 
		borderColor:theme.palette.action.disabled, 
		borderRadius:1,
		'&:hover': {
			borderColor: theme.palette.action.active,
		}}

	const finalSX = {...defaultSX, ...sx};

	return (
		<Box sx={finalSX}>
			{children}
		</Box>
	)
}