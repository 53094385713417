// React imports
import React, { useEffect, useContext  } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Material imports
import { Box, SpeedDial, useTheme, SpeedDialIcon } from '@mui/material';
// import BugReportRoundedIcon from '@mui/icons-material/BugReportRounded';
// import EditRoundedIcon from '@mui/icons-material/Edit';
// import SendRoundedIcon from '@mui/icons-material/SendRounded';
// project imports
import { AuthContext } from '../utils/AuthContext.js';

export default function ContentBackground(props) {
  const [currentUser, setCurrentUser] = useContext(AuthContext);

	const theme = useTheme();
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(()=>{
		if (currentUser===null && location.pathname !== '/') {
			navigate('/');
		}
	},[])

	return (
		<Box component="main"  
			sx={{	paddingTop: '3.25em', 
						paddingLeft: '1em', 
						paddingRight: '1.5em',
						[theme.breakpoints.down('lg')]: { paddingTop:'3em'},
						[theme.breakpoints.down('md')]: { paddingTop:'2.5em'},
            [theme.breakpoints.down('sm')]: { paddingTop:'2.25em'}}}>
				{props.children}
			{/* <SpeedDial
				onClick={()=>{log('speed dial clicked')}}
        ariaLabel="SpeedDial basic example"
        sx={{ position: 'absolute', bottom: 16, left: 16 }}
        icon={<SpeedDialIcon icon={<BugReportRoundedIcon/> } openIcon={<EditRoundedIcon />}/>}
			>
			</SpeedDial> */}
		</Box>
	)
}