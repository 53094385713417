
// React Imports
import React, {useState} from 'react';
import {Link} from 'react-router-dom';
// material imports
import {
		List, 
		ListItemText, 
		ListItemButton, 
		ListItemIcon, 
		Divider, 
		SwipeableDrawer, 
		IconButton,
		useTheme } from '@mui/material';

//icons
import LocationCityRoundedIcon from '@mui/icons-material/LocationCityRounded';
import LibraryMusicRoundedIcon from '@mui/icons-material/LibraryMusicRounded';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import TheaterComedyRoundedIcon from '@mui/icons-material/TheaterComedyRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import MenuRoundedIcon  from '@mui/icons-material/MenuRounded';
import BuildRoundedIcon from '@mui/icons-material/BuildRounded';
import DesignServicesRoundedIcon from '@mui/icons-material/DesignServicesRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';



export default function ArtiztaMenuDrawer({ onChange, currentValue }) {
	const theme = useTheme();

  // function version - so it only runs once
  const [openDrawer, setOpenDrawer] = useState(() => {
    return false
  })

  // Control which tab is selected
  const handleChange = () => {
    setOpenDrawer(!openDrawer)
    onChange()
  }

  const handleClose = () => {

  }

  const handleOpen = () => {
    
  }

  const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

  return (
    <React.Fragment>
      <SwipeableDrawer disableBackdropTransition={!iOS} disableDiscovery={iOS}
        open={openDrawer} 
        onClick={() => handleChange()}
        onClose={handleClose}
        onOpen={handleOpen}
        sx={{
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { boxSizing: 'border-box' }}}>
        <List sx={{ [theme.breakpoints.down('lg')]: { paddingTop:'5.35em'},
                    [theme.breakpoints.down('md')]: { paddingTop:'3.5em'},
                    [theme.breakpoints.down('sm')]: { paddingTop:'3em'}}}>
          <ListItemButton component={Link} to='/' selected={currentValue===0}>
            <ListItemIcon> <HomeRoundedIcon/>  </ListItemIcon>
            <ListItemText>Home</ListItemText>
          </ListItemButton>
          <Divider/>
					{/*
          <ListItemButton component={Link} to='/organizations' selected={currentValue===1}>
            <ListItemIcon> <BusinessRoundedIcon/>  </ListItemIcon>
            <ListItemText>Organizations</ListItemText>
          </ListItemButton>
				*/}
          <ListItemButton component={Link} to='/locations' selected={currentValue===1}>
            <ListItemIcon> <LocationCityRoundedIcon/>  </ListItemIcon>
            <ListItemText>Locations</ListItemText>
          </ListItemButton>
          <ListItemButton component={Link} to='/people' selected={currentValue===2}>
            <ListItemIcon> <PeopleAltRoundedIcon/>  </ListItemIcon>
            <ListItemText>People</ListItemText>
          </ListItemButton>
          <ListItemButton component={Link} to='/events' selected={currentValue===3}>
            <ListItemIcon> <TheaterComedyRoundedIcon/>  </ListItemIcon>
            <ListItemText>Events</ListItemText>
          </ListItemButton>
					<ListItemButton component={Link} to='/seasonDesigner' selected={currentValue===4}>
            <ListItemIcon> <DesignServicesRoundedIcon/>  </ListItemIcon>
            <ListItemText>Season Designer</ListItemText>
          </ListItemButton>
          <ListItemButton component={Link} to='/works' selected={currentValue===5}>
            <ListItemIcon> <LibraryMusicRoundedIcon/>  </ListItemIcon>
            <ListItemText>Works</ListItemText>
          </ListItemButton>
          <Divider/>
          <ListItemButton component={Link} to='/settings' selected={currentValue===6}>
            <ListItemIcon> <SettingsRoundedIcon/>  </ListItemIcon>
            <ListItemText>Settings</ListItemText>
          </ListItemButton>
          <Divider/>
          <ListItemButton component={Link} to='/sandbox' selected={currentValue===7}>
            <ListItemIcon> <BuildRoundedIcon/>  </ListItemIcon>
            <ListItemText>Sandbox</ListItemText>
          </ListItemButton>
        </List>
      </SwipeableDrawer>
      <IconButton onClick={() => setOpenDrawer(!openDrawer)}  >
            <MenuRoundedIcon sx={{
							width:'1.5em', height:'1.5em', color:theme.palette.common.white}} />
      </IconButton>
    </React.Fragment>
  )
}