import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';

// material
import { Grid, Typography, IconButton, Tooltip } from '@mui/material';
import ArrowLeftRoundedIcon from '@mui/icons-material/ArrowLeftRounded';
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import ArtiztaInputBox from '../../../common/ArtiztaInputBox';

// project

export default function CalendarControls ({currentMonth, currentYear, setCurrentMonth, setCurrentYear, children}) {

	const handleMonthChange = (value) => {
		// handle the edge cases
		if (value < 0) {
			setCurrentMonth(11);
			setCurrentYear(currentYear-1);
		}
		else if (value > 11) {
			setCurrentMonth(0);
			setCurrentYear(currentYear+1);
		}
		else {
			setCurrentMonth(value);
		}
	}

	const LeftRightControl = ({value, setValue, leftTooltip, rightTooltip, children}) => {

		return (
			<ArtiztaInputBox sx={{p:0}}>
				<Grid container direction='row' justifyContent='space-between' alignItems='center'>
					{/* Left Button */}
					<Grid item>
						<Tooltip title={leftTooltip}>
							<IconButton 
								onClick={()=>{setValue(value-1)}}>
								<ArrowLeftRoundedIcon/>
							</IconButton>
						</Tooltip>
					</Grid>
					{/* Children */}
					<Grid item>
						{children}
					</Grid>
					{/* Right Button */}
					<Grid item>
						<Tooltip title={rightTooltip}>
							<IconButton 
								onClick={()=>{setValue(value+1)}}>
								<ArrowRightRoundedIcon/>
							</IconButton>
						</Tooltip>
					</Grid>
				</Grid>
			</ArtiztaInputBox>
		)
	}

	return (
		<Grid container direction='row' 
				justifyContent='flex-start' 
				alignItems='center' 
				spacing={1} sx={{marginTop:'0.5em', marginBottom:'0.5em'}}>
			<Grid item xs={3}>
				<LeftRightControl 
						value={currentYear} 
						setValue={setCurrentYear} 
						leftTooltip='Previous Year' 
						rightTooltip='Next Year'>
					<Typography variant='h2'>{currentYear}</Typography>
				</LeftRightControl>
			</Grid>
			<Grid item xs={3}>
				<LeftRightControl 
						value={currentMonth} 
						setValue={handleMonthChange} 
						leftTooltip='Previous Month' 
						rightTooltip='Next Month'>
					<Typography variant='h2'>{dayjs().month(currentMonth).format('MMMM')}</Typography>
				</LeftRightControl>
			</Grid>
		</Grid>
	)
}