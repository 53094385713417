// React imports
import React, {useContext, useState} from 'react';

// material
import { Card } from '@mui/material';
import CelebrationTwoToneIcon from '@mui/icons-material/CelebrationTwoTone';
import AutoAwesomeTwoToneIcon from '@mui/icons-material/AutoAwesomeTwoTone';
// project imports
import ContentBackground from '../../common/ContentBackground.js';
import FileSelectorDemo from './fileSelectorDemo/FileSelectorDemo.js';
import AccordionTableDemo from './accordionTableDemo/AccordionTableDemo.js';
import ButtonGroupBarDemo from './ButtonGroupBarDemo.js';
import DNDDemo from './DragNDropDemo/DNDDemo.js';
import NotificationCard from '../../common/notifications/NotificationCard.js';
import RoleWrapper from '../../utils/RoleWrapper.js';
import artiztaConfetti from '../../utils/ArtiztaConfetti.js';
import ArtiztaCard from '../../common/ArtiztaCard.js';
import ArtiztaIconButton from '../../common/ArtiztaIconButton.js';
import ThemeTest from './themeTest/ThemTest.js';

export default function SandboxPage(props) {
	const DemoCard = ({ children }) => {
		return (
			<Card sx={{p:1}}>
				{children}
			</Card>
		)
	}
	const notification = {
		title:'Test Notification', 
		notificationDate:(new Date()).toISOString(), 
		text:'This is the text for the notification.'}

	return (
		<ContentBackground >
			<ArtiztaCard>
				<ThemeTest/>
			</ArtiztaCard>
			<ArtiztaCard>
				<ArtiztaIconButton onClick={artiztaConfetti}>
					<CelebrationTwoToneIcon/>
				</ArtiztaIconButton>
				<ArtiztaIconButton onClick={(e)=>artiztaConfetti(e, 'stars')}>
					<AutoAwesomeTwoToneIcon/>
				</ArtiztaIconButton>
			</ArtiztaCard>
			<RoleWrapper roles={['Librarian']}>
				<h2>Must be a Librarian to see this</h2>
			</RoleWrapper>
			<NotificationCard notification={notification}/>
			<DemoCard>
				<ButtonGroupBarDemo/>
			</DemoCard>
			<DemoCard>
				<FileSelectorDemo/>
			</DemoCard>
			<br/>
			<DemoCard>
				<AccordionTableDemo/>
			</DemoCard>
			<DemoCard>
				<DNDDemo />
			</DemoCard>
		</ContentBackground>
	);
}