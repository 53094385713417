// project imports
import APIClientManager from '../../utils/APIClientManager';

/**
 * 	WorksClient encapsulates the API calls to Works. All operations require a valid organization
 * 		getAll - returns all Works for an organization
 *    TO DO - searchForTitle - searches for a work where the title starts with whatever is entered
 *    get - returns a work given an ID
 *    add - adds a work for an organization, the only required field is title
 *    update - updates an existing work
 * 		TO DO - delete work 
 */

const worksURL = (organization) => {
	return `works/${organization.id}`
}

export default class WorksClient {
	static baseURL = 'http://'+process.env.REACT_APP_API_URL+'/api/';

	static getAll = async (organization) => {
		const response = await APIClientManager.get(worksURL(organization));
		const json = await response.json();
		return json;
	}

	static get = async (organization, id) => {
		const response = await APIClientManager.get(`${worksURL(organization)}/${id}`);
		const json = await response.json();
		return json;
	}

	static add = async (organization, work) => {
		const response = await APIClientManager.post(worksURL(organization), work);
		const json = await response.json();
		return json;
	}

	static update = async (organization, work) => {
		const response = await APIClientManager.put(`${worksURL(organization)}/${work.id}`, work);
		const json = await response.json();
		return json;
	}
}