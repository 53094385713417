import { Autocomplete, TextField } from "@mui/material"
import React, { useContext, useState, useEffect } from "react"

// Project Imports
import { OrganizationContext } from "../../../utils/OrganizationContext";
import OrganizationsClient from "../OrganizationsClient";
import ArtiztaBorderBox from "../../../common/ArtiztaBorderBox";
import { AuthContext } from "../../../utils/AuthContext";
import PeopleClient from "../../people/PeopleClient";
import ArtiztaSnackbar from "../../../common/ArtiztaSnackbar";

export default function OrganizationChange(props) {
  // context
  const [ currentOrganization, setCurrentOrganization ] = useContext(OrganizationContext)
  const [ currentUser ] = useContext(AuthContext)

  // Organization Search
  // const [selectedOrg, setSelectedOrg] = useState(null);
  const [lastFetchSearchValue, setLastFetchSearchValue] = useState('');
  const [lastFetchResults, setLastFetchResults] = useState([]);
  const [displayItems, setDisplayItems] = useState([]);
  const [ openSnackbar, setOpenSnackbar] = useState(false)

  // Search helper
  const handleSelection = async (event, newValue) => {
    if (newValue!=null){
      //Context
      setCurrentOrganization(newValue);
      
      // DB
      if (currentUser.person.settings === null || currentUser.person.settings === undefined) {
        currentUser.person.settings = {};
      }
      
      currentUser.person.settings.selectedOrganization = newValue.id
      await PeopleClient.update(currentUser.person)

      // State
      if (props.setChangeOrg !== null && props.setChangeOrg !== undefined) {
        props.setChangeOrg(false);
      }

      // snacks
      setOpenSnackbar(true)
    }
  }

  // Search Helper
  const handleInputChange = async (event, newValue) => {
    // need to determine if a new fetch is needed
    let newFetchNeeded=false;

    if (newValue==='') { setLastFetchSearchValue(''); setLastFetchResults([]); setDisplayItems([]); return;}
    if (lastFetchSearchValue==='') {newFetchNeeded=true;}
    if (!newValue.startsWith(lastFetchSearchValue)) {newFetchNeeded=true;}

    if (newFetchNeeded) {
      setLastFetchSearchValue(newValue);
      const json = await OrganizationsClient.searchForName(newValue)
			setLastFetchResults(json);
      setDisplayItems(json);
    }
    else {
      return;
    }
  }

  return (
    <ArtiztaBorderBox sx={{ padding: 2, borderRadius:4, topMargin:'.5em' }}>
      {(currentOrganization != null) ?
        <React.Fragment>
          <img src={currentOrganization.imageURL} style={{'width':'50px'}}/>
          <p>{currentOrganization.name}</p>
        </React.Fragment>
      :
        <React.Fragment>No organization selected<p/></React.Fragment>
      }

      <Autocomplete 
        id="organization-search"
        autoHighlight={true}
        options={displayItems}
        getOptionLabel={option=>option.name}
        isOptionEqualToValue={(option, value) => {return option.id===value.id}}
        renderInput={(params) => <TextField {...params} label="Search for an Organization" />}
        onChange={handleSelection}
        onInputChange={handleInputChange}
      />
      {/* Snackbar */}
			{currentOrganization != null && currentOrganization != undefined &&
			  <ArtiztaSnackbar message={`Changed organization to "${currentOrganization.name}"`} open={openSnackbar} setOpen={setOpenSnackbar}/>
			}
    </ArtiztaBorderBox>
  )
}