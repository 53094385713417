// React imports
import React, {useContext, useState, useEffect} from 'react';
import { Link } from 'react-router-dom';

// material
import { Box, Grid, useTheme } from '@mui/material';

// project imports
import ContentBackground from '../../common/ContentBackground.js';
import {AuthContext} from '../../utils/AuthContext.js';
import OrganizationChange from '../organizations/components/OrganizationChange.js';
import { OrganizationContext } from '../../utils/OrganizationContext.js';
import EventsList from '../events/EventsList.js';
import ArtiztaCard from '../../common/ArtiztaCard.js';
import ArtiztaPageTitle from '../../common/ArtiztaPageTitle.js';
import OrganizationName from '../organizations/components/OrganizationName.js';
import NotificationsDisplay from '../../common/notifications/NotificationsDisplay.js';
import OrganizationsClient from '../organizations/OrganizationsClient.js';
import RoleWrapper from '../../utils/RoleWrapper.js';
import PeopleClient from '../people/PeopleClient.js';
import { getGoogleRedirectResult } from '../../utils/Firebase.js';
import { LoginClient } from '../login/LoginClient.js';
import MarketingPage from './MarketingPage.js';
import EventsClient from '../events/EventsClient.js';

export default function DashboardPage(props) {
	// Context
	const [currentUser, setCurrentUser] = useContext(AuthContext);
	const [currentOrganization, setCurrentOrganization] = useContext(OrganizationContext);
	const [events, setEvents] = useState(null);	

	const theme=useTheme();
	// Loads selected org if it exists
	useEffect(()=>{

		if (currentUser != null || currentUser != undefined){
			if (currentUser.person.settings === undefined || currentUser.person.settings === null) {
				currentUser.person.settings = {};
			}	

			if (	currentUser.person.settings.selectedOrganization === undefined || 
						currentUser.person.settings.selectedOrganization == null) {
				// set the organization
				
				if (currentUser.person.organizationIDs !== undefined && currentUser.person.organizationIDs !== null && currentUser.person.organizationIDs.length > 0) {
					currentUser.person.settings.selectedOrganization = currentUser.person.organizationIDs[0];
					PeopleClient.update(currentUser.person)
					.then(json => {
						setCurrentUser({...currentUser, ...{person: json}})
					})
				}
			}
			else if (currentOrganization === null || currentOrganization === undefined || currentUser.person.settings.selectedOrganization !== currentOrganization.id) {
				OrganizationsClient.getOrganization(currentUser.person.settings.selectedOrganization)
				.then(json => {
					setCurrentOrganization(json)
				})
			}
		}
	},[currentUser])

	const processGoogleRedirectResult = async () => {	
		try {
			const result = await getGoogleRedirectResult();

			if (result !== null && result !== undefined && result.user !== null && result.user !== undefined) {
				const authUser = result.user;
				const response = await LoginClient.postLogin(authUser);
				const json = await response.json();
				if (json.status === 'success') {
					const person = json.user;
					setCurrentUser({user: authUser, person: person});
				}
			}
		}
		catch (error) {
			console.log('error', error);
		}
	}

	useEffect(()=>{	
		processGoogleRedirectResult();
	},[]);

	useEffect(() => {
		if (currentUser !== null && currentUser !== undefined) {
			document.body.style.backgroundColor = theme.palette.background.default;
		}

	}, [currentUser]);

	const fetchEvents = async () => {
		const theEvents = await EventsClient.getAll(currentOrganization);
		setEvents(theEvents);
	}

	useEffect(()=>{
		if (currentOrganization !== null && currentOrganization !== undefined) {
			fetchEvents();
		}
	}, [currentOrganization]);

	// UI
	return (
		<React.Fragment>
		{(currentUser==null) ?
			<MarketingPage />
			:
			<ContentBackground>
				<NotificationsDisplay/>
				<ArtiztaCard>
					<Grid container direction='row' justifyContent='space-between'>
						<Grid item>
							<ArtiztaPageTitle>Upcoming Events</ArtiztaPageTitle>
						</Grid>
						<Grid item>
							<OrganizationName/>
						</Grid>
					</Grid>
					{(currentOrganization === null || currentOrganization === undefined) ?
						<RoleWrapper roles={['Global Admin']}>
							<OrganizationChange />
						</RoleWrapper>
						:
						<Box>
							<EventsList events={events}/>
							<Link to='/events'>All events...</Link>
						</Box>
					}
				</ArtiztaCard>
			</ContentBackground>
		}   
		</React.Fragment>
	)
}