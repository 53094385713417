import React, { useState, useEffect, useContext } from 'react';
import dayjs from 'dayjs';

// material
import { Box, Grid, Typography } from '@mui/material';
import ArrowLeftRoundedIcon from '@mui/icons-material/ArrowLeftRounded';
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';

//project
import CalendarHeader from './CalendarHeader.js';
import CalendarControls from './CalendarControls.js';
import CalendarMonth from './CalendarMonth.js';
import { SeasonDesignerContext } from '../SeasonDesignerPage.js';
import ArtiztaEditComponent from '../../../common/ArtiztaEditComponent.js';
import EditEventComponent from '../../events/EditEventComponent.js';

export default function SeasonCalendar ( { events, onSaveEvent, refetch, startDay=1 /* Sunday=0 */} ) {
	const [currentMonth, setCurrentMonth] = useState(null);
	const [currentYear, setCurrentYear] = useState(null);
	const [selectedSeason, setSelectedSeason, selectedSeries, setSelectedSeries] = useContext(SeasonDesignerContext);
	const [selectedEditingEvent, setSelectedEditingEvent] = useState(null);

	useEffect(()=>{
		if (selectedSeason!==null && selectedSeason !== undefined) {
			setCurrentMonth(dayjs(selectedSeason.startDate).month());
			setCurrentYear(dayjs(selectedSeason.startDate).year());
		}
	}, [selectedSeason])

	const handleEditEvent = (event) => {
		setSelectedEditingEvent(event);
	}

	const handleSaveEvent = (event) => {
		const savedEvent = onSaveEvent(event);
		setSelectedEditingEvent(null);
	}


	const handleAddEvent = (dayJS) => {
		const theNewEvent = newEvent(dayJS);
		console.log(theNewEvent);
		setSelectedEditingEvent(theNewEvent);
	}

	const newEvent = (dayJS) => {

		const templateEvent = {
			name: `New Event on ${dayJS.format('M/D')}`,
			performances: [{date:dayJS.hour(20).minute(0).second(0).millisecond(0).toDate()}]
		};

		if (selectedSeason !== null && selectedSeason !== undefined) {
			templateEvent.season = selectedSeason.name;
		}
		if (selectedSeries !== null && selectedSeries !== undefined) {
			templateEvent.series = selectedSeries.name;
		}
		return templateEvent;
	}

	return (
		<Box sx={{width:'100%', height:'100%'}}>
			{selectedEditingEvent !== null && selectedEditingEvent !== undefined ?
				<ArtiztaEditComponent 
					title={`Editing '${selectedEditingEvent.name}'`}
					item={selectedEditingEvent} 
					onSave={handleSaveEvent} 
					onCancel={()=>{setSelectedEditingEvent(null); refetch()}}>
					<EditEventComponent />
				</ArtiztaEditComponent>
			:
			<React.Fragment>
				<CalendarControls 
						currentMonth={currentMonth} 
						currentYear={currentYear} 
						setCurrentMonth={setCurrentMonth} 
						setCurrentYear={setCurrentYear}/>
				<CalendarHeader startDay={startDay}/>
				<CalendarMonth 
						startDateJS={dayjs().year(currentYear).month(currentMonth).day(1)}
						currentMonth={currentMonth}
						currentYear={currentYear}
						events={events}
						onEditEvent={handleEditEvent}
						onAddEvent={handleAddEvent}/>
			</React.Fragment>
			}
		</Box>
	)
}