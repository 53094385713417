// React imports
import React, { useEffect } from 'react';

// material
import { Typography, Grid, Button, Card, Box, useTheme } from '@mui/material';
import CancelPresentationRoundedIcon from '@mui/icons-material/CancelPresentationRounded';

// project imports

export default function NotificationCard({notification, onClose}) {

	const notificationDateString = () => {
		if (notification !== null && notification !== undefined) {
			// notificationDate should be a ISOString
			const theDate = new Date(notification.notificationDate);
			 return theDate.toLocaleString();
		}
		else {
			return 'MM/DD/YYYY HH:SS AMPM';
		}
	}

	const handleCloseNotification = () => {
		onClose(notification);
	}
	const theme = useTheme();

	return (
		<Card sx={{
				backgroundColor:theme.palette.background.notificationLabel, 
				border:1, borderRadius:3, marginBottom:'0.5em'}}>
			<Box sx={{m:'0.5em'}}>
				<Grid container direction='row'>
					<Grid item xs={11.5}>
						<Grid container direction='row' justifyContent='space-between'>
							<Typography variant='h2' color={theme.palette.text.notificationLabel}><b>{notification.title}</b></Typography>
							<Typography fontSize={16} color={theme.palette.text.notificationLabel}>{notificationDateString()}</Typography>
						</Grid>
						<Typography color={theme.palette.text.notificationLabel}>{notification.text}</Typography>
					</Grid>
					<Grid item xs={.5} container justifyContent='flex-end' alignItems='flex-start'>
						<Button sx={{p:0, minWidth:0, minHieght:0}} onClick={handleCloseNotification}>
							<CancelPresentationRoundedIcon/>
						</Button>
					</Grid>
				</Grid>		
			</Box>	
		</Card>
	)
}