//react 
import { useEffect } from 'react';	
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale'

// Material
import { Box, Grid } from '@mui/material';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

export default function PerformanceDateTimeEdit ({ performanceDate, setPerformanceDate}) {

	const calculatePerformanceDate = (newPerformanceDate) => {
		// assume this is a datejs date
		const currentDate = dayjs(performanceDate);
		const date = newPerformanceDate.date();
		const month = newPerformanceDate.month();
		const year = newPerformanceDate.year();
		const hour = currentDate.hour();
		const minute = currentDate.minute();

		const dateStr = `${month+1}-${date}-${year} ${hour}:${minute}`;
		const newDate = dayjs(dateStr).toDate();
		setPerformanceDate(newDate);
	}

	const calculatePerformanceTime = (newPerformanceTime) => {
		// assume this is a datejs date
		const currentDate = dayjs(performanceDate);
		const date = currentDate.date();
		const month = currentDate.month();
		const year = currentDate.year();
		const hour = newPerformanceTime.hour();
		const minute = newPerformanceTime.minute();

		const dateStr = `${month+1}-${date}-${year} ${hour}:${minute}`;
		const newDate = dayjs(dateStr).toDate();
		setPerformanceDate(newDate);
	}

	useEffect(() => {
		dayjs.extend(updateLocale);
		dayjs.updateLocale('en', {
			weekStart: 1,
		});
	},[])

	return (
		<Box>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<Grid container direction='row' spacing='0.5em'>
					<Grid item xs={6} >
						<DatePicker sx={{width:'100%'}}
							label='Date'
							value={dayjs(performanceDate)}
							onChange={(newValue)=>calculatePerformanceDate(newValue)}/>		
					</Grid>
					<Grid item xs={6}>
						<TimePicker sx={{width:'100%'}}
							label="Start Time"
							value={dayjs(performanceDate)}
							onChange={(newValue)=>calculatePerformanceTime(newValue)}/>
					</Grid>
				</Grid>
			</LocalizationProvider>
		</Box>
	)
}
