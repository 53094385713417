// Material
import { Grid, TextField, Typography } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';

// react
import { useContext, useState } from "react";

// project
import ArtiztaIconButton from "../../../common/ArtiztaIconButton";
import { AuthContext } from "../../../utils/AuthContext";
import PeopleClient from "../../people/PeopleClient";
import ArtiztaSnackbar from "../../../common/ArtiztaSnackbar";
import DanielsOnlineClient from "../../works/DanielsOnlineClient.js"


export default function DanielsOnlineSettings() {
  // context
  const [ currentUser, setCurrentUser ] = useContext(AuthContext)
  
  // State
  const [ isLoading, setIsLoading ] = useState(false)
  const [saveSettingsDisabled, setSaveSettingsDisabled] = useState((currentUser.person.settings == undefined || currentUser.person.settings == null) ? false : true);
  const [ openSnackbar, setOpenSnackbar] = useState(false)
  const [ snackbarTitle, setSnackbarTitle ] = useState("")
  const [ snackbarAlert, setSnackbarAlert ] = useState("success")

  // Make save button disabled if there is no changes
  const handleSettingsInputChange = () => {
		const danielsOnlineID = document.getElementById("doID").value;
		const danielsOnlineAPIKey = document.getElementById("doAPIKey").value;
    if (currentUser.person.settings != undefined || currentUser.person.settings != null) {
      setSaveSettingsDisabled((danielsOnlineID===currentUser.person.settings.danielsOnlineID && danielsOnlineAPIKey=== currentUser.person.settings.danielsOnlineAPIKey));
    }
	}

  // Saving Daniels Online
  const handleDanielsOnlineSettingSave = async () => {
    setIsLoading(true)
		const theID = document.getElementById("doID").value;
		const theAPIKey = document.getElementById("doAPIKey").value;
    DanielsOnlineClient.getWorkForID(theID, theAPIKey, "validateKey")
      .then(response => {
        if("danielsAccessDenied" in response){
          // Show error message
          setSnackbarTitle("Credentials Failed to validate")
          setSnackbarAlert('error')
          setOpenSnackbar(true)
        }else{
          // Update person settings
          const person = currentUser.person;

          if (person.settings === null || person.settings === undefined) {
            person.settings = {
              danielsOnlineID: theID,
              danielsOnlineAPIKey: theAPIKey
            }
          }
          else {
            person.settings.danielsOnlineID = theID;
            person.settings.danielsOnlineAPIKey = theAPIKey;
          }

          // save the person... 
          setCurrentUser(currentUser)
          PeopleClient.update(currentUser.person);
          setSaveSettingsDisabled(true)

          // Snackbar
          setSnackbarTitle("Success, credentials are valid")
          setSnackbarAlert("success")
          setOpenSnackbar(true)
        }
        setIsLoading(false)
      })
	}

  // UI
  return (
    <Grid container direction={'column'}>
      {/* Textfields */}
      <Grid container direction={'row'} spacing={2} alignItems={'center'}>
        <Grid item>
          <Typography>Daniels Online ID</Typography>
          <TextField 
            id="doID"
            name="doID"
            error={snackbarAlert=='error'}
            label={snackbarAlert=='error'?'one or both are Invalid':""}
            onChange={handleSettingsInputChange}
            defaultValue={currentUser.person.settings !== undefined ? currentUser.person.settings.danielsOnlineID : null}
          />
        </Grid>
        <Grid item>
          <Typography>Daniels Online API Key</Typography>
          <TextField 
            id="doAPIKey"
            name="doAPIKey"
            error={snackbarAlert=='error'}
            label={snackbarAlert=='error'?'one or both are Invalid':""}
            onChange={handleSettingsInputChange}
            defaultValue={currentUser.person.settings !== undefined ? currentUser.person.settings.danielsOnlineAPIKey : null}
          />
        </Grid>
        <Grid item>
          <ArtiztaIconButton toolTip={"Save"} onClick={handleDanielsOnlineSettingSave} disabled={isLoading}>
            <CheckIcon />
          </ArtiztaIconButton>
        </Grid>
      </Grid>

      {/* Snackbar */}
      <ArtiztaSnackbar message={snackbarTitle} open={openSnackbar} setOpen={setOpenSnackbar} alert={snackbarAlert}/>
    </Grid>
  )
}