// React
import React, { useContext, useState } from "react"

// Material Imports
import { ListItemButton, Grid, Box, Typography, Tooltip, Paper, Divider } from "@mui/material";
import StarIcon from '@mui/icons-material/Star';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import VerifiedIcon from '@mui/icons-material/Verified';

// Role Icons
import PsychologyIcon from '@mui/icons-material/Psychology'; // Organization Admin
import AcUnitIcon from '@mui/icons-material/AcUnit'; // leadership
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks'; // Librarian
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople'; // Personnel Manager
import EngineeringIcon from '@mui/icons-material/Engineering'; // staff
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh'; // performer

// Project
import OrganizationsClient from "../../organizations/OrganizationsClient";
import { OrganizationContext } from "../../../utils/OrganizationContext";
import ButtonGroupBar from "../../../common/ButtonGroupBar";
import ButtonGroupButton from "../../../common/ButtonGroupButton";
import ArtiztaEditDialog from "../../../common/ArtiztaEditDialog";
import PersonOrganizationComponent from "./PersonOrganizationComponent";
import ArtiztaAlertDialog, { clearAlertDialog } from '../../../common/ArtiztaAlertDialog';
import PersonFullEditComponent from "./PersonFullEditComponent";

export default function PeopleCell({person, onDelete}) {
  // States
  const [ currentPerson, setCurrentPerson ] = useState(null) // this is for the new artizta edit dialog
  const [ statePerson, setStatePerson ] = useState(person) // needed this because this component was not updating when context changed
  const [ expandCell, setExpandCell] = useState(false)
  const [ alertDialog, setAlertDialog ] = useState(clearAlertDialog)

  // context
  const [ currentOrganization, setCurrentOrganization ] = useContext(OrganizationContext)

  // For Delete
  function handleDelete(personGone) {
    setAlertDialog({
      open: true,
      title: `Remove ${personGone.firstName} ${personGone.lastName} from the ${currentOrganization.name}?`,
      message: `This will remove all organization access for ${personGone.firstName} ${personGone.lastName}. Their profile will remain in the system.`,
      onAgree: () => {
        OrganizationsClient.removePersonFromOrganization(currentOrganization, personGone)
          .then(json => {
						if (onDelete !== null && onDelete !== undefined){
							onDelete(personGone)
						}
            setCurrentOrganization(json)
          })
        setAlertDialog(clearAlertDialog)
      },
      onDisagree: () => {
        setAlertDialog(clearAlertDialog)
      }
    })
  }

  // Handle edit person
  const handleEdit = () => {
    setCurrentPerson(statePerson)
  }

  // Update person in Database
  const handleSavePerson = (savePerson) => {
    OrganizationsClient.setPersonInOrganization(currentOrganization, savePerson)
      .then(json => {
        // Update org context
        setCurrentOrganization(json)
        // Update State
        setStatePerson(savePerson)

        // Clean up Dialog
        setCurrentPerson(null)
      })
  }

  // cancel
  const handleCancelPerson = (cancelPerson) => {
    setCurrentPerson(null)
  }

  // Role Component
  const RoleIcons = ({role}) => {
    return (
      <React.Fragment>
        {
          (role=="Organization Admin") &&
          <Tooltip title="Organization Admin">
            <PsychologyIcon />
          </Tooltip>
        }
        {
          (role=="Leadership") &&
          <Tooltip title="Leadership">
            <AcUnitIcon />
          </Tooltip>
        }
        {
          (role=="Librarian") &&
          <Tooltip title="Librarian">
            <LibraryBooksIcon />
          </Tooltip>
        }
        {
          (role=="Personnel Manager") &&
          <Tooltip title="Personnel Manager">
            <EmojiPeopleIcon />
          </Tooltip>
        }
        {
          (role=="Staff") &&
          <Tooltip title="Staff">
            <EngineeringIcon />
          </Tooltip>
        }
        {
          (role=="Performer") &&
          <Tooltip title="Performer">
            <AutoFixHighIcon />
          </Tooltip>
        }
      </React.Fragment>
    )
  }

  return (
    <Grid container flexDirection={'column'} key={person.id}>
      <Grid container>
        <ListItemButton
          onClick={()=> setExpandCell(!expandCell)}
					sx={{borderRadius:2}}
          // selected={expandCell}
        >
          <Grid container flexDirection={'column'}>
            {/* Cell Content */}
            <Grid container direction='row' justifyContent='space-between' alignItems={'center'}>
              {/* Is Key Person Star */}
              {(statePerson.isKeyPerson) ?
                <Tooltip title="Key Person">
                  <StarIcon />
                </Tooltip>
                :
                <Box sx={{marginRight: 3}} />
              }

              {/* Name & Title */}
              <Grid item xs={8} container>
                <Typography fontWeight={'bold'} fontSize={16}>
                  {statePerson.firstName + " " + statePerson.lastName + ","}
                </Typography>
                <Typography sx={{marginLeft: 1}}>
                  {(statePerson.title != "") ? statePerson.title : "Staff"}
                </Typography>
              </Grid>
              
              {/* User has logged in */}
              {
                (person.auth_uid != undefined) ?
                <Tooltip title="User has logged in">
                  <VerifiedIcon color="primary" fontSize="small"/>
                </Tooltip>
                :
                <Box sx={{marginLeft:2}} />
              }

              {/* Email */}
              <Grid item xs={3}>
                {statePerson.email}<br />
              </Grid>
            </Grid>
          </Grid>
        </ListItemButton>

        {/* Edit & Delete Buttons */}
        <ButtonGroupBar size='small' variant='outline'>
          <ButtonGroupButton onClick={handleEdit} toolTip='Edit Person'>
            <EditRoundedIcon/>
          </ButtonGroupButton>
          <ButtonGroupButton onClick={() => handleDelete(statePerson)} toolTip='Delete Person'>
            <DeleteForeverRoundedIcon/>
          </ButtonGroupButton>
        </ButtonGroupBar>
      </Grid>
      <Divider />

      {/* Expanded Section of the Cell */}
      {(expandCell &&
      <Paper sx={{m:2, p:2, marginTop:'0.25em'}}>
        <Grid container justifyContent={'space-between'}>
          {/* Description */}
          <Grid item xs={4}>
            <Typography fontWeight={'bold'}>About</Typography>
            <Typography>{(statePerson.profile != "" || statePerson.profile == undefined) ? statePerson.profile : "No Profile Description"}</Typography>
          </Grid>

          {/* Roles */}
          <Grid item xs={4}>
            <Typography fontWeight={'bold'}>Organization Roles</Typography>
            {
              statePerson.roles.map(function(role) {
                return (
                  <Typography key={role}>{role}</Typography>
                )
              })
            }
          </Grid>

          {/* Performance Skillz */}
          <Grid item xs={4}>
            <Typography fontWeight={'bold'}>Performance Skills</Typography>
            {
              statePerson.performanceSkills.map(function(skill) {
                return (
                  <Typography key={skill.skill}>{skill.skill}</Typography>
                )
              })
            }
          </Grid>
        </Grid>
      </Paper>
      )}
      
      {/* Edit Person Dialog */}
      <ArtiztaEditDialog
        item={currentPerson}
        title={"Editing " + statePerson.firstName}
        onSave={handleSavePerson}
        onCancel={handleCancelPerson}
        overrideSaveDisabled={true}
        maxWidth={(person.auth_uid == undefined) ? 'xl' : 'sm'}
      >
        {
          (person.auth_uid != undefined) ?
          // Person is free 
          <PersonOrganizationComponent />
          :
          // Person is owned by the organization
          <PersonFullEditComponent />
        }
      </ArtiztaEditDialog>

      {/* Delete Person Dialog */}
      <ArtiztaAlertDialog dialogOptions={alertDialog} />
    </Grid>
  )
}