import React, { useEffect } from "react";

// Material
import { 
	Grid, 
	Typography, 
	Accordion, 
	AccordionDetails, 
	AccordionSummary, 
	Divider } from "@mui/material";
import ArtiztaBorderBox from "../../../common/ArtiztaBorderBox";
import DanielsOnlineDisplay from "./DanielsOnlineDisplay";
import ButtonGroupBar from "../../../common/ButtonGroupBar";
import ButtonGroupButton from "../../../common/ButtonGroupButton";
import AddRoundedIcon from '@mui/icons-material/AddRounded';

export default function DanielsOnlineSearchResults ({ searchResults, onSelect}) {

	// useEffect(()=>{
	// 	log("Search results:", searchResults)
	// },[searchResults])

	const DisplayDanielsOnlineRecord = ({record, onSelect}) => {
		return (
			<Accordion 
					elevation={0} 
					border={1} 
					disableGutters={true} >
				<AccordionSummary 
						sx={{ cursor: 'pointer', m:0, s:0, p:0 }}>
					<Grid container direction='row' item xs={12} alignItems='center'>
						<Grid item xs={10}>
							<Typography variant='h3'>{record.title} by {record.composer}</Typography>
						</Grid>
						<Grid item xs={1}>
							<Typography variant='h3'>{record.details.duration} minutes</Typography>
						</Grid>
						<Grid item xs={1}>
							<ButtonGroupBar variant='outline' size='small'>
								<ButtonGroupButton 
									toolTip='Select this Work' 
									onClick={(e)=>{
										e.stopPropagation();
										e.preventDefault();
										onSelect(record)
									}}>
									<AddRoundedIcon/>
								</ButtonGroupButton>
							</ButtonGroupBar>
						</Grid>
					</Grid>
				</AccordionSummary>
				<AccordionDetails>
					<ArtiztaBorderBox>
						<DanielsOnlineDisplay danielsOnline={record} />
					</ArtiztaBorderBox>
				</AccordionDetails>
			</Accordion>

		)
	
	}

	return (
		<React.Fragment>
			{searchResults !== null && searchResults !== undefined && searchResults.length > 0 &&
				<Grid container direction='column'>
					{searchResults.map((result)=>{
						return (
							<Grid item xs={12}>
								<DisplayDanielsOnlineRecord record={result} onSelect={onSelect}/>
								<Divider/>
							</Grid>
						)
					})}
				</Grid>
			}
		</React.Fragment>
	)
}