// Material
import { 	Grid, 
					List, 
					ListItem, 
					Box, 
					ListItemButton, 
					Typography, 
					Button, 
					Divider, 
					Collapse } from "@mui/material";
import AddRoundedIcon from '@mui/icons-material/AddRounded';

// Project
import DanielsOnlineDisplay from "../../pages/works/components/DanielsOnlineDisplay";

import { ItemContext } from "../ArtiztaEditComponent";

// React
import { useContext, useState } from "react";
import ArtiztaIconButton from "../ArtiztaIconButton";


export default function WorkRecommendationCell({ item, onSave }) {
  // state
  const [ danielsSearchReults, setDanielsSearchResults ] = useState(null)

  //Context
  const [ editingItem, setEditingItem ] = useContext(ItemContext)

  // Row for Daniels Online Search results
  const DisplayDanielsSearchResult = ({work}) => {
    const [ showDetails, setShowDetails ] = useState(false)
    return (
      <Grid>
        <Grid container alignItems={'center'}>
          {/* List item button */}
          <ListItemButton key={item.id} onClick={() => setShowDetails(!showDetails)} divider>
            <Grid container direction={'column'}>
              <Typography fontWeight={'bold'}>Title: {work.title}</Typography>
              <Typography>Composer: {work.composer}</Typography>
            </Grid>
            <Typography >Click to see {showDetails ? 'LESS' : 'MORE'} info</Typography>
          </ListItemButton>

          {/* Add Work Button */}
          <Grid sx={{m:1}}>
          <ArtiztaIconButton
            toolTip='Add Work From Daniels Online'
            onClick={() => onSave(work, false)}
          >
            <AddRoundedIcon />
          </ArtiztaIconButton>
          </Grid>
        </Grid>
        {/* Daniels Online Details */}
        {(showDetails)&&
          <Grid sx={{m:3}}>
            <DanielsOnlineDisplay danielsOnline={work} />
            <Divider />
          </Grid>
        }
      </Grid>
    )
  }

  // UI
  return (
    <Grid container direction={'column'} >
      <Grid container alignItems={'center'} justifyContent={'space-between'}>
        {/* Title & Composer */}
        <Grid item container direction={'column'} xs={3}>
          <Typography fontWeight={'bold'}>{item.work}</Typography>
          <Typography>{item.composer}</Typography>
        </Grid>

        {/* Spotify & Daniels Buttons*/}
        {/* <Grid item container direction={'column'} xs={6} alignItems={'flex-start'} spacing={2}>
          <Grid item>
            <SpotifyButton searchString={item.work + " - " + item.composer} short={true}/>
          </Grid>
          <Grid item>
            <DanielsOnlineButton work={item.work} composer={item.composer} setSearchResults={setDanielsSearchResults}/>
          </Grid>
        </Grid> */}
        
        {/* Add Work Button */}
        <ArtiztaIconButton
          toolTip='Add Work Manually'
          onClick={() => onSave({'title': item.work, 'composer': item.composer}, true)}
          // disabled={checkIfWorkExists(item.title)}
        >
          <AddRoundedIcon />
        </ArtiztaIconButton>
      </Grid>

        {/* Daniels Information */}
        {(danielsSearchReults != null) &&
          <Collapse in={(danielsSearchReults != null)} sx={{borderRadius: 2}}>
            {(danielsSearchReults == "none") ?  // DanielsOnlineButton sets restuls to 'none' if there are none
              <Typography sx={{p:2}} >No results</Typography>
              :
              <List >
                {danielsSearchReults.map((work) => {
                  return (
                    <DisplayDanielsSearchResult work={work} />
                  )
                })}
              </List>
            }
          </Collapse>
        }
    </Grid>
  )
}