// react
import React, {useContext, useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
// material
import {	Divider, 
					Button, 
					IconButton, 
					Typography, 
					ButtonGroup, 
					TextField, 
					Dialog, 
					InputAdornment, 
					DialogContent, 
					DialogContentText, 
					DialogTitle,
					Tooltip,
					useTheme,
					Link as MUILink }  from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

// icons
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import AppRegistrationRoundedIcon from '@mui/icons-material/AppRegistrationRounded';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import LoginRoundedIcon from '@mui/icons-material/LoginRounded';

// project files
import googleIcon from '../../assets/google-icon.svg';
//import facebookIcon from '../../assets/facebook-icon.svg';
//import amazonIcon from '../../assets/amazon-icon.svg';
import {AuthContext} from '../../utils/AuthContext';
import { signInWithEmail, createUserWithEmail, signInWithGooglePopup, signInWithGoogleRedirect } from '../../utils/Firebase';
import {LoginClient} from './LoginClient.js'
import APIClientManager from '../../utils/APIClientManager.js';
import { SettingsContext } from '../../utils/SettingsContext.js';

export default function LoginWindow() {
  const [open, setOpen] = useState(false);
  const [currentUser, setCurrentUser] = useContext(AuthContext);
  const [dialogMessage, setDialogMessage] = useState(null);
  const [dialogTitle, setDialogTitle] = useState('Login to SymphonEase');
  const [loginMode, setLoginMode] = React.useState('login');
  const [signupLoginButton, setSignupLoginButton] = React.useState('Sign Up');

  // fields
  // email
  const [email, setEmail] = React.useState('');
  const [emailError, setEmailError] = React.useState('');
	const [okDisabled, setOkDisabled] = React.useState(true);

  // password
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  // confirmPassword
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [confirmPasswordError, setConfirmPasswordError] = React.useState('');
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
	const [settings, setSettings, performanceSkills, setPerformanceSkills, debugResult, setDebugResult] = useContext(SettingsContext);

	const theme=useTheme();

  const clearFields = () => {
    setEmail('');
    setEmailError('');
    setPassword('');
    setPasswordError('');
    setConfirmPassword('');
    setConfirmPasswordError('');
    setDialogMessage(null);
    setLoginMode('login');
    setDialogTitle('Login to SymphonEase');
    setSignupLoginButton('Sign Up');
  }
  const validateEmail = (anEmail) => {

    if (anEmail === null || anEmail === '') {
      setEmailError('Please enter an e-mail address')
      return false;
    }
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValidEmail = emailRegex.test(anEmail);

    if (!isValidEmail) {
      setEmailError('Please enter an appropriate e-mail');
      return false;
    }
    setEmailError('');
    return true;
  }

  const validateConfirmPassword = (aPassword, aConfirmPassword) => {

    if (aPassword !== aConfirmPassword) {
      setConfirmPasswordError('Passwords do not match');
      return false;
    }
    setConfirmPasswordError('');
    return true;
  }

  const validatePassword = (aPassword) => {
    let returnValue = true;

    if (loginMode === 'signup') {
      returnValue = validateConfirmPassword(aPassword, confirmPassword);
    }

    if (aPassword.length < 8) {
      setPasswordError('Enter a valid Password => 8 characters');
      return false;
    } 
    setPasswordError('');

    return returnValue;
  }

  const handleOk = async () => {
    if (loginMode === 'login') {
			var googleResponse = null;
			// authenticate through Google
			try {
				googleResponse = await signInWithEmail(email.toString(), password.toString())
				if (googleResponse === false) {
					setDialogMessage("Sign in failed.");
					return;
				}
			} catch (error) {
				console.log(error);
				setDialogMessage("Sign in failed.");
				return;
			}
			// see if the person is in our system
			const user = {user:googleResponse.user};
			try {
				const personResponse = await LoginClient.postLogin(googleResponse.user)
				const personData = await personResponse.json();
				user.person = personData.user;
				//APIClientManager.setPersonID(user.person.id);
				setCurrentUser(user);
			}
			catch (error) {
				setDialogMessage("Login failed, contact the System Administrator for access.");
				return;
			}
    }
    else {
      createUserWithEmail(email.toString(), password.toString())
        .then((response)=>{
          LoginClient.postLogin(response.user)
          .then(response => response.json())
          .then((data) => {
						if (data.status !== 'success') {
							setDialogMessage("We saved your Authentication information, however you do not have access to this site. Contact the site Administrator for access.");
							return;
						}
						setCurrentUser({user:response.user, person:data.user});
						setOpen(false)
          });
        })
        .catch((error)=>{
          setDialogMessage("Create account failed.");
        });
    }
  };

	const handleGoogleLoginRedirect = async () => {
		await signInWithGoogleRedirect();
	}

  const handleGoogleLogin = async () => {
		//await signInWithGoogleRedirect();
		
		signInWithGooglePopup()
			.then(async (response) => {
				const authUser = response.user;

				try {
					const loginResponse = await LoginClient.postLogin(authUser);
					const loginJson = await loginResponse.json();
					if (loginJson.status === 'success') {
						const person = loginJson.user;
						setCurrentUser({user: authUser, person: person});
					}
					else {
						const errorMessage = loginJson.message;
						setDialogMessage(errorMessage);
						return;
					}
				}
				catch (error) {
					console.log(error);
					setDialogMessage("Login failed, contact the System Administrator for access.");
					return;
				}
			})
			.catch((error) => {
				console.log(error);
			});
  }

  const checkForEnter = (event) => {
    if(event.keyCode === 13){
      handleOk();
    } 
  }
  const handleClickOpen = () => {
    clearFields();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCancel = () => {
    handleClose();
  };

  const handleSignup = () => {
    setDialogMessage(null);
    if (loginMode === 'login') {
      setLoginMode('signup');
      setDialogTitle('Sign up for SymphonEase');
      setSignupLoginButton('Login with Account');
			setConfirmPassword('');
    }
    else {
      setLoginMode('login');
      setDialogTitle('Login to SymphonEase');
      setSignupLoginButton('Sign Up');   
			setConfirmPassword('');   
    }

  };

	useEffect(() => {	
		const emailPasswordInValid = (email === null || email === undefined || email.length < 1 || 
			(emailError !== null && email.Error !== undefined && emailError.length > 0) || 
			password === null || password === undefined || password.length < 1 || 
			(passwordError !== null && passwordError !== undefined && passwordError.length > 0));

		if ((loginMode === 'login') || emailPasswordInValid) {
			setOkDisabled(emailPasswordInValid)
		}
		else {
			const confirmPasswordInValid = (confirmPassword === null || confirmPassword === undefined || confirmPassword.length < 1 ||
				(confirmPasswordError !== null && confirmPasswordError !== undefined && confirmPasswordError.length > 0));
			setOkDisabled(confirmPasswordInValid);
		}
	}, [email, password, emailError, passwordError, loginMode, confirmPassword]);

  return (
    <React.Fragment>
        <Button variant="contained" 
						sx={{
							borderRadius:10, 
							marginLeft:'auto',
							color: theme.palette.getContrastText(theme.palette.common.orange),
							backgroundColor: theme.palette.common.orange,
							'&:hover': {
								backgroundColor: theme.palette.common.orange}}} 
						onClick={handleClickOpen}>
        <LoginRoundedIcon/><Typography sx={{paddingLeft:'0.5em'}}><b>Login / Sign Up</b></Typography>
        </Button>
        <Dialog open={open} onClose={handleClose} sx={{flexGrow:1}}>
          <form>
        <DialogTitle><b>{dialogTitle}</b></DialogTitle>
        <DialogContent>

            <DialogContentText color='primary'>
            {dialogMessage}
            </DialogContentText>
						<Divider sx={{paddingTop:1}}>Login / Signup using one of these providers</Divider>
						<Grid container spacing='1em' justifyContent='center' sx={{paddingTop:1}}>
              <Grid item='true'>
									<Tooltip title="Login with Google Popup">
										<Button variant='outlined' component={Link} to='/' onClick={handleGoogleLogin} sx={{height: '2em'}}><img src={googleIcon} alt="google logo" height='100%'/></Button>
									</Tooltip>
              </Grid>      
							<Grid item='true'>
									<Tooltip title="Login with Google Redirect">
										<Button variant='outlined' component={Link} to='/' onClick={handleGoogleLoginRedirect} sx={{height: '2em'}}><img src={googleIcon} alt="google logo" height='100%'/></Button>
									</Tooltip>
              </Grid>               
            </Grid>
						<Divider sx={{paddingTop:1}}>Or Login / Signup using an e-mail and password</Divider>
            <TextField
              
              margin="dense"
              id="email"
              label="Email Address"
              type="email"
              fullWidth
              variant="outlined"
              autoComplete='on'
              color='primary'
              onKeyDown={checkForEnter}
              onChange={event => {setEmail(event.target.value); validateEmail(event.target.value); }}
              value={email}
              helperText={emailError}
              error={emailError !== ''}/>
            <TextField
              margin="dense"
              id="password"
              label="Password"
              autoComplete='on'
              type={showPassword ? "text" : "password"}
              fullWidth
              variant="outlined"
              color='primary'
              onKeyDown={checkForEnter}
              onChange={event => {setPassword(event.target.value); validatePassword(event.target.value); }}
              helperText={passwordError}
              error={passwordError !== ''}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {setShowPassword(!showPassword)} }
                      edge="end"
                      tabIndex={-1}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}/>
            {loginMode === 'signup' &&
              <TextField
                margin="dense"
                id="confirmPassword"
                label="Confirm Password"
                type={showConfirmPassword ? "text" : "password"}
                fullWidth
                variant="outlined"
                autoComplete='on'
                color='primary'
                onKeyDown={checkForEnter}
                onChange={event => {setConfirmPassword(event.target.value); validateConfirmPassword(password, event.target.value); }}
                helperText={confirmPasswordError}
                error={confirmPasswordError !== ''}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {setShowConfirmPassword(!showConfirmPassword)} }
                        edge="end"
                        tabIndex={-1}
                      >
                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}/>
            }


            <Divider sx={{paddingTop:1}}/>

            <Grid container flexDirection='row' justifyContent='space-between' sx={{padding:'0.5em', paddingLeft:0, paddingRight:0}}>
              <Grid display='flex' alignItems='center'>
                  <MUILink underline="hover" onClick={handleSignup} sx={{'&:hover': {cursor: 'pointer'}}}>
                    {loginMode === 'login' && <Typography>Need an Account? Sign up.</Typography>}
                    {loginMode === 'signup' && <Typography>Already have an Account? Log in.</Typography>}
                  </MUILink>
              </Grid>
              <Grid display='flex' alignItems='center'>
                <ButtonGroup variant="contained" sx={{borderRadius:10, marginLeft:'auto'}}>
                    <Button sx={{borderRadius:10, p:1}} component={Link} to='/' onClick={handleOk} disabled={okDisabled} color='primary'><CheckRoundedIcon/></Button>
                    <Button sx={{borderRadius:10, p:1}} onClick={handleCancel} color='primary'><CloseRoundedIcon/></Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          </DialogContent>
          </form>
        </Dialog>
    </React.Fragment>
  );
}
