// React imports
import React, {useContext, useState, useEffect, createContext} from 'react';
import dayjs from 'dayjs';

// material imports
import { Box, Grid, Typography, Divider, IconButton, Tooltip, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';

// project imports
import ContentBackground from '../../common/ContentBackground';
import ArtiztaPageTitle from '../../common/ArtiztaPageTitle';
import ArtiztaCard from '../../common/ArtiztaCard';
import OrganizationName from '../organizations/components/OrganizationName';
import OrganizationChange from '../organizations/components/OrganizationChange.js';
import { OrganizationContext } from '../../utils/OrganizationContext.js';
import ArtiztaSelect from '../../common/ArtiztaSelect.js';
import ArtiztaIconButton from '../../common/ArtiztaIconButton.js';
import EditSeasonComponent from './EditSeasonComponent.js';
import OrganizationsClient from '../organizations/OrganizationsClient.js';
import SeriesChip from '../events/SeriesChip.js';
import SeriesDisplay from './SeriesDisplay.js';
import EventsClient from '../events/EventsClient.js';
import ButtonGroupBar from '../../common/ButtonGroupBar.js';
import ButtonGroupButton from '../../common/ButtonGroupButton.js';
import EditSeriesComponent from './EditSeriesComponent.js';
import ArtiztaEditDialog from '../../common/ArtiztaEditDialog.js';
import WeekCalendarToggle from './WeekCalendarToggle.js';
import SeasonCalendar from './calendarView/SeasonCalendar.js';
import SeasonClient from './SeasonClient.js';

export const SeasonDesignerContext = createContext();

export default function SeasonDesignerPage(props) {
	const [currentOrganization, setCurrentOrganization] = useContext(OrganizationContext);
	const [editAddSeason, setEditAddSeason] = useState(null);
	const [editSeason, setEditSeason] = useState(null);
	const [events, setEvents] = useState(null);
	const [seasonStatus, setSeasonStatus] = useState('Public');
	const [editSeries, setEditSeries] = useState(null);
	const [editSeriesTitle, setEditSeriesTitle] = useState('Edit Series');
	const [editSeriesIndex, setEditSeriesIndex] = useState(null);
	const [canEditName, setCanEditName] = useState(true);
	const [editSeasonTitle, setEditSeasonTitle] = useState('Edit Season');
	const [seriesList, setSeriesList] = useState([]);
	const [calendarDisplayMode, setCalendarDisplayMode] = useState('weekly');

	// SeriesDesignerContext
	const [selectedSeason, setSelectedSeason] = useState(null);
	const [selectedSeries, setSelectedSeries] = useState(null);

	const handleSeasonSelect = (season) => {
		if (season==='') { 
			setSelectedSeason(null);
			setSeasonStatus(null);
	 	}
		else { 
			setSelectedSeason(season); 
			if (season.status !== undefined) {
				setSeasonStatus(season.status);
			}
			else {
				setSeasonStatus('Public');
			}
		}
	}

	const handleSeriesSelect = (series) => {
		if (series==='') { 
			setSelectedSeries(null);
	 	}
		else { 
			setSelectedSeries(series); 
		}
	}

	/** When Add Season is pressed
	 * 
	 */
	const handleAddSeason = () => {
		const newSeason = OrganizationsClient.autoCalculateNextSeason(currentOrganization);

		setEditAddSeason(newSeason);
	}

	/** When Save is pressed on the Add Season Dialog
	 * 
	 */
	const handleSaveAddSeason = async (newSeason) => {
		const newOrg = await OrganizationsClient.addSeason(currentOrganization, newSeason);
		setCurrentOrganization(newOrg);
		setSelectedSeason(newSeason);		
		setEditAddSeason(null);
	}

	/** When Cancel is pressed on the Add Season Dialog
	 * 
	 */
	const handleCancelAddSeason = () => {
		setEditAddSeason(null);
	}

	/** When the Edit button is pressed for a season
	 * 
	 */
	const handleEditSeason = () => {
		setEditSeasonTitle(`Edit the ${selectedSeason.name} Season`);
		setEditSeason(selectedSeason);
	}

	/** When Cancel is pressed on the Edit Season Dialog
	 * 
	 */
	const handleCancelEditSeason = () => {
		setEditSeason(null);
	}


	/** When Ok is pressed on the Edit Season Dialog
	 * 
	 */
	const handleSaveEditSeason = async (aSeason) => {
		const newOrg = await OrganizationsClient.updateSeason(currentOrganization, aSeason);
		setCurrentOrganization(newOrg);
		setSelectedSeason(aSeason);
		setEditSeason(null);
	}

	/** When Add Series is pressed
	 * 
	 */
	const handleAddSeries = () => {
		setCanEditName(true);
		setEditSeriesIndex(null);
		setEditSeriesTitle(`Add a Series to the ${selectedSeason.name} Season`);
		setEditSeries({status:'Draft'});
	}

	/** When Edit Series is pressed
	 * 
	 */
	const handleEditSeries = () => {
		setCanEditName(false);
		setEditSeriesIndex(selectedSeason.series.findIndex((series)=>{return series.name === selectedSeries.name}));
		setEditSeriesTitle(`Edit the ${selectedSeries.name} Series`);
		setEditSeries(selectedSeries);
	}

	const handleSaveEditSeries = async (aSeries) => {
		if (editSeriesIndex === null) {
			const newOrganization = await OrganizationsClient.addSeriesToSeason(currentOrganization, selectedSeason, aSeries);
			setCurrentOrganization(newOrganization);
		}
		else {
			const newOrganization = await OrganizationsClient.updateSeriesInSeason(currentOrganization, selectedSeason, aSeries);
			setCurrentOrganization(newOrganization);
		}
		setEditSeries(null);
		// setSelectedSeason({...selectedSeason});
		setSelectedSeries(aSeries);
	}

	const handleCancelEditSeries = (aSeries) => {
		setEditSeries(null);
		setEditSeriesIndex(null);
	}

	useEffect(()=>{
		// reselect whatever should be selected
		if (selectedSeason !== null && selectedSeason !== undefined) {
			setSelectedSeason(currentOrganization.seasons.find((season)=>{return season.name === selectedSeason.name}));
			if (selectedSeries !== null && selectedSeries !== undefined) {
				setSelectedSeries(selectedSeason.series.find((series)=>{return series.name === selectedSeries.name}));
			}
		}

	},[currentOrganization]);

	useEffect(()=>{
		if (selectedSeason !== null && selectedSeason !== undefined && selectedSeason.series !== null && selectedSeason.series !== undefined) {
			setSeriesList([...selectedSeason.series, ...[{name:'All'}]]);
		}
	},[selectedSeason]);

	const formatDate = (theDate) => {

		return dayjs(theDate).format('MMMM, YYYY')
	}

	const fetchEvents = async () => {
		const theEvents = await EventsClient.getAll(currentOrganization);
		setEvents([...theEvents]);
	}

	const handleSaveEvent = async (event) => {
		// event.season = selectedSeason.name;
		// event.status = 'Draft';
		// if (selectedSeries !== null && selectedSeries !== undefined) {
		// 	event.series = selectedSeries.name;
		// 	event.status = selectedSeries.status;
		// }
		const savedEvent = await EventsClient.save(currentOrganization, event);
		fetchEvents();
		return savedEvent;
	}

	const handleDeleteEvent = async (event) => {
		await EventsClient.delete(currentOrganization, event);
		
		fetchEvents();
		// const index = events.findIndex((e)=>(e.name===event.name && e.performances[0].date===event.performances[0].date));
		// events.splice(index, 1);
	}

	useEffect(()=>{
		if (currentOrganization !== null) {
			fetchEvents();
		}
	},[currentOrganization]);

	return (
		<ContentBackground>
			<ArtiztaEditDialog 
					title={editSeriesTitle} 
					item={editSeries}
					onSave={handleSaveEditSeries}
					onCancel={handleCancelEditSeries}>
				<EditSeriesComponent canEditName={canEditName}/>
			</ArtiztaEditDialog>
			<ArtiztaEditDialog 
					title={editSeasonTitle} 
					item={editAddSeason}
					onSave={handleSaveAddSeason}
					onCancel={handleCancelAddSeason}
					overrideSaveDisabled={true}>
				<EditSeasonComponent canEditName={true}/>
			</ArtiztaEditDialog>
			<ArtiztaEditDialog 
					title={editSeasonTitle} 
					item={editSeason}
					onSave={handleSaveEditSeason}
					onCancel={handleCancelEditSeason}
					overrideSaveDisabled={true}>
				<EditSeasonComponent canEditName={false}/>
			</ArtiztaEditDialog>
			<ArtiztaCard>
				<SeasonDesignerContext.Provider value={[selectedSeason, setSelectedSeason, selectedSeries, setSelectedSeries]}>
					<ArtiztaPageTitle>Season Designer</ArtiztaPageTitle>
					{(currentOrganization === null || currentOrganization === undefined) ?
						<OrganizationChange/>
						:
						<Box>
							<Grid container justifyContent='space-between' alignItems='center' direction='row'>
								<Grid item xs={3.5}>
									<Grid item container direction='row' alignItems='center' spacing={1}>
										{/* <Grid item xs={4}>
											Select Season
										</Grid> */}
										<Grid item xs={8}>
											<ArtiztaSelect
												itemList={currentOrganization.seasons}
												id='sd-season-select'
												label='Season'
												value={selectedSeason}
												onChange={handleSeasonSelect}/>
										</Grid>
										<Grid item xs={1}>
											<ButtonGroupBar size='small'>
												<ButtonGroupButton 
													toolTip={selectedSeason===null ? 'Select a Season to Edit' : `Edit the ${selectedSeason.name} Season`} 
													onClick={handleEditSeason}
													disabled={selectedSeason===null}>
													<EditRoundedIcon/>
												</ButtonGroupButton>
												<ButtonGroupButton 
													toolTip={`Add a new Season`} 
													onClick={handleAddSeason}>
													<AddRoundedIcon/>
												</ButtonGroupButton>
											</ButtonGroupBar>		
										</Grid>												
									</Grid>
								</Grid>
								<Grid item xs={4.5}>
								{selectedSeason !== null &&
									<Typography>
										{formatDate(selectedSeason.startDate)} - {formatDate(selectedSeason.endDate)}<br/>
										This season is {seasonStatus}.
									</Typography>
								}
								</Grid>
								<Grid item xs={4}>
									<OrganizationName/>
								</Grid>
							</Grid>
						</Box>					
					}
					{selectedSeason !== null && selectedSeason !== undefined &&
						<Box sx={{marginTop:'1em'}}>
							<Grid container justifyContent='space-between' alignItems='center' direction='row'>
								<Grid item xs={3.5}>
									<Grid item container direction='row' alignItems='center' spacing={1}>
										{/* <Grid item xs={4}>
											Select Series
										</Grid> */}
										<Grid item xs={8}>
											<ArtiztaSelect
												itemList={seriesList}
												id='sd-series-select'
												label='Series'
												value={selectedSeries}
												onChange={handleSeriesSelect}/>
										</Grid>
										<Grid item xs={1}>
											<ButtonGroupBar size='small'>
													<ButtonGroupButton 
														toolTip={selectedSeries===null ? 'Select a Series to Edit' : `Edit the ${selectedSeries.name} Series`} 
														onClick={handleEditSeries}
														disabled={selectedSeries===null}>
														<EditRoundedIcon/>
													</ButtonGroupButton>											
													<ButtonGroupButton 
														toolTip={`Add a new Series to the ${selectedSeason.name} Season`} 
														onClick={handleAddSeries}
														disabled={selectedSeason===null}>
														<AddRoundedIcon/>
													</ButtonGroupButton>											
											</ButtonGroupBar>	
										</Grid>												
									</Grid>
								</Grid>
								<Grid item xs={4.5}>
								{selectedSeries !== null ?
									<Box>
										<SeriesChip series={selectedSeries}/>
										{selectedSeries.name === 'All' ? 
											<Typography>
												Displaying All Events for the {selectedSeason.name} Season.
											</Typography>
										:
											<Typography>
												Displaying Events from the {selectedSeries.name} Series. This Series is {selectedSeries.status}.
											</Typography>
										}
									</Box>
									:
									<Box>
										<Typography>
											Displaying Events in the {selectedSeason.name} Season that are not associated with a Series.
										</Typography>
									</Box>		
								}
								</Grid>
								<Grid item xs={4} container justifyContent='flex-end'>
									<WeekCalendarToggle mode={calendarDisplayMode} setMode={setCalendarDisplayMode}/>
								</Grid>
							</Grid>
						</Box>
					}
					{selectedSeason !== null && selectedSeason !== undefined &&
						<Box sx={{marginTop:'1em'}}>
								<Divider/>
								{calendarDisplayMode === 'weekly' ?
									<SeriesDisplay 
											events={events} 
											selectedSeries={selectedSeries} 
											season={selectedSeason} 
											onSaveEvent={handleSaveEvent} 
											onDeleteEvent={handleDeleteEvent}/>
									:
									<SeasonCalendar 
											events={events} 
											onSaveEvent={handleSaveEvent} 
											onDeleteEvent={handleDeleteEvent}
											refetch={fetchEvents}/>
								}
						</Box>
					}
				</SeasonDesignerContext.Provider>
			</ArtiztaCard>
		</ContentBackground>
	)
}