// project imports
import APIClientManager from '../../utils/APIClientManager';

/**
 * 	LocationsClient encapsulates the API calls to Locations
 * 		getAll - returns all locations
 *    get - returns a location given an ID
 *
 */

export default class LocationsClient {

	static getAll = async () => {
		const response = await APIClientManager.get('locations');
		const json = await response.json();
		return json;
	}

	static get = async (id) => {
		const response = await APIClientManager.get(`locations/${id}`);
		const json = await response.json();
		return json;
	}
}