// React imports
import React, {useContext} from 'react';

// material

// Project

import WorksSearch from '../../works/components/WorksSearch';
import { ItemContext } from '../../../common/ArtiztaEditComponent';

export default function FindEventWorkComponent() {
	const [editingItem, setEditingItem] = useContext(ItemContext);

	const handleSelectWork = (selectedWork) => {
		setEditingItem(selectedWork);
	}

	return (
		<WorksSearch isEditable={false} onSelectWork={handleSelectWork}/>
	)
}