import { useState } from 'react';

// material
import { ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import HorizontalSplitRoundedIcon from '@mui/icons-material/HorizontalSplitRounded';


export default function WeekCalendarToggle({mode, setMode, variant='contained', size='small'}) {

	const handleChange = (event, newMode) => {
		setMode(newMode);
	}

	const control = {
    value: mode,
    onChange: handleChange,
    exclusive: true,
  };

	return (
		<ToggleButtonGroup size={size} variant={variant} {...control} color='primary' aria-label="Small sizes" sx={{borderRadius:10}}>
			<Tooltip title="Set Display to Weekly View">
				<ToggleButton size={size} variant={variant} value="weekly"  key='weekly'>
					<HorizontalSplitRoundedIcon/>
				</ToggleButton>
			</Tooltip>
			<Tooltip title="Set Display Calendar View">
				<ToggleButton size={size} variant={variant} value="calendar"  key='calendar'>
					<CalendarMonthRoundedIcon/>
				</ToggleButton>
			</Tooltip>
		</ToggleButtonGroup>
	)
}