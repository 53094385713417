import React, { useContext, useEffect, useState } from 'react';

// material
import { Box, Grid, Typography, Accordion, AccordionDetails, AccordionSummary, Tooltip, useTheme } from '@mui/material';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import TheaterComedyRoundedIcon from '@mui/icons-material/TheaterComedyRounded'; // Performances
import LibraryMusicRoundedIcon from '@mui/icons-material/LibraryMusicRounded'; // works
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded'; // featured performers

// project
import ArtiztaBorderBox from '../../common/ArtiztaBorderBox';
import EventQuickEdit from './EventQuickEdit';
import EventsClient from '../events/EventsClient';
import EventDisplay from '../events/EventDisplay';
import ButtonGroupBar from '../../common/ButtonGroupBar';
import ButtonGroupButton from '../../common/ButtonGroupButton';
import ArtiztaAlertDialog, { clearAlertDialog } from '../../common/ArtiztaAlertDialog';
import { SeasonDesignerContext } from './SeasonDesignerPage';
import SeasonClient from './SeasonClient';

export default function WeeklyEvents ( { displayEvents, week, onSaveEvent, onDeleteEvent, onEditDetails } ) {
	const [alertDialogOptions, setAlertDialogOptions] = useState(clearAlertDialog);
	const [editingDisplayEvent, setEditingDisplayEvent] = useState(null);
	const [weekDisplayEvents, setWeekDisplayEvents] = useState(null);
	const [selectedSeason, setSelectedSeason, selectedSeries, setSelectedSeries] = useContext(SeasonDesignerContext);
	const [isHovering, setIsHovering] = useState(false);

	const theme = useTheme();

	const handleAddEvent = () => {
		if (editingDisplayEvent !== null) {
			setAlertDialogOptions({
				open:true,
				title:'Do you want to cancel editing an event?',
				message:'You currently editing an event, do you want to cancel and lose your work?',
				onAgree: () => {
					addNewDisplayEvent()
					setAlertDialogOptions(clearAlertDialog)},
				onDisagree: () => {
					setAlertDialogOptions(clearAlertDialog)}
			});
			return;
		}
		addNewDisplayEvent();
	}

	const addNewDisplayEvent = () => {
		const theNewDisplayEvent = newDisplayEvent();
		setEditingDisplayEvent(theNewDisplayEvent);
	}

	const newDisplayEvent = () => {

		const templateEvent = {
			performances: [{date:week.startDateJS.hour(20).minute(0).second(0).millisecond(0).toDate()}]
		};

		if (selectedSeason !== null && selectedSeason !== undefined) {
			templateEvent.season = selectedSeason.name;
		}
		if (selectedSeries !== null && selectedSeries !== undefined && selectedSeries.name !== 'All' ) {
			templateEvent.series = selectedSeries.name;
		}

		const templateDisplayEvent = SeasonClient.displayEventForEvent(templateEvent, selectedSeason, theme);
		templateDisplayEvent.order=weekDisplayEvents.length;
		templateDisplayEvent.new=true;
		weekDisplayEvents.push(templateDisplayEvent);
		setWeekDisplayEvents([...weekDisplayEvents]);
		return templateDisplayEvent;
	}


	const handleEditEvent = (displayEvent) => {
		setEditingDisplayEvent(displayEvent);
	}

	const handleDeleteEvent = (displayEvent) => {
		setAlertDialogOptions({
			open:true,
			title:'Are you sure you want to delete this Event?',
			message:`This will remove '${displayEvent.event.name}' and all associate Event and Performance data permanently. Are you sure?`,
			onAgree: () => {
				onDeleteEvent(displayEvent.event);
				setAlertDialogOptions(clearAlertDialog)},
			onDisagree: () => {
				// no harm no foul
				setAlertDialogOptions(clearAlertDialog)}
		});
	}

	const handleCancelEditEvent = (event) => {
		if (editingDisplayEvent.new) {
			const index = weekDisplayEvents.findIndex((e)=>(e.order===editingDisplayEvent.order));
			weekDisplayEvents.splice(index, 1);
			setWeekDisplayEvents([...weekDisplayEvents]);
		}
		setEditingDisplayEvent(null);
	}
	
	const handleSaveEvent = (event) => {
		onSaveEvent(event);
		setEditingDisplayEvent(null);
	}

	useEffect(() => {
		if (displayEvents !== null && displayEvents !== undefined) {
			for (var i=0; i<displayEvents.length; i++) {
				displayEvents[i].order = i;
			}
			setWeekDisplayEvents([...displayEvents]);
		}
		else {
			setWeekDisplayEvents([]);
		}
	}, [displayEvents]);

	return (
		<React.Fragment>
		<ArtiztaAlertDialog dialogOptions={alertDialogOptions}/>
		<Grid container direction='column' spacing={0.25} sx={{marginTop:'0.05em', marginBottom:'0.05em'}}>
			{weekDisplayEvents !== null && weekDisplayEvents.map((item)=>{

				const generateToolTip = (itemCollection, itemName) => {
					if (itemCollection === null || itemCollection === undefined || itemCollection.length === 0) {
						return `No ${itemName}s`;
					}
					else {
						return `${itemCollection.length} ${itemName}${itemCollection.length !== 1 ? 's' : ''}`;
					}
				}
				const numPerformances = (item.event.performances !== undefined && item.event.performances !== null ? item.event.performances.length : 0);
				const numWorks = (item.event.works !== undefined && item.event.works !== null ? item.event.works.length : 0);
				const numFeaturedPerformers = (item.event.featuredPerformers !== undefined && item.event.featuredPerformers !== null ? item.event.featuredPerformers.length : 0);
				const performancesTooltip = generateToolTip(item.event.performances, 'Performance');
				const worksTooltip = generateToolTip(item.event.works, 'Work');
				const featuredPerformersTooltip = generateToolTip(item.event.featuredPerformers, 'Featured Performer');

				return (
					<Box key={`we-${item.event.name}-${item.event.performances[0].date.toString()}`}>
						<Grid container direction='row' item xs={12} >
							<Grid item xs={11.5}>
								{editingDisplayEvent !== null && item.order === editingDisplayEvent.order  ?
									<Box sx={{paddingTop:'0.5em'}}>
										<EventQuickEdit 
											event={item.event} 
											onSave={handleSaveEvent} 
											onCancel={handleCancelEditEvent}
											onEditDetails={onEditDetails}/>		
									</Box>
									:
									<Accordion elevation={0} disableGutters={true} sx={{m:0, s:0, p:0 }}>
										<AccordionSummary sx={{
											minHeight: "0px", 
											cursor: 'pointer', 
											s:0, 
											p:0, 
											".MuiAccordionSummary-content": { margin: 0 }}}
										>
											<ArtiztaBorderBox sx={{
												border:1, 
												borderColor: item.textColor, 
												backgroundColor: item.backgroundColor, 
												marginBottom:'0.1em',
												marginTop:'0.1em',
												width:'100%' }}
											>
												{/* Little icons */}
												<Grid container justifyContent={'space-between'}>
													<Grid item xs={10.5}>
														<Typography variant='h3' color={item.textColor}>{item.event.name}</Typography>
													</Grid>
													{/* Performances */}
													<Grid item xs={0.5} container alignItems={'center'}>
														<Tooltip title={performancesTooltip}>
															{numPerformances > 0 ? 
																<TheaterComedyRoundedIcon sx={{color: item.textColor}} />

															:
																<TheaterComedyRoundedIcon sx={{color: item.textColor+'99'}}/>
															}
														</Tooltip>
													</Grid>
													{/* Works */}
													<Grid item xs={0.5} container alignItems={'center'}>
														<Tooltip title={worksTooltip}>
															{numWorks > 0 ? 
																<LibraryMusicRoundedIcon sx={{color: item.textColor}} />
															:
																<LibraryMusicRoundedIcon sx={{color: item.textColor+'99'}}/>
															}
														</Tooltip>
													</Grid>
													{/* Featured Performers */}
													<Grid item xs={0.5} container alignItems={'center'}>
														<Tooltip title={featuredPerformersTooltip}>
														{numFeaturedPerformers > 0 ? 
																<PeopleAltRoundedIcon sx={{color: item.textColor}} />
															:
																<PeopleAltRoundedIcon sx={{color: item.textColor+'99'}}/>
															}
														</Tooltip>
													</Grid>
												</Grid>
											</ArtiztaBorderBox>
										</AccordionSummary>
										<AccordionDetails sx={{minHeight:'0px'}}>
												<Grid container direction='row' alignItems='flex-start'>
													<Grid item xs={11.5}>
														<EventDisplay event={item.event} showTitle={false}/>
													</Grid>
													<Grid item xs={0.5}>
													<ButtonGroupBar variant='outline' size='small'>
														<ButtonGroupButton toolTip={`Edit '${item.event.name}'`} placement='bottom' onClick={() => handleEditEvent(item)}>
															<EditRoundedIcon/>
														</ButtonGroupButton>
														<ButtonGroupButton toolTip={`Delete '${item.event.name}'`} placement='bottom' onClick={() => handleDeleteEvent(item)}>
															<DeleteForeverRoundedIcon/>
														</ButtonGroupButton>
													</ButtonGroupBar>	
													</Grid>
												</Grid>
										</AccordionDetails>
									</Accordion>				
								}
							</Grid>
							<Grid item xs={0.5}>
								{editingDisplayEvent === null &&
								<ButtonGroupBar variant='outline' size='small'>
									<ButtonGroupButton toolTip={`Add an Event the week of ${week.startDateJS.format('ddd, M/D')}`} placement='left-end' onClick={() => handleAddEvent(week)}>
										<AddCircleRoundedIcon/>
									</ButtonGroupButton>
								</ButtonGroupBar>	
								}
							</Grid>
						</Grid>
					</Box>
				)
			})}
			{displayEvents.length === 0 && editingDisplayEvent===null &&
				<Grid container direction='row' item xs={12}>
					<Grid item xs={12}>
						<Tooltip title={`Add an Event the week of ${week.startDateJS.format('ddd, M/D')}`}>
							<Box sx={{
								borderRadius:2,									
								width:'100%', 
								height:'100%',
								'&:hover': {
									cursor:'pointer',
									border: 1,
									borderStyle:'dashed'}
								}}
								onClick={()=>handleAddEvent()}
								onMouseEnter={()=>setIsHovering(true)}
								onMouseLeave={()=>setIsHovering(false)}	
								>
									{isHovering &&
										<Grid container justifyContent='center' alignContent='center' sx={{height:'100%'}}>
											<Grid item >
												<Typography variant='h3'>{`Add an Event the week of ${week.startDateJS.format('ddd, M/D')}`}</Typography>
											</Grid>
										</Grid>
									}
							</Box>
						</Tooltip>
					</Grid>
				</Grid>
			}
		</Grid>
		</React.Fragment>
	)
}

