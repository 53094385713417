// React imports
import React, { useState, useContext} from 'react';
import dayjs from 'dayjs';

// Material Imports
import { Typography, TextField, Grid } from '@mui/material';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// project imports

import { ItemContext } from '../../common/ArtiztaEditComponent';


export default function EditSeasonComponent({ canEditName=true }) {
	const [imageData, setImageData] = useState(null);
	const [editingItem, setEditingItem] = useContext(ItemContext);	

	const setStartDate = (newValue) => {
		setEditingItem({...editingItem, ...{startDate: newValue}});
	}

	const setEndDate = (newValue) => {
		setEditingItem({...editingItem, ...{endDate: newValue}});
	}

	const handleImageSelect = () => {

	}

	const handleDeleteImage = () => {

	}

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<Grid container direction='column' >
				<Grid item >
					{canEditName ?
						<TextField
							fullWidth
							sx={{marginTop:1.5}}
							id="es-name"
							name="es-name"
							label="Season Name"
							defaultValue={editingItem.name}
							onChange={(e) => setEditingItem({...editingItem, ...{name: e.target.value}})}
						/>
					:
						<Typography variant='h6'>{editingItem.name}</Typography>
					}
				</Grid>
				<Grid item>
					<DatePicker sx={{width:'100%', marginTop:1.5}}
						label='Season Start Date'
						defaultValue={dayjs(editingItem.startDate)}
						onChange={(newValue)=>setStartDate(newValue)}/>
				</Grid>
				<Grid item>
					<DatePicker sx={{width:'100%', marginTop:1.5}}
						label='Season End Date'
						defaultValue={dayjs(editingItem.endDate)}
						onChange={(newValue)=>setEndDate(newValue)}/>
				</Grid>
				{/* <Grid item>
					<FileSelector 
						title='Season Logo'
						acceot='image/*'
						onSelect={handleImageSelect}
						onDelete={handleDeleteImage}
						dropZoneMessage='Drag an image to upload'>
							<img src={imageData} width='100%' alt='Event Image'/>
					</FileSelector>
				</Grid> */}
			</Grid>
		</LocalizationProvider>
	)
}