
// project imports
import APIClientManager from '../../utils/APIClientManager';

export class LoginClient {

	static postLogin(data) {
		return APIClientManager.post('login', data, LoginClient.options);
	}
}

