// project
import { ItemContext } from "../../../common/ArtiztaEditComponent"
import { AuthContext } from "../../../utils/AuthContext"
import FileSelector from "../../../common/FileSelector"
import PerformanceSkillsSelector from "./PerformanceSkillsSelector"
import { FirebaseFileClient } from "../../../utils/FirebaseFileClient"

// react
import { useState, useContext } from "react"

// material
import { Grid, TextField } from "@mui/material"

/* 
This would only be able to change:
- firstName
- lastName
- tagLine
- profile
- profileImage
- performanceSkills
*/
export default function PersonEditComponent() {
  // context
  const [ editingItem, setEditingItem ] = useContext(ItemContext)
  const [ currentUser ] = useContext(AuthContext)

  // state
  const [performanceSkills, setPerformanceSkills] = useState( editingItem!=null && editingItem.performanceSkills !== undefined ? editingItem.performanceSkills : [])

  // handle performance skills
  const handlePerformanceSkills = (newSkills) => {
    // state
    setPerformanceSkills(newSkills)

    // context
    setEditingItem({...editingItem, ...{performanceSkills: newSkills}})
  }

  // File Selector
  const [filename, setFilename] = useState('No File Selected')
  const [imageData, setImageData] = useState(editingItem.profileImage!=null ? editingItem.profileImage : null)
  const selectImage = (theFile, theImageData) => {
    if (theFile) {
      setImageData(theImageData);
      FirebaseFileClient.upload(theFile, 'images', (url) => {
        setFilename(url);
        // Context
        setEditingItem({...editingItem, ...{profileImage: url}})
      });
    }
  }
  const deleteImage = (url) => {
    setFilename('deleted');
    setImageData(null);
  }

  return (
    <Grid container>
      {/* Info & Image section */}
      <Grid container justifyContent={'space-between'} spacing={2} sx={{paddingTop:'1em'}}>
        {/* Name & Tagline */}
        <Grid item xs={8}>
          {/* First & Last Name */}
          <Grid item container direction={'row'} spacing={2} >
            <Grid item xs={6}>
              <TextField 
                id="firstName"
                name="firstName"
                label="First Name"
                fullWidth
                defaultValue={editingItem.firstName}
                onChange={(e) => setEditingItem({...editingItem, ...{firstName: e.target.value}})}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField 
                id="lastName"
                name="lastName"
                label="Last Name"
                fullWidth
                defaultValue={editingItem.lastName}
                onChange={(e) => setEditingItem({...editingItem, ...{lastName: e.target.value}})}
              />
            </Grid>
          </Grid>
          <TextField 
            sx={{marginTop: 2}}
            id="tagLine"
            name="tagLine"
            label="Tagline"
            fullWidth
            defaultValue={editingItem.tagLine}
            onChange={(e) => setEditingItem({...editingItem, ...{tagLine: e.target.value}})}
          />
        </Grid>
        {/* Image */}
        <Grid item xs={4}>
          <FileSelector
            title="Profile Image Picker"
            accept='image/*'
            onSelect={selectImage}
            onDelete={deleteImage}
            dropZoneMessage='Drag an image to upload'
            width={256}
            imageData={imageData}
          >
            <img src={imageData} alt='Selected' width={256}/>
          </FileSelector>
        </Grid>
      </Grid>
      {/* Profile */}
      <TextField 
        sx={{marginTop: 2}}
        id="profile"
        name="profile"
        label="Profile Description"
        multiline
        minRows={4}
        fullWidth
        defaultValue={editingItem.profile}
        onChange={(e) => setEditingItem({...editingItem, ...{profile: e.target.value}})}
      />

      {/* Performance Skills */}
      <PerformanceSkillsSelector selectedPerformanceSkills={performanceSkills} setSelectedPerformanceSkills={handlePerformanceSkills} />
    </Grid>
  )
}