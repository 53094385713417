import React, { useEffect, useState, useContext } from 'react';
import dayjs from 'dayjs';

// material
import { Grid, Typography } from '@mui/material';
import CalendarDay from './CalendarDay';
import { SeasonDesignerContext } from '../SeasonDesignerPage.js';

export default function CalendarWeek ( { 
			events, 
			startDateJS, 
			startDay=1, /* Sunday=0 */
			currentMonth, 
			currentYear,
			onEditEvent, onAddEvent } ) 
{
	const [days, setDays] = useState(null);
	const [selectedSeason, setSelectedSeason, selectedSeries, setSelectedSeries] = useContext(SeasonDesignerContext);

	const calculateDays = () => {
		const theDays=[];

		const weekDay = startDateJS.day();

		let currentDateJS = startDateJS.add(weekDay-startDay, 'day');

		for (let i=0; i<7; i++) {
				theDays.push(currentDateJS);

			currentDateJS = currentDateJS.add(1, 'day');
		}
		setDays(theDays);
	}

	const calculateBorders = (dayJS) => {
		const theBorders={top:0, right:0, bottom:0, left:0};

		if (dayJS.month() !== currentMonth) {	
			if (	(dayJS.month() > currentMonth && dayJS.year() === currentYear)
						|| dayJS.year() > currentYear) {
				theBorders.top = 1;
			}
			if (dayJS.month() < currentMonth || dayJS.year() < currentYear) {
				if (dayJS.add(1, 'day').month() === currentMonth ) {
					theBorders.right = 1;				}
			}
			return theBorders;
		}

		const daysSinceWeekStart = dayJS.date() - startDateJS.date();
		const daysToNextWeekStart = 7-daysSinceWeekStart;

		// top always unless the day is not in the current month
		if (dayJS.month() === currentMonth) {
			theBorders.top = 1;
		}
		// bottom only if there is no day below
		if (dayJS.add(1, 'week').month() !== currentMonth) {			
			if (dayJS.add(daysToNextWeekStart, 'day').month() !== currentMonth){
				theBorders.bottom = 1;
			}
		}
		// right always unless the day is not in the current month
		if (dayJS.month() === currentMonth) {
			theBorders.right = 1;
		}
		// left only if there is no day to the left in the current month
		if ( daysSinceWeekStart === 0 ) {
			theBorders.left = 1;
		}

		return theBorders;
	}

	useEffect(()=>{
		if (startDateJS !== null) {
			calculateDays();
		}
	}, [startDateJS]);

	return (
		<Grid container direction='row' >
			{days !== null && days.map((dayJS)=> {
				const borders = calculateBorders(dayJS);

				return (
					<Grid item container xs={12/7} key={dayJS.toDate()}
						sx={{ borderTop:borders.top,
									borderRight:borders.right,
									borderBottom:borders.bottom,
									borderLeft:borders.left,
									m:'0px'}} justifyContent='flex-start'>
						<CalendarDay 
							dayJS={dayJS} 
							events={events} 
							currentMonth={currentMonth}
							onEditEvent={onEditEvent}
							onAddEvent={onAddEvent} />
					</Grid>
				)
			})}
		</Grid>
	)
}