import APIClientManager from "./APIClientManager";

export default class RecommendationClient {
  // Validate API key
  static validate = async (openAiAPIKey) => {
    var bundle = {
      'type': 'validate',
      'item': 'validate',
      'token': openAiAPIKey
    }
    const response = await APIClientManager.post('recommendations', bundle)
    const json = await response.json()
    return json
  }
  
  // Work Recommendations
  static getWorkRecommendations = async (openAiAPIKey, item) => {
    var bundle = {
      'type': 'worksForEvent',
      'item': item,
      'token': openAiAPIKey
    }
    const response = await APIClientManager.post('recommendations', bundle)
    const json = await response.json()
    return json
  }

  // Event Description Recommendation
  static getDescriptionRecommendation = async (openAiAPIKey, item) => {
    const bundle = {
      'type': 'descriptionForEvent',
      'item': item,
      'token': openAiAPIKey
    }
    const response = await APIClientManager.post('recommendations', bundle)
    const json = await response.json()
    return json
  }
}

// worksForEvent,descriptionForEvent, descriptionForSeason, descriptionForSeries, descriptionForPerson