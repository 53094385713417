import React, {useState, useEffect} from 'react'
import SettingsClient from '../pages/settings/SettingsClient'
import SettingsClientV2 from '../pages/settings/SettingsClientV2'

export const SettingsContext = React.createContext()

export const SettingsContextProvider = ({ children }) => {
  const [settings, setSettings ] = useState(null)
	const [performanceSkills, setPerformanceSkills] = useState(null);
  const [settingsV2, setSettingsV2 ] = useState(null)
	const [debugResult, setDebugResult] = useState(null)

  // Get all of the people
  useEffect(() => {
    // const json = SettingsClient.getAll()
    // console.log(json)
    SettingsClient.getAll()
      .then(json => {
        setSettings(json)
      })

		if (performanceSkills === null) {
			SettingsClientV2.getPerformanceSkills()
			.then(skills=>setPerformanceSkills(skills))
		}
  },[])

	useEffect(()=>{
		console.log('debugResult', debugResult);
	}, [debugResult]);

  return (
    <SettingsContext.Provider value={[settings, setSettings, performanceSkills, setPerformanceSkills, debugResult, setDebugResult]}>
      {children}
    </SettingsContext.Provider>
  )
}