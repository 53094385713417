// React imports
import React, {useState, useContext} from 'react';
// Material imports
import { IconButton, Button, Divider } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { ArrowForward, MoreVert } from '@mui/icons-material';

// project imports
import ContentBackground from '../../common/ContentBackground.js';
import { OrganizationContext } from '../../utils/OrganizationContext.js';
import OrganizationChange from './components/OrganizationChange.js';


// Little helper Component here
function PeopleBlock({type, amount}) {
  return (
    <Grid xs={4}>
      <Button sx={{width: '100%', display: "flex", justifyContent: "space-between"}} >
        <Grid container direction='column' justifyContent="flex-start" alignItems='flex-start'>
          <p style={{'margin':0}}>{type}</p>
          <p style={{'margin':0, 'fontWeight':'normal'}}>{amount}</p>
        </Grid>
        <ArrowForward />
      </Button>
    </Grid>
  )
}

export default function Organizations(props) {
  // Context
  const [ currentOrganization/*, setCurrentOrganization*/ ] = useContext(OrganizationContext)

  // Change Org
  const [ changeOrg, setChangeOrg ] = useState(false)

  return (
    <ContentBackground>
      {(changeOrg) && <OrganizationChange setChangeOrg={setChangeOrg}/>}

      {/* Organization Header */}
      <Grid container justifyContent={'space-between'} alignItems={'flex-start'}>
        {(currentOrganization!= null) ?
          <Grid container>
            <img src={currentOrganization.darkLogo}  width={'250px'} style={{'paddingRight':'1em'}} alt='organization'/>
            {/* <h2>{currentOrganization.name}</h2> */}
          </Grid>
          :
          <h2>No Symphony Selected</h2>
        }
        <Grid>
          <Button onClick={()=> setChangeOrg(!changeOrg)}>
            Change Symphony
          </Button>
          {/* Selected new org button */}
          <IconButton 
            color='primary' 
            sx={{
              borderRadius:10,
              //backgroundColor: artiztaBrown,
              //color: artiztaWhite,
              //"&:hover": { backgroundColor: artiztaBrown }
              }}
            onClick={()=> setChangeOrg(!changeOrg)}
          >
            <MoreVert />
          </IconButton>
        </Grid>
      </Grid>

      {/* Org PAGE */}
      <Grid container justifyContent={"space-between"} spacing={4} sx={{marginTop: '1em', marginBottom: '1em'}}>
        <Grid xs={5.5}> 
          {/* ABOUT */}
          <Grid container>
            <h3>About</h3>
            <p> Descend into the mesmerizing melodies of Skyrim's ancient heart with the Whiterun Chamber Orchestra. Founded under the visionary guidance of King Olaf One-Eye during the revered First Era, this ensemble encapsulates the soul of the Nord legends. Within the hallowed halls of Dragonsreach, each performance reverberates with tales of heroes, dragons, and epochal battles. </p>
          </Grid>
        </Grid>
        <Divider orientation="vertical" variant='middle' flexItem/>
        <Grid xs={5.5}>
          {/* PEOPLE */}
          <Grid>
            <h3>People</h3>
            <Grid container spacing={2}>
              <PeopleBlock type={'Staff'} amount={30} />
              <PeopleBlock type={'Musician'} amount={50} />
              <PeopleBlock type={'Contract Musician'} amount={10} />
              <PeopleBlock type={'Board Members'} amount={5} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      
      {/* Events */}
      <Divider />
      <h1>Organization Feed (Events & To Do's could go here)</h1>
      <p>Ruminations on the Elder Scrolls</p>
    </ContentBackground>
  )
}

