import React, { useEffect, useState, useContext } from 'react';

// material
import { Grid, Box, Typography, useTheme, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import LabelImportantRoundedIcon from '@mui/icons-material/LabelImportantRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';

// project
import SeasonClient from '../SeasonClient';
import ArtiztaBorderBox from '../../../common/ArtiztaBorderBox';
import { SeasonDesignerContext } from '../SeasonDesignerPage.js';
import ArtiztaClickableBox from '../../../common/ArtiztaClickableBox.js';
import dayjs from 'dayjs';
import EventDisplay from '../../events/EventDisplay.js';
import ButtonGroupBar from '../../../common/ButtonGroupBar.js';
import ButtonGroupButton from '../../../common/ButtonGroupButton.js';

export default function CalendarDay ( { 
			dayJS, 
			events, 
			currentMonth,
			onEditEvent, 
			onAddEvent } ) 
{
	const [borders, setBorders] = useState({top:0, right:0, bottom:0, left:0});
	const [selectedSeason, setSelectedSeason, selectedSeries, setSelectedSeries] = useContext(SeasonDesignerContext);
	const [headerSX, setHeaderSX] = useState(null);
	const [dayEvents, setDayEvents] = useState(null);

	const [selectedEvent, setSelectedEvent] = useState(null);

	const daySX = {position:'relative', width:'100%', height:'100%', minHeight:'128px'}

	useEffect(()=>{
		if (events !== null && events !== undefined) {
			const theEvents = SeasonClient.eventsForDaySeriesSeason(events, dayJS.toDate(), selectedSeries, selectedSeason);
			setDayEvents(theEvents);
		}
	},[events, selectedSeries, selectedSeason, dayJS])

	useEffect(()=>{
		//console.log('setting dayEvents for '+dayJS.format('M/D'), dayEvents);
	},[dayEvents])

	const theme=useTheme();

	const isSeasonStart = () => {
		if (dayJS !== null && dayJS !== undefined && 
			selectedSeason !== null && selectedSeason !== undefined) {
			const seasonStartJS = dayjs(selectedSeason.startDate);
			const returnValue = dayJS.isSame(seasonStartJS, 'date') && dayJS.isSame(seasonStartJS, 'month') && dayJS.isSame(seasonStartJS, 'year');
			return returnValue
		}
		return false;
	}

	const isSeasonEnd = () => {
		if (dayJS !== null && dayJS !== undefined && 
			selectedSeason !== null && selectedSeason !== undefined) {
			const seasonEndJS = dayjs(selectedSeason.endDate);
			const returnValue = dayJS.isSame(seasonEndJS, 'date') && dayJS.isSame(seasonEndJS, 'month') && dayJS.isSame(seasonEndJS, 'year');
			return returnValue
		}
		return false;
	}
	
	const handleEventClick = (event) => {
		setSelectedEvent(event);
	}

	const handleEditEvent = () => {
		if (onEditEvent !== null && onEditEvent !== undefined && selectedEvent !== null && selectedEvent !== undefined) {
			onEditEvent(selectedEvent);
		}
		setSelectedEvent(null);
	}

	// Set the Day Header style
	useEffect(()=>{
		const standardHeaderSX = {paddingLeft:'0.5em', paddingTop:'0.5em', paddingBottom:'0.5em'};
		if (dayJS !== null && dayJS !== undefined && 
			selectedSeason !== null && selectedSeason !== undefined) {
			if (isSeasonStart() || isSeasonEnd() ) {
				setHeaderSX({...standardHeaderSX, ...{backgroundColor:theme.palette.background.lightLabel}});
			}
			else {
				setHeaderSX(standardHeaderSX);
			}
		}
	}, [dayJS, currentMonth, selectedSeason])

	return (
		<React.Fragment>
			<Dialog open={selectedEvent !== null} fullWidth={true} maxWidth='xl'>
				<DialogTitle>
					{selectedEvent !== null && selectedEvent !== undefined && selectedEvent.name}
				</DialogTitle>
				<DialogContent>
					{selectedEvent !== null && selectedEvent !== undefined &&
						<EventDisplay event={selectedEvent} displayHeader={false}/>
					}
				</DialogContent>
				<DialogActions>
					<ButtonGroupBar>
						<ButtonGroupButton onClick={handleEditEvent}toolTip={"Edit this event"}>
							<EditRoundedIcon/>
						</ButtonGroupButton>
						<ButtonGroupButton onClick={()=>setSelectedEvent(null)} toolTip={'Close'}>
							<CloseRoundedIcon/>
						</ButtonGroupButton>
					</ButtonGroupBar>
				</DialogActions>
			</Dialog>
			<Box style={daySX}>
					<Grid container direction='column' justifyContent='flex-start' alignItems='stretch' spacing={0} sx={{height:'100%'}}>
						{/* Day Header */}
						<Grid item container direction='row' justifyContent='flex-start' 
								sx={headerSX}>
							{/* Day Number */}
							<Grid item xs={0.5}>
								{dayJS.month() == currentMonth ? 
									<Typography variant='h2'>{dayJS.format('D')}</Typography>
									:
									<Typography variant='h2' color={theme.palette.text.secondary}>{dayJS.format('D')}</Typography>
								}
							</Grid>
							{/* Season Start */}
							{isSeasonStart() &&
								<Grid item xs={11.5} container justifyContent='center' alignItems='center'>
									<LabelImportantRoundedIcon fontSize='small'/>
									<Typography variant='3' >Season Start</Typography>
								</Grid>
							}
							{isSeasonEnd() &&
								<Grid item xs={11.5} container justifyContent='center' alignItems='center'>
									<CheckCircleRoundedIcon fontSize='small'/>
									<Typography variant='3' >Season End</Typography>
								</Grid>
							}
							{/* Indications */}
							<Grid item xs={11.5}>
								{/* Other things in header here */}
							</Grid>
						</Grid>
						{/* Day Body */}
						<Grid item flexGrow={5} container direction='column'>
							{dayEvents !== null && dayEvents !== undefined && dayEvents.map((event)=> {
								const displayEvent = SeasonClient.displayEventForEvent(event, selectedSeason, theme);
								
								return (
									<Grid item key={event.id} sx={{width:'100%'}} display='flex'>
										{/* Event Display */}
										<ArtiztaBorderBox sx={{
													borderRadius:2,
													border:1, 
													borderColor: displayEvent.textColor,
													backgroundColor: displayEvent.backgroundColor, 
													marginBottom:'0.1em',
													marginTop:'0.1em',
													paddingTop:'0.1em',
													paddingBottom:'0.1em',
													m:'0.25em',
													width:'100%'}}>
											<Box sx={{width:'100%', height:'100%', '&:hover': {cursor:'pointer'}}} onClick={()=>handleEventClick(event)}>
												<Typography variant='body1' color={displayEvent.textColor}>{event.name}</Typography>
											</Box>
										</ArtiztaBorderBox>
									</Grid>
								)
							})}
							{/* Clickable Area */}
							<Grid item flex={1}>
								<ArtiztaClickableBox 
										tooltip={`Add Event`} 
										message={`Add an Event on ${dayJS.format('M/D')}`} 
										onClick={()=>onAddEvent(dayJS)}/>
							</Grid>
						</Grid>
					</Grid>
			</Box>
		</React.Fragment>
	)
}

