// Material
import {createTheme} from '@mui/material/styles';
import 'typeface-asap';
import 'typeface-cabin';
import 'typeface-montserrat';
import 'typeface-cutive-mono';
import 'typeface-roboto-mono';
import 'typeface-prompt';

//Project
import { 	primaryColors, 
					nightColor, 
					stageColor, 
					shadowColor, 
					curtainColor, 
					spotlightColor,
					beachColor, 
					monoColor } from './SymphonEaseColors.js'

export const summerProgressionsTheme = createTheme({
  palette: {
    common: {
      blue: nightColor[60],
      brown: curtainColor[10],
      black: primaryColors.shadow,
      white: monoColor['white'],
			orange: primaryColors.spotlight 
    },
    primary: {
      main: nightColor[30],
      contrastText: beachColor[90]
    },
    secondary: {
      main: beachColor[20],
      contrastText: monoColor['white']
    },
    text: {
      primary: shadowColor[10],
      secondary: beachColor[20],
      disabled: curtainColor[90],
			darkLabel: stageColor[90],
			lightLabel: curtainColor[10],
			defaultSeries: monoColor[20],
			tab: monoColor['white'],
			tabSelected: curtainColor[80],
			notificationLabel: spotlightColor[10],
    },
    background: {
      paper: stageColor[90],
      default: stageColor[80],
			lightLabel: beachColor[70],
			darkLabel: curtainColor[20],
			defaultSeries: monoColor[80],
			notificationLabel: shadowColor[90],
			calendarWeekend: stageColor[70]
		}
  },
  typography: {
    fontFamily: [
      'Montserrat',
    ].join(','),
    button: {
      textTransform: "none",
      fontWeight: 700,
      fontSize: "1em",
			fontFamily: 'Montserrat'
    },
		h1: {
			fontFamily: 'Montserrat',
			fontSize: '1.5em',
			fontWeight: 600
		},
		h2: {
			fontFamily: 'Montserrat',
			fontSize: '1.25em',
			fontWeight: 500
		},
		h3: {
			fontFamily: 'Montserrat',
			fontSize: '1em',
			fontWeight: 500
		},
		h4: {
			fontFamily: 'Montserrat',
			fontSize: '.9em',
			fontWeight: 400
		},
		body1: {
			fontFamily: 'Prompt',
			fontSize: '.9em',
			fontWeight: 400
		},
  },
	breakpoints: {
		values: {
			xs: 0,
      sm: 600,
      md: 1000,
      lg: 1500,
      xl: 1800,
		}
	}
});

