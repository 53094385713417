import { useState } from 'react';

// material
import { ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import CropLandscapeRoundedIcon from '@mui/icons-material/CropLandscapeRounded';
import CropPortraitRoundedIcon from '@mui/icons-material/CropPortraitRounded';



export default function ArtiztaLandscapePortraitToggle({mode, setMode, variant='contained', size='small'}) {

	const handleChange = (event, newMode) => {
		setMode(newMode);
	}

	const control = {
    value: mode,
    onChange: handleChange,
    exclusive: true,
  };

	return (
		<ToggleButtonGroup size={size} variant={variant} {...control} color='primary' aria-label="Small sizes" sx={{borderRadius:10}}>
			<Tooltip title="Set Display Mode to Landscape">
				<ToggleButton size={size} variant={variant} value="landscape"  key='landscape'>
					<CropLandscapeRoundedIcon/>
				</ToggleButton>
			</Tooltip>
			<Tooltip title="Set Display Mode to Portrait">
				<ToggleButton size={size} variant={variant} value="portrait"  key='portrait'>
					<CropPortraitRoundedIcon/>
				</ToggleButton>
			</Tooltip>
		</ToggleButtonGroup>
	)
}