import dayjs from "dayjs";

// project imports
import EventsClient from "../events/EventsClient";

export default class SeasonClient {
	/**
	 * Returns an array of "SeasonWeeks". Each entry is a dictionary with the startDateJS is the beginning date and endDateJS is that last day of the week
	 * the startDateJS and endDateJS are dayjs types
	 * For example, if the season starts on Friday, September 1, the first week will be Friday, September 1 - Thursday September 7
	 * If weekStartDay is specified, and if that week day is *different* than the startDate, the first row will be a full week starting the weekDay *before* the start date
	 * Example: Let's say the season.startDate is September 1 and that's a Wednesday, and weekStartDay is specified as 5, which is Friday. 
	 *     The first row returned would have the startDate of Friday, August 28
	 * @param {{}} season the season object. startDate and endDate are javascript Dates
	 * @param {integer} weekStartDay the start day of the week, 0 (Sunday) to 6 (Saturday), if left blank, takes the start day from the season
	 */


	static calculateSeasonWeeks = (season, weekStartDay=null) => {
		const seasonRows = [];

		var startDateJS = dayjs(season.startDate);
		// determine the first Friday 
		// dayjs Days are 0 (Sunday) to 6 (Saturday)
	  const seasonStartDay = startDateJS.day()
		
		const startWeekDay=weekStartDay === null ? seasonStartDay : weekStartDay-1;
		if (startWeekDay<0) { weekStartDay = 6; }
		
		// set the start date
		let seasonRow = {
			startDateJS: startDateJS,
			events:[]};
		// end date for the first season is the day before the next weekStartDay
		var dayOffset = startWeekDay-dayjs(seasonRow.startDateJS).day();
		if (dayOffset<1) {dayOffset = dayOffset+7};
		seasonRow.endDateJS=seasonRow.startDateJS.add(dayOffset, 'day');
		seasonRows.push(seasonRow);

		// now it's just 'add the rows'
		while (seasonRow.endDateJS.isBefore(dayjs(season.endDate))) {
			const lastEndDateJS = seasonRow.endDateJS;
			// calculate next seasonRow
			seasonRow = {
				startDateJS:lastEndDateJS.add(1,'day'),
				endDateJS:lastEndDateJS.add(7,'day'),
				events:[]};
			seasonRows.push(seasonRow);
		}

		return seasonRows;
	}

	static eventsForDay = (events, day) => {
		const returnValue = [];
		const dayJS = dayjs(day);
		const startDayJS = dayJS.startOf('day');
		const endDayJS = dayJS.endOf('day');

		events.map((event)=> {
			if (EventsClient.isEventInRange(event, startDayJS.toDate(), endDayJS.toDate())) {
				returnValue.push(event);
			}
		})
		return returnValue;
	}

	static eventsForDaySeriesSeason = (events, day, series, season) => {
		// just see if we get the events first
		const dayEvents = SeasonClient.eventsForDay(events, day);
		if (dayEvents !== null && dayEvents !== undefined) {
			let seriesEvents = null;
			if (series === null || series === undefined) {
				seriesEvents = dayEvents.filter((event)=>((event.series===null || event.series===undefined) && event.season===season.name));
			}
			else{
				if (series.name==='All') {
					return dayEvents;
				}
				seriesEvents = dayEvents.filter((event)=>(event.series===series.name && event.season===season.name));
			}
			return seriesEvents;
		}
		return dayEvents;
	}

	/**
	 * Given the events and the seasonWeeks from calculateSeasonWeeks, places the events in the events array for each week
	 * @param {[]} seasonWeeks array of seasonWeeks
	 * @param {[]} events array of Event Objects
	 */
	static placeEventsInSeasonWeeks = (seasonWeeks, events) => {
		if (events !== null && events !== undefined) {
			events.map((event)=> {
				SeasonClient.placeEventInSeasonWeeks(seasonWeeks, event);
			})
		}
	}

	static placeEventInSeasonWeeks = (seasonWeeks, event) => {
		if (event !== null && event !== undefined) {
			var placed=false;
			seasonWeeks.map((week)=> {
				const weekEvents = week.events;
				const theEvent = weekEvents.find((e)=>(e.id===event.id));
				if (theEvent !== null && theEvent !== undefined) {
					// if the event is already in the week, remove it
					const index = weekEvents.findIndex((e)=>(e.id===event.id));
					weekEvents.splice(index, 1);
				}

				if (!placed && EventsClient.isEventInRange(event, week.startDateJS.toDate(), week.endDateJS.toDate())) {
					week.events.push(event);
					placed=true;
				}
			})
		}
	}

	static removeEventFromSeasonWeeks = (seasonWeeks, event) => {
		if (event !== null && event !== undefined) {
			seasonWeeks.map((week)=> {
				const index = week.events.findIndex((e)=>(e.name===event.name && e.performances[0].date===event.performances[0].date));
				if (index>=0) {
					week.events.splice(index, 1);
				}
			})
		}
	}

	static findSeriesForName = (seriesName, season) => {
		if (season.series === null || season.series === undefined) {
			return null;
		}
		
		var i=0;

		while ( i<season.series.length ) {
			if (season.series[i].name === seriesName) {
				return season.series[i];
			}
			i++;
		}
		return null;
	}

	static displayEventForEvent = (event, season, theme) => {

		var displayEvent={event:event};

		const defaultBGColor = (theme === null || theme === undefined) ? '#CCCCCC' : theme.palette.background.defaultSeries;
		const defaultTextColor = (theme === null || theme === undefined) ? '#222222' : theme.palette.text.defaultSeries;

		if (event.series === null || event.series === undefined ) {
			displayEvent.backgroundColor=defaultBGColor;
			displayEvent.textColor=defaultTextColor;
		}
		else if (season !== null && season !== undefined) {
			const series = SeasonClient.findSeriesForName(event.series, season);
			if (series === null || series === undefined) {
				console.log('Series not found for event', event, season);

				displayEvent.backgroundColor=defaultBGColor;
				displayEvent.textColor=defaultTextColor;
			}
			else {
				displayEvent.backgroundColor=series.color;
				displayEvent.textColor=series.contrastColor;
			}
		}
		return displayEvent;
	}
}