import React, { useContext, useEffect, useState } from 'react';

// Material
import { Grid, TextField, Tooltip, Typography, Box } from '@mui/material';

// project imports
import { ItemContext } from '../../common/ArtiztaEditComponent';
import ItemStatusSelect from '../../common/ItemStatusSelect';
import ArtiztaColorSelect from '../../common/ArtiztaColorSelect';

export default function EditSeriesComponent({ canEditName=true}) {
	const [editingItem, setEditingItem] = useContext(ItemContext);	
	const [statusDescription, setStatusDescription] = useState(null);

	const handleSelectColor = (color) => {
		const colorMerge = {
			colorName: color.name,
			color: color.color,
			contrastColor: color.contrastColor
		}
		setEditingItem({...editingItem, ...colorMerge});
	}

	return (
		<Grid container direction='column' id='es-top-grid' sx={{marginTop:'1em'}} >
			{/* Title */}
			<Grid 
					container direction='row' 
					id='es-title-Status' 
					justifyContent='space-between' 
					alignItems='center' 
					spacing={1}>
				<Grid item xs={4}>
					{canEditName ?
						<TextField
							id="es-name"
							name="es-name"
							label="Series Name"
							fullWidth
							defaultValue={editingItem.name}
							onChange={(e) => setEditingItem({...editingItem, ...{name: e.target.value}})}
						/>
					:
						<Typography variant='h6'>{editingItem.name}</Typography>
					}
					</Grid>
					{/* Status */}
					<Grid item xs={2} sx={{marginBottom:'0.5em'}}>
						<ItemStatusSelect 
							itemType='Series'
							item={editingItem}
							onChange={(newStatus)=> setEditingItem({...editingItem, ...{status: newStatus.name}})}
							setDescription={setStatusDescription}/>
					</Grid>
					<Grid item xs={6}>
						{statusDescription}
					</Grid>
			</Grid>
			{/* Strings Presets */}
			<Grid item container direction='row'>
					<Grid item xs={12}>
						<Tooltip 
								arrow
								title='This Strings Instrumentation will be defaulted to any new Event in this Series, but can be overridden.'
								enterDelay={500}>
							<TextField
								id="es-stringsInstrumentation"
								name="es-stringsInstrumentation"
								label="Strings Instrumentation Preset"
								fullWidth
								defaultValue={editingItem.stringsInstrumentation}
								onChange={(e) => setEditingItem({...editingItem, ...{stringsInstrumentation: e.target.value}})}
							/>
						</Tooltip>
					</Grid>
			</Grid>
			{/* Color */}
			<Grid item sx={{marginBottom:0, paddingBottom:0, minHeight:10}} id='ColorSelectContainer'>
				<Tooltip 
						enterDelay={500}
						arrow
						title='The Color will be used in displays to make each series distinctive.'>
					<Box>
						<ArtiztaColorSelect 
							defaultColorName={editingItem.colorName}
							onSelectColor={handleSelectColor}/>
					</Box>
				</Tooltip>
			</Grid>

			{/* Description */}
			<Grid item>
				<Tooltip 
					enterDelay={500}
					arrow
					title='This is a great place to set any direction or comments for the Series.'>
					<TextField
						sx={{marginTop:1}}
						id="es-desc"
						name="es-desc"
						label="Series Description"
						fullWidth
						multiline
						minRows={4}
						defaultValue={editingItem.desc}
						onChange={(e) => setEditingItem({...editingItem, ...{title: e.target.value}})}
					/>
				</Tooltip>
			</Grid>
		</Grid>
	)
}