// react imports
import React, {useState, useEffect, useContext} from 'react';

// material imports
import { Grid, Box, Typography, CircularProgress, Autocomplete, TextField, useTheme } from '@mui/material';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import SmartToyIcon from '@mui/icons-material/SmartToy';

// papaparse
import papa from 'papaparse';

// project imports
import ButtonGroupBar from './ButtonGroupBar';
import ButtonGroupButton from './ButtonGroupButton';
import ArtiztaBorderBox from './ArtiztaBorderBox.js';
import ArtiztaInputBox from './ArtiztaInputBox.js';
import GenerateImageButton from './GenerateImageButton.js';
import ImageClient from '../utils/ImageClient.js';
import { OrganizationContext } from '../utils/OrganizationContext.js';
import { AuthContext } from '../utils/AuthContext.js';
import ArtiztaSnackbar from './ArtiztaSnackbar.js'

/**
 * This component should be used for any type of file upload.
 * @param {string} title to be displayed on the titlebar in the component
 * @param {function} onSelect callback function, passes in the file that was selected and the data (bytes).
 * @param {function} onDelete callback function when the user hits the delete button, passes in the file that was deleted.
 * @param {string} accept the string representing file types that will be allowed, for example 'image/*' or '.csv'
 * @param {string} dropZoneMessage the message to be displayed in the drop zone for drag and drop
 * @param {string} width the width of the component
 * @param {boolean} isEditable whether the file can be edited
 * @param {string} imageData the image data to be displayed
 * @param {string} fileURL the URL of the file
 * @param {boolean} showGenerativeButton whether to show the generative AI button
 * @param {string} generativeObject when using generative AI, this is the object to be used for the generation. If null, the generative AI button will not be displayed
 * @param {string} generativeType when using generative AI, this is the type of generation 
 * @param {string} generativeStyle when using generative AI, this is the style of generation
 */
export default function FileSelector( {
			title, 
			onSelect, 
			onDelete, 
			accept, 
			dropZoneMessage, 
			children, 
			width='100%', 
			isEditable=true, 
			imageData, 
			fileURL=null,
			showGenerativeButton=false,
			generativeObject=null, 
			generativeType=null,
			generativeStyle=null,
			isLoading,
			setIsLoading
		}) {

	const theme = useTheme();

	const [fileData, setFileData] = useState(null);
	const fileInputRef = React.createRef();
	const [dropZoneBGColor, setDropZoneBGColor] = useState(theme.palette.background.lightLabel);
	// const [isLoading, setIsLoading] = useState(false);
	const [isDeleteDisabled, setIsDeleteDisabled] = useState(true);
	//const [filename, setFilename] = useState(null);
	const [file, setFile] = useState(null);
	const [currentOrganization] = useContext(OrganizationContext);
	const [currentUser] = useContext(AuthContext);
	const [generativeDisabled, setGenerativeDisabled] = useState(true);
	const [loadingMessage, setLoadingMessage] = useState('Loading Image...');
	const [imageStyle, setImageStyle] = useState(null)
	const [ openSnackbar, setOpenSnackbar] = useState(false)



	// const [url, setUrl] = useState(null)

	// Image Styles
	const styles = [
		'random style',
		'photorealistic', 
		'black and white', 
		'vintage photo', 
		'pop art', 
		'impressionist', 
		'oil painting', 
		'line drawing', 
		'watercolor', 
		'modern art', 
		'fantasy'];

	// TO Do: Add error handling
	const handleFileCapture = ({ target }) => {
		if (target !== undefined && target.files !== undefined && target.files.length >0) {
			setIsLoading(true);
			loadFirstFile(target.files);
		}
	};

	const loadFirstFile = (files) => {

		const theFile = files[0];

		if (theFile.type === 'text/csv') {
			papa.parse(theFile, { complete: (results) => {
				setFileData(results.data);
				setIsLoading(false);
				setFile(theFile);
				onSelect(theFile, results.data);				
			}})
		}
		else if (	theFile.type === 'image/jpeg' ||
							theFile.type === 'image/png') {
			const fileReader = new FileReader();

			fileReader.onloadend = (event) => {
				setFileData(event.target.result);
				setIsLoading(false);
				setFile(theFile);
				onSelect(theFile, event.target.result);
			}

			fileReader.onerror = (error) => {
				console.log('error reading file');
				console.log(error);
			}

			fileReader.readAsDataURL(theFile);
		}
	}

	const handleDelete = () => {
		// call onDelete and pass in the URL
		setFileData(null);
		// onSelect(null)
		onDelete(file !== null ? file.name : null);
	};

	const handleFilePicker = () => {
			fileInputRef.current.click();
	};

	const handleDragEnter = (event) => {

		setDropZoneBGColor(theme.palette.background.lightLabel);
	}

	const handleDragLeave = (event) => {		

		setDropZoneBGColor(theme.palette.background.lightLabel);
	}

	const handleDrop = (event) => {
		event.stopPropagation();
		event.preventDefault();
		const files = event.dataTransfer.files
		// Do something with the files
		if (fileData !== null) {
			setFileData(null);
		}
		loadFirstFile(files);
	}

	const handleDragOver = (event) => {
		event.stopPropagation();
		event.preventDefault();
		return false;
	}

	// Generate an image
	const generateImage = async () => {
		handleDelete();
		const origLoadingMessage = loadingMessage;
		setLoadingMessage('Generating Image...');
		setIsLoading(true);
		const imageJson = await ImageClient.generateImage(currentUser.person.settings.openAiAPIKey, currentOrganization.id, currentUser.person.id, generativeType, generativeObject, imageStyle);
		// If error message is in the image response, show that instead
		if('error' in imageJson) {
			console.log("Error:", imageJson)
			setOpenSnackbar(true)
			setIsLoading(false)
			setLoadingMessage(origLoadingMessage);
		}else{
			onSelect(imageJson.url);
			setIsLoading(false);
			setLoadingMessage(origLoadingMessage);
		}
	}

	useEffect(() => {
		if (fileData !== null ) {
			setIsDeleteDisabled(false);
		}
		else {
			setIsDeleteDisabled(true);
		}
	}, [fileData]);

	useEffect(() => {
		setGenerativeDisabled(generativeObject === null);
	}, [generativeObject]);
  
	return (
		<ArtiztaInputBox>
			<Typography sx={{paddingLeft:1, paddingBottom:1}}>{title}</Typography>
			{/* Dropzone */}
			<Box id='dropZone'
				onDragEnter={handleDragEnter}
				onDragLeave={handleDragLeave}
				onDrop={handleDrop}
				onDragOver={handleDragOver}
				position={'relative'}
				sx={{p:(fileData === null && (isEditable) && (imageData === null)) ? '1em' : 0,  
					backgroundColor:dropZoneBGColor}}
			>
				{/* If no image - display the drop zone */}
				{((fileData === null) && (isEditable) && (imageData === null) ) ?
				<Grid border={4} sx={{ borderStyle:'dashed', borderColor:theme.palette.text.lightLabel, p:'0.5em'}}>
					{isLoading ? 
						<Grid container direction='column' alignItems={'center'} alignContent={'center'} justifyContent='center'>
							<CircularProgress />
							<Typography>{loadingMessage}</Typography>
						</Grid>
						:
						<Typography align={'center'} fontWeight={'fontWeightBold'} sx={{color:theme.palette.text.lightLabel}}>{dropZoneMessage}</Typography>
					}
				</Grid>
				:
					<Box>
						{children}
					</Box>
				}
			</Box>

			{/* Style + Buttons */}
			{isEditable && 
			<Grid container justifyContent={(showGenerativeButton===false) ? 'flex-end' : 'space-between'} alignItems={'center'} sx={{marginTop:1}}>
				{/* Style */}
				{showGenerativeButton &&
				<Grid item xs={6}>
					<Autocomplete 
						options={styles}
						renderInput={(params) => <TextField {...params} label="AI Image Style" />}
						onChange={(event, newValue) => {setImageStyle(newValue)}}
					/>
				</Grid>
				}
				
				{/* Buttons */}
				<Grid item>
					<ButtonGroupBar>
						<ButtonGroupButton disabled={false} onClick={handleFilePicker} toolTip='Select File to load'>
							<input
								accept={accept}
								type={'file'}
								hidden
								onChange={handleFileCapture}
								ref={fileInputRef}
							/> 
							<FileOpenIcon/>                  
						</ButtonGroupButton>
						{(showGenerativeButton) &&
							<ButtonGroupButton 
								disabled={generativeDisabled || currentUser.person.settings.openAiAPIKey == undefined || isLoading} 
								onClick={generateImage} 
								toolTip={(currentUser.person.settings.openAiAPIKey == undefined) ? 'To use Recommendations, enter your OpenAI Key in Settings' : 'Generate an Image'}
							>
								<SmartToyIcon />
							</ButtonGroupButton>
						}
						<ButtonGroupButton  onClick={handleDelete} toolTip='Remove Currently Selected File'>
							<DeleteRoundedIcon/>
						</ButtonGroupButton>
					</ButtonGroupBar>
				</Grid>
			</Grid>
			}

			{/* Snackbar */}
			<ArtiztaSnackbar message={"Error with image generation"} open={openSnackbar} setOpen={setOpenSnackbar}/>
		</ArtiztaInputBox>
	)	
}