// Project
import SpotifyClient from "../pages/sandbox/spotify/SpotifyClient";
import SpotifyIconDark from "../assets/spotify-icon-dark.svg"
import SpotifyIconLight from "../assets/spotify-icon-light.svg"
import SpotifyIconGreen from "../assets/spotify-icon-green.png"

// Material
import { Button, Grid, SvgIcon, Typography } from "@mui/material";

// React
import { useEffect, useState } from "react";

export default function SpotifyButton({ searchString, short=false,  }) {
  // state
  // const [ showSelf, setShowSelf ] = useState(false) // decides wether or not to show the spotify search result
  const [ searchResult, setSearchResult ] = useState(null)

  // Search for Spotify Track
  const getTrack = () => {
    SpotifyClient.get(searchString)
      .then(json => {
        setSearchResult(json)
      })
  }

  // UI
  return (
    <Grid>
      {/* Button */}
      <Grid container alignItems={'center'}>
        <Button onClick={getTrack} sx={{borderRadius:4}}>
          <img src={SpotifyIconDark} width={20} height={20}/>  
          <Typography sx={{marginLeft: 1}}>Search Spotify {(short==false) && "for " + searchString}</Typography>
        </Button>
      </Grid>

      {/* Search Result */}
      {(searchResult) != null &&
        <iframe 
          src={"https://open.spotify.com/embed/track/" + searchResult.trackId + "?theme=0" }
          width="100%" 
					height='100px'
          frameBorder="0" 
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture" 
          loading="lazy"
        />
        }
        
    </Grid>
  )
}

