// material
import { Button, Tooltip } from '@mui/material';

/* ButtonGroupButton
	Intended to be used with a ButtonGroupBar
	usage:
		onClick - the function to be called when the button is clicked
		disabled - if true, will disable the button
		toolTip - the text to be displayed as a tooltip
	The children will be rendered but just use some icon.
*/
export default function ButtonGroupButton({children, onClick, disabled, toolTip, placement='bottom', type='button'}) {

	return (
		<Tooltip title={toolTip} placement={placement} arrow>
			<span>
				<Button sx={{borderRadius:10, p:1}} 
					color='primary' 
					onClick={onClick} 
					disabled={disabled}
					type={type}>			
					{children}
				</Button>
			</span>
		</Tooltip>
	)
}