import APIClientManager from "./APIClientManager";

export default class FeedbackClient {
  static postFeedback = async (location, feedbackType, attemptedAction, whatHappened, suggestion, name, email) => {
    var bundle = {
      'location': location,
      'feedbackType': feedbackType,
      'attemptedAction': attemptedAction,
      'whatHappened': whatHappened,
      'suggestion': suggestion,
      'name': name,
      'email': email
    }
    const response = await APIClientManager.post('feedback', bundle)
    const json = await response.json()
    return json
  }
}