// React imports
import React, { useState, useContext, useEffect } from 'react';
import dayjs from 'dayjs';

// Material Imports
import { Box, TextField, Grid, Typography, Divider, CircularProgress, ListItemButton, List, useTheme } from '@mui/material';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';

// project imports
import ButtonGroupBar from '../../common/ButtonGroupBar.js';
import ButtonGroupButton from '../../common/ButtonGroupButton.js';
import ArtiztaSelect from '../../common/ArtiztaSelect.js';
import { OrganizationContext } from '../../utils/OrganizationContext.js';
import FileSelector from '../../common/FileSelector.js';
import EditEventWorks from './eventWork/EditEventWorksComponent.js';
import EditEventPerformancesComponent from './eventPerformance/EditEventPerformancesComponent.js';
import EditEventFeaturedPerformers from './featuredPerformers/EditEventFeaturedPerformers.js';
import { FirebaseFileClient } from '../../utils/FirebaseFileClient.js';
import { ItemContext } from '../../common/ArtiztaEditComponent.js';
import OrganizationsClient from '../organizations/OrganizationsClient.js';
import ItemStatusSelect from '../../common/ItemStatusSelect.js';
import RecommendationClient from '../../utils/RecommendationsClient.js';
import { AuthContext } from '../../utils/AuthContext.js';
import CommentsDisplay from '../../common/CommentsDisplay.js';
import EventsClient from './EventsClient.js';
import ArtiztaSnackbar from '../../common/ArtiztaSnackbar.js';
import ArtiztaLandscapePortraitToggle from '../../common/ArtiztaLandscapePortraitToggle.js';

/**
 * Displays a component to edit an event. 
 * @param {{}} event existing event to be edited
 * @param {function} onSave callback function to receive the edited work - will need to close the work window when this is called
 * @param {function} onCancel callback function to determine if cancel was pressed
 */

export default function EditEventComponent({setEvents}) {
	const [editingItem, setEditingItem] = useContext(ItemContext);
	const [selectedSeason, setSelectedSeason] = useState(null);
	const [currentOrganization, setCurrentOrganization] = useContext(OrganizationContext);
	const [currentUser, setCurrentUser] = useContext(AuthContext)
	const [selectedSeries, setSelectedSeries] = useState(null);
	const [series, setSeries] = useState(null);
	const [imageData, setImageData] = useState(null);
	const [imageURL, setImageURL] = useState(null);
	const [generativeEvent, setGenerativeEvent] = useState(null);
	const [statusDescription, setStatusDescription] = useState(null);
	const [ selectedTab, setSelectedTab ] = useState(0)
	const [imageLoading, setImageLoading ] = useState(false)
	const [snackBarOpen, setSnackBarOpen] = useState(false);
	const [snackBarMessage, setSnackBarMessage] = useState(null);
	const [orientationMode, setOrientationMode] = useState('landscape');
	const [eventIsNew, setEventIsNew] = useState(false);

	// Description Generation
	const [savedText, setSavedText] = useState(null)
	const [ showDescriptionLike, setShowDescriptionLike ] = useState(false)
	const [ descriptionLoading, setDescriptionLoading ] = useState(false)
	

	// Symphy's Recommendations
	const recommendDescription = () => {
		setDescriptionLoading(true)
		RecommendationClient.getDescriptionRecommendation(currentUser.person.settings.openAiAPIKey, editingItem)
			.then(json => {
				setShowDescriptionLike(true)
				if (editingItem.desc != undefined || editingItem.desc != null) {
					setSavedText(editingItem.desc)
				}
				setEditingItem({...editingItem, ...{desc:json}})
				quickSave({...editingItem, ...{desc:json}})
				document.getElementById('ee-desc').value = json
				setDescriptionLoading(false)
			})
	}
	const loveIt = () => {
		setShowDescriptionLike(false)
	}
	const hateIt = () => {
		if(savedText != null){
			setEditingItem({...editingItem, ...{desc:savedText}})
			document.getElementById('ee-desc').value = savedText
		}else{
			setEditingItem({...editingItem, ...{desc:""}})
			document.getElementById('ee-desc').value = ""
		}
		setShowDescriptionLike(false)
	}

	// Quick save for each edit.
	const quickSave = async(newEvent) => {
		// logic for performances
		if (newEvent.performances === null || newEvent.performances === undefined || newEvent.performances.length === 0) {
			const performanceDateJS = dayjs().add(1, 'month').hour(20).minute(0).second(0).millisecond(0)
			newEvent.performances = [{date:performanceDateJS.toDate()}]
		}
		// Update DB
		const theEvent = await EventsClient.save(currentOrganization, newEvent)
		setEditingItem({...editingItem, ...{id:theEvent.id}})
		const theEvents = await EventsClient.getAll(currentOrganization)
		// Update State
		//setEvents(theEvents)
		// snacks
		setSnackBarMessage(`Saved`);
		setSnackBarOpen(true);
	}

	// Selects the status
	const handleStatusSelect = (status) => {
		setEditingItem({...editingItem, ...{status:status.name}})
	}

	// Selects the season
	const handleSeasonSelect = (season) => {

		setSelectedSeason(season);

		if (season !== null && season !== undefined) {
			setSeries(season.series);
			setEditingItem({...editingItem, ...{season:season.name}}) 
		}
		else {
			setSeries(null);
			setEditingItem({...editingItem, ...{season:null}});
		}
	}

	// Selects the series
	const handleSeriesSelect = (series) => {
		setSelectedSeries(series);
		if(series !== null && series !== undefined) {
			setEditingItem({...editingItem, ...{series:series.name}})
		}else{
			setEditingItem({...editingItem, ...{series:null}})
		}
			
	}

	// Selects the image
	const handleImageSelect = (theFile, theImageData=null) => {
		// need to determine the type of the imageData, either string (URL) or bytes

		if (theImageData != null) {
			setImageData(theImageData);
			FirebaseFileClient.upload(theFile, 'images', (url) => {
				setImageURL(url);
				setEditingItem({...editingItem, ...{imageURL:url}});
			});
		} else{
			setImageData(theFile);
			setImageURL(theFile);
			setEditingItem({...editingItem, ...{imageURL:theFile}});
		}
	}

	const handleDeleteImage = (url) => {
		setImageData(null);
		setImageURL(null);
		delete editingItem.imageURL;
	}

	const generativeButtonEnabled = (e) => {
		const name = document.getElementById('ee-name').value;
		if ((name!==null && name!==undefined && name.length>0) || 
			(editingItem.works!==null && editingItem.works!==undefined && editingItem.works.length>0)) {
			setGenerativeEvent(editingItem);
		}
		else {
			setGenerativeEvent(null);
		}
	}

	// calls once to get stuff
	useEffect(() => {
		if (editingItem!==null && editingItem!==undefined ) {
			const aSeason = OrganizationsClient.getSeasonForEvent(currentOrganization, editingItem);
			if (aSeason !== null) {
				setSelectedSeason(aSeason);
				setSeries(aSeason.series);
			}

			const aSeries = OrganizationsClient.getSeriesForEvent(currentOrganization, editingItem);
			if (aSeries !== null) {	
				setSelectedSeries(aSeries);
			}
			if (editingItem.imageURL !== null && editingItem.imageURL !== undefined) {
				setImageData(editingItem.imageURL);
			}
			generativeButtonEnabled();

		}else{
			// Make a new event & save it
			quickSave(editingItem)
		}

	},[]);

	// Does a quicksave on change of certain things
	useEffect(() =>{
		if (editingItem.id != undefined){
			quickSave(editingItem)
		}
	},[
		editingItem.works, 
		editingItem.season, 
		editingItem.series, 
		editingItem.status,
		editingItem.works,
		editingItem.imageURL,
		editingItem.featuredPerformers,
		editingItem.performances
	]);

	// Styles
	const listButtonStyle = {
		borderRadius: 2
	}
	const tVarient = 'body1'

	const theme = useTheme();

	return (
		<Box sx={{marginTop:'0.5em'}}>
			<ArtiztaSnackbar open={snackBarOpen} message={snackBarMessage} setOpen={setSnackBarOpen} />
			{/* Header (Event Name, Season, Series, Event Access Level) */}
			<Grid container direction={'column'} sx={{marginTop:2}} spacing={1}>
				{/* Event Name, Event Access Level */}
				<Grid item container direction={'row'} justifyContent={'flex-start'} spacing={1} >
					{/* Event Name */}
					<Grid item xs={6}>
						<TextField
							id="ee-name"
							name="ee-name"
							label="Event Name"
							fullWidth
							defaultValue={editingItem===null || editingItem===undefined ? '' : editingItem.name}
							onChange={(e)=>{
								setEditingItem({...editingItem, ...{name:e.target.value}}); 
								generativeButtonEnabled()}}
							onBlur={()=>{ quickSave(editingItem)}}
						/>
					</Grid>
					{/* Access Level */}
					<Grid item xs={6} container spacing={1}>
						<Grid item xs={4}>
						<ItemStatusSelect 
							onChange={handleStatusSelect} 
							itemType='Event' 
							item={editingItem}
							setDescription={setStatusDescription}
						/>
						</Grid>
						<Grid item xs>
						<Typography>{statusDescription}</Typography>
						</Grid>
					</Grid>
				</Grid>
				{/* Season, Series selectors */}
				<Grid item container direction='row' justifyContent='space-between'>
					<Grid item container direction={'row'} spacing={1} xs={6}>
						{/* Season */}
						<Grid item xs={6}>
							<ArtiztaSelect
								itemList={currentOrganization.seasons}
								id='ee-season-select'
								label='Season'
								fullWidth
								value={selectedSeason}
								onChange={handleSeasonSelect}
							/>
						</Grid>
						{/* Series */}
						<Grid item xs={6}>
							<ArtiztaSelect
								itemList={series}
								id='ee-series-select'
								label='Series'
								fullWidth
								value={selectedSeries}
								onChange={handleSeriesSelect}
							/>
						</Grid>
					</Grid>
					<Grid item>
						<ArtiztaLandscapePortraitToggle mode={orientationMode} setMode={setOrientationMode}/>
					</Grid>
				</Grid>
			</Grid>
			<Divider sx={{marginTop: 2, marginBottom:2}} />
			{/* List of options & the View */}
			<Grid container spacing={2}>
				{/* List of Options */}
				{orientationMode === 'landscape' &&
				<Grid item xs={2} sx={{borderRight:1, borderColor:theme.palette.divider}}>
					<List>
						<ListItemButton sx={listButtonStyle} selected={(selectedTab===0)} onClick={()=>setSelectedTab(0)}>
							<Typography variant={tVarient}>Works</Typography>
						</ListItemButton>
						<ListItemButton sx={listButtonStyle} selected={(selectedTab===1)} onClick={()=>setSelectedTab(1)}>
							<Typography variant={tVarient}>Event Info</Typography>
						</ListItemButton>
						<ListItemButton sx={listButtonStyle} selected={(selectedTab===2)} onClick={()=>setSelectedTab(2)}>
							<Typography variant={tVarient}>Featured Performers</Typography>
						</ListItemButton>
						<ListItemButton sx={listButtonStyle} selected={(selectedTab===3)} onClick={()=>setSelectedTab(3)}>
							<Typography variant={tVarient}>Performances</Typography>
						</ListItemButton>
						{
							(editingItem.id !== null && editingItem.id !== undefined) &&
							<ListItemButton sx={listButtonStyle} selected={(selectedTab===4)} onClick={()=>setSelectedTab(4)}>
								<Typography variant={tVarient}>Comments</Typography>
							</ListItemButton>
						}
					</List>
				</Grid>
				}

				{/* The View */}
				<Grid item xs={orientationMode === 'landscape' ? 10 : 12}>
					{/* Works */}
					{
						(orientationMode ==='portrait' || selectedTab===0) &&
						<Grid item>
							<Typography variant='h2'>Works</Typography>
							<EditEventWorks selectedSeries={selectedSeries} />
							{orientationMode === 'portrait' && <Divider sx={{marginTop: 2, marginBottom:2}} />}
						</Grid>
					}

					{/* Event Info */}
					{
						(orientationMode ==='portrait' || selectedTab===1) &&
						<Box>
						<Grid container alignItems={'flex-start'} spacing={1}>
							{/* Image */}
							<Grid item xs={6}>
								<FileSelector 
									title='Event Image'
									accept='image/jpeg, image/png, image/gif'
									onSelect={handleImageSelect}
									onDelete={handleDeleteImage}
									imageData={imageData}
									dropZoneMessage='Drag an image to upload'
									showGenerativeButton={true}
									generativeObject={generativeEvent}
									generativeType='event'
									isLoading={imageLoading}
									setIsLoading={setImageLoading}
								>
									<img src={imageData} width='100%' alt='Event Image'/>
								</FileSelector>
							</Grid>

							{/* Description & AI */}
							<Grid item xs={6} container direction={"column-reverse"} spacing={2} justifyContent='flex-start'>
								{/* AI */}
								<Grid item container alignItems={'flex-start'} justifyContent={'flex-end'}>
									{(descriptionLoading)&& <CircularProgress />}
									<ButtonGroupBar>
										<ButtonGroupButton toolTip={(currentUser.person.settings.openAiAPIKey == undefined) ? 'To use Recommendations, enter your OpenAI Key in Settings' : 'Generate a description for this Event'} disabled={descriptionLoading} sx={{m:1, p:1}} onClick={recommendDescription}>
											<SmartToyIcon />
										</ButtonGroupButton>
										<ButtonGroupButton disabled={!showDescriptionLike} toolTip="Love it!" onClick={loveIt}>
											<ThumbUpIcon />
										</ButtonGroupButton>
										<ButtonGroupButton disabled={!showDescriptionLike} toolTip="Hate it..." onClick={hateIt}>
											<ThumbDownIcon />
										</ButtonGroupButton>
									</ButtonGroupBar>
								</Grid>

								{/* Description */}
								<Grid item >
									<TextField
										sx={{height: '100%', display:'flex', flexGrow:1}}
										InputProps={{
											sx: {
												height: '100%',
												alignItems: 'start'
											}}}
										id="ee-desc"
										name="ee-desc"
										label="Description"
										rows={10}
										fullWidth
										multiline
										defaultValue={editingItem===null || editingItem===undefined ? '' : editingItem.desc}
										onChange={(e)=> setEditingItem({...editingItem, ...{desc:e.target.value}})}
										onBlur={()=>{quickSave(editingItem)}}
										InputLabelProps={{ shrink: true}}
									/>
								</Grid>
							</Grid>
						</Grid>
						{orientationMode === 'portrait' && <Divider sx={{marginTop: 2, marginBottom:2}} />}
						</Box>
					}

					{/* Performance Info (Featured Performers, Performances*/}
					{
						(orientationMode ==='portrait' || selectedTab===2) &&
						<Grid item>
							<Typography variant='h2'>Featured Performers</Typography>
							<EditEventFeaturedPerformers event={editingItem}/>
							{orientationMode === 'portrait' && <Divider sx={{marginTop: 2, marginBottom:2}} />}
						</Grid>
					}

					{/* Performances */}
					{
						(orientationMode ==='portrait' || selectedTab==3) &&
						<Grid item>
							<Typography variant='h2'>Performances</Typography>
							<EditEventPerformancesComponent />
						</Grid>
					}

					{/* Comments */}
					{
						((orientationMode ==='portrait' || selectedTab==4) && editingItem.id !== null && editingItem.id !== undefined) &&
						<CommentsDisplay item={editingItem} type='event'/>
					}
				</Grid>
			</Grid>


		</Box>
	)
}
