// React imports
import React, { useRef, useContext, useCallback, useState } from 'react';
import { useDrag, useDrop } from "react-dnd"

// material
import { Typography, Grid } from '@mui/material';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';

// Project
import { ItemTypes } from '../../sandbox/DragNDropDemo/ItemTypes';
import ButtonGroupBar from '../../../common/ButtonGroupBar';
import ButtonGroupButton from '../../../common/ButtonGroupButton';

export default function DisplayEventFeaturedPerformer ({eventFeaturedPerformer, onEdit, onDelete}) {
	
	return (
		<Grid item container direction='row' key={eventFeaturedPerformer.name}>				
			<Grid item xs={5}>
				<Typography>{eventFeaturedPerformer.name}</Typography>
			</Grid>
			<Grid item xs={6}>
				<Typography>{eventFeaturedPerformer.performanceSkill}</Typography>
			</Grid>
			<Grid item xs={1}>
					<ButtonGroupBar variant='outline' size='small'>
						<ButtonGroupButton toolTip={`Edit '${eventFeaturedPerformer.name}'`} placement='left' onClick={onEdit}>
							<EditRoundedIcon/>
						</ButtonGroupButton>
						<ButtonGroupButton toolTip={`Remove ${eventFeaturedPerformer.name} from this Event`} placement='left-end' onClick={onDelete}>
							<DeleteForeverRoundedIcon/>
						</ButtonGroupButton>
					</ButtonGroupBar>			
				</Grid>
		</Grid>
	)
}