// React imports
import React from 'react';

// Material imports
import { Grid, Box, Typography } from '@mui/material';
//import Grid from '@mui/material/Unstable_Grid2';
import EditRoundedIcon from '@mui/icons-material/EditRounded';

// project imports
import ArtiztaIconButton from '../../../common/ArtiztaIconButton.js';
import DanielsOnlineDisplay from './WorkDanielsOnlineDisplay.js';
import ArtiztaBorderBox from '../../../common/ArtiztaBorderBox.js';

export default function WorkDisplay({ work, onEdit, isEditable=true }) {
  //const [currentWork, setCurrentWork] = useContext(WorkContext);

	return (
		<ArtiztaBorderBox>
			<Grid container direction='row' justifyContent='space-between'>
				<Box >
					<Typography fontSize={24}>{work.title}</Typography>
					<Typography fontSize={18}>{work.composer}, {work.duration} minutes</Typography>
				</Box>
				{isEditable &&
					<Grid item>
						<ArtiztaIconButton toolTip='Edit this Work' onClick={()=>{onEdit(work)}}>
							<EditRoundedIcon/>
						</ArtiztaIconButton>
					</Grid>
				}
			</Grid>
			<Grid container direction='column' sx={{marginTop:1, marginBottom:1}}>
				<Grid container item direction='row' >
					<Grid item xs={3}>
						<b>Instrumentation </b>
					</Grid >
					<Grid item xs={9}>
						<Typography fontFamily='Roboto Mono'>{work.instrumentation}</Typography>
					</Grid> 
				</Grid>
				<Grid container item direction='row' >
					<Grid item xs={3}>
						<b>Strings Instrumentation </b>
					</Grid >
					<Grid item xs={9}>
						<Typography fontFamily='Roboto Mono'>{work.stringsInstrumentation}</Typography>
					</Grid> 
				</Grid>
				<Grid container item direction='row' >
					<Grid item xs={3}>
						<b>Percussion </b>
					</Grid >
					<Grid item xs={9}>
					<Typography fontFamily='Roboto Mono'>{work.percussionInfo}</Typography>
					</Grid> 
				</Grid>
				<Grid container item direction='row' >
					<Grid item xs={3}>
						<b>Description</b>
					</Grid >
					<Grid item xs={9}>
						{work.desc}
					</Grid> 
				</Grid>
			</Grid>
			<DanielsOnlineDisplay/>
		</ArtiztaBorderBox>
	)
}