// material
import { ButtonGroup } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

/* ButtonGroupBar
	Use this to display a button group on the far right.
	Intended to be the only item on a row
	Fill the children with ButtonGroupButtons 
*/
export default function ButtonGroupBar({children, variant='contained', size='medium'}) {

	return (
		<Grid container direction='row' justifyContent='flex-end' sx={{p:'.25em'}} >
			<Grid>
				<ButtonGroup variant={variant} size={size} sx={{borderRadius:10, marginLeft:'auto'}}>
					{children}
				</ButtonGroup>
			</Grid>
		</Grid>
	)
}