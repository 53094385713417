import APIClientManager from "../../../utils/APIClientManager";

export default class SpotifyClient {
  static get = async (q) => {
    var bundle = {
      'q': q
    }
    const response = await APIClientManager.post('spotify', bundle)
    const json = await response.json()
    return json
  }
}