// project imports
import APIClientManager from '../../utils/APIClientManager';

/**
 * 	NotificationsClient encapsulates the API calls to Notifications. All operations require a valid person
 * 		getAll - returns all Notifications for a person
 *    update - updates an existing notification
 */

const notificationsURL = (person) => {
	return `notifications/${person.id}`
}

export default class NotificationsClient {
	static baseURL = 'http://'+process.env.REACT_APP_API_URL+'/api/';

	static getAll = async (person) => {
		const response = await APIClientManager.get(notificationsURL(person));
		const json = await response.json();
		return json;
	}

	static update = async (person, notification) => {
		const response = await APIClientManager.put(notificationsURL(person), notification);
		const json = await response.json();
		return json;
	}
}