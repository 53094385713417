// React imports
import {React, useContext, useState} from 'react';

// Material Imports
import { Grid } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';


// project imports
import ContentBackground from '../../common/ContentBackground';
import { OrganizationContext } from '../../utils/OrganizationContext';
import OrganizationChange from '../organizations/components/OrganizationChange';
import PeopleListOrg from './components/PeopleListOrg';
import ArtiztaCard from '../../common/ArtiztaCard';
import ArtiztaPageTitle from '../../common/ArtiztaPageTitle';
import ArtiztaIconButton from '../../common/ArtiztaIconButton';
import PeopleNew from './components/PeopleNew';
import RoleWrapper from '../../utils/RoleWrapper';
import { AuthContext } from '../../utils/AuthContext';
import ArtiztaSnackbar from "../../common/ArtiztaSnackbar.js";

export default function PeoplePagev2() {
  // State
	const [newPerson, setNewPerson] = useState(null);
	const [ snackbarOpen, setSnackbarOpen ] = useState(false);
	const [ snackbarMessage, setSnackbarMessage ] = useState(null);	
  // Add new person
  const [open, setOpen] = useState(false)
  const handleClickOpen = () => {
    setOpen(true);
  }
  // context
  const [currentOrganization] = useContext(OrganizationContext) 
  const [currentUser] = useContext(AuthContext)

	// Callback for when a person is saved
	const personSaved = (person) => {
		setSnackbarMessage(`${person.firstName} ${person.lastName} has been added to the ${currentOrganization.name}.`);
		setSnackbarOpen(true);
	}

	const personDeleted = (person) => {
		setSnackbarMessage(`${person.firstName} ${person.lastName} has been removed from the ${currentOrganization.name}.`);
		setSnackbarOpen(true);
	}

  return (
    <ContentBackground>
			<ArtiztaSnackbar
				open={snackbarOpen}
				setOpen={setSnackbarOpen}
				message={snackbarMessage}/>
      {(currentUser!=null)&&
			<ArtiztaCard>
        {/* Title & New Person */}
        <Grid container justifyContent='space-between' alignItems='center' direction='row'>
          <ArtiztaPageTitle>People</ArtiztaPageTitle>

          {/* Button */}
          <ArtiztaIconButton
            toolTip='Add a new person to your Organization' 
            disabled={currentOrganization===null} 
            onClick={handleClickOpen}
          >
            <AddRoundedIcon/>
          </ArtiztaIconButton>

          {/*Dialog */}
          {(currentOrganization != null)&&
            <PeopleNew open={open} setOpen={setOpen} person={newPerson} setPerson={setNewPerson} onSave={personSaved}/>
          }
        </Grid>

        {/* People List */}
				{(currentOrganization === null || currentOrganization === undefined) ?
					<RoleWrapper roles={["Global Admin"]}>
            <OrganizationChange/>
          </RoleWrapper>
				:
					<PeopleListOrg onDelete={personDeleted}/>
				}
			</ArtiztaCard>
      }
    </ContentBackground>
  )
}