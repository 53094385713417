
// project imports
import { FirebaseFileClient } from "../../utils/FirebaseFileClient";

const addJSONExtension = (filename) => {
	const parts = filename.split('.');
	const lastPart = parts[parts.length-1];
	if (lastPart.toLowerCase() === 'json') {
		return filename;
	}
	else {
		return filename+'.json'
	}
}


export default class SettingsClientV2 {
	/**
	 * Use this for the Performance Skills settings
	 */
	static performanceSkills='performanceSkills_V2';

	static getPerformanceSkills = () => {
		const returnValue = SettingsClientV2.getForName(SettingsClientV2.performanceSkills);

		return returnValue;
	}

	/**
	 * Returns a settings json for a name from Firebase storage. use SettingsClient.roles for a list of roles and SettingsClient.performanceSkills for a list of performance skills
	 * This will return null if the setting does not exist. Need to await this function as it returns a promise.
	 * @param {string} name the settings name
	 * @returns {{}} a dictionary of the settings
	 */
	static getForName = async (name) => {
		const filename = addJSONExtension(name);
		try {
			const json = await FirebaseFileClient.getJSONForFilename('settings', filename);
			
			return json;
		}
		catch (error) {
			console.log(error);
			return null;
		}
	}
}