// React imports
import React, {useContext, useState, useEffect} from 'react';
import { Link } from 'react-router-dom';

// material
import { Box, Typography} from '@mui/material';


export default function ArtiztaPageTitle({ children }) {

	return (
			<Box sx={{marginBottom:'1em'}}>
				<Typography variant='h1'>
					{children}
				</Typography>
			</Box>
	)
}