import dayjs from 'dayjs';
import React, { useState, useEffect } from 'react';
//material
import { useTheme, Grid, Typography } from '@mui/material';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
// project
import ArtiztaBorderBox from '../../common/ArtiztaBorderBox';
import WeeklyEvents from './WeeklyEvents';
import SeasonClient from './SeasonClient';

/**
 * Displays a week in a season
 * @param {{}} week is the week of the season, created from SeasonClient.calculateSeasonWeeks
 * @param {[]} selectedSeries the series objects to display. If this is an empty array then all series are displayed
 */
export default function SeasonWeek({ week, season, selectedSeries, onSaveEvent, onDeleteEvent, onEditDetails }) {	
	const [displayEvents, setDisplayEvents] = useState([]);
	const [displayMonthHeader, setDisplayMonthHeader] = useState(false);

	const theme=useTheme();

	const shouldDisplayEvent = (event) => {
		if (selectedSeries === null || selectedSeries === undefined ) {
			if (event.series === null || event.series === undefined) {
				return true;
			}
		}
		else {
			if (selectedSeries.name === 'All' || event.series === selectedSeries.name) {
				return true;
			}
		}
		return false;
	}

	const calculateDisplayEvents = (week) => {
		// calculate displayEvents
		const theDisplayEvents = [];
		
		if ( week.events !== null && week.events !== undefined ) {
			const numEvents = week.events.length;

			for (var i=0; i<week.events.length; i++) {

				const theEvent = week.events[i];
				
				if (shouldDisplayEvent(theEvent)) {
					const displayEvent = SeasonClient.displayEventForEvent(theEvent, season, theme);

					theDisplayEvents.push(displayEvent);
				}
			}
		}

		return theDisplayEvents;
	}

	const shouldDisplayMonthHeader = () => {	
		const seasonStartDateJS = dayjs(season.startDate);
		const weekStartDateJS = week.startDateJS;
		
		if ((seasonStartDateJS.date() !== weekStartDateJS.date() || 
				seasonStartDateJS.month() !== weekStartDateJS.month() ||
				seasonStartDateJS.year() !== weekStartDateJS.year()) 
				&& weekStartDateJS.diff(seasonStartDateJS, 'days') <= 6) {
			// if the week is within the first 7 days and it's not the first week
			return false;
		}
		if (weekStartDateJS.diff(seasonStartDateJS, 'days') === 0) {
			return true;
		}
		return (weekStartDateJS.date()<=7);
	}

	// set the displayed events
	useEffect(()=> {
		const theDisplayEvents = calculateDisplayEvents(week);
		setDisplayEvents(theDisplayEvents); 
		setDisplayMonthHeader(shouldDisplayMonthHeader());
	}, [week]);

	useEffect(()=>{
		const theDisplayEvents = calculateDisplayEvents(week);
		setDisplayEvents(theDisplayEvents); 
	}, [selectedSeries])

	return (
		<React.Fragment key={week.startDateJS.toString()}>
			{displayMonthHeader &&
				<ArtiztaBorderBox sx={{p:'.25em', marginBottom:'0.1em', backgroundColor:theme.palette.background.lightLabel}}>
					<Grid container direction='row' justifyContent='center'>
						<Typography variant='h2' color={theme.palette.text.lightLabel}>{week.endDateJS.format('MMMM YYYY')}</Typography>
					</Grid>
				</ArtiztaBorderBox>
			}		
			<Grid direction='row' container sx={{borderBottom:0.5, borderColor:theme.palette.text.disabled}}>
				<Grid item xs={1} container direction='column'>
					<Grid item >
						{week.startDateJS.format('ddd, M/D')}
					</Grid>
					<Grid item>
						{week.endDateJS.format('ddd, M/D')}
					</Grid>
				</Grid>
				<Grid item xs={11} container direction='row'>
					<WeeklyEvents 
						displayEvents={displayEvents} 
						week={week} 
						onSaveEvent={onSaveEvent} 
						onDeleteEvent={onDeleteEvent}
						onEditDetails={onEditDetails}/>
				</Grid>
			</Grid>
		</React.Fragment>
	)
}