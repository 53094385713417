// React imports
import React, { useContext } from 'react';

// material
import { Typography, Grid, TextField } from '@mui/material';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

// Project
import ButtonGroupBar from '../../../common/ButtonGroupBar';
import ButtonGroupButton from '../../../common/ButtonGroupButton';
import ArtiztaBorderBox from '../../../common/ArtiztaBorderBox';
import { SettingsContext } from '../../../utils/SettingsContext';
import PerformanceSkillSelect from '../../settings/PerformanceSkillSelect';

export default function EditEventFeaturedPerformer ({eventFeaturedPerformer, onSave=null, onCancel=null}) {
	const [settings, setSettings] = useContext(SettingsContext);
	
	const handleSaveFeaturedPerformer = (featuredPerformer) => {
		eventFeaturedPerformer.name = document.getElementById('efp-name').value;
		eventFeaturedPerformer.performanceSkill = document.getElementById('efp-performanceSkill').value;
		delete eventFeaturedPerformer.new;

		if (onSave !== null) {
			onSave(featuredPerformer);
		}
	}

	const fieldMargin='1em';

	return (
		<ArtiztaBorderBox>
			<Typography variant='h3'>Edit Featured Performer</Typography>
			<Grid container direction='row' spacing={1} sx={{marginTop:1}}>				
				<Grid item xs={6}>
						<TextField
							sx={{width:'100%'}}
							id="efp-name"
							name="efp-name"
							label="Name"
							defaultValue={eventFeaturedPerformer===null || eventFeaturedPerformer===undefined ? '' : eventFeaturedPerformer.name}
						/>
				</Grid>
				<Grid item xs={6}>
						<PerformanceSkillSelect id='efp-performanceSkill' defaultValue={eventFeaturedPerformer.performanceSkill}/>
				</Grid>
			</Grid>
			<ButtonGroupBar>
				<ButtonGroupButton onClick={()=>{handleSaveFeaturedPerformer(eventFeaturedPerformer)}} toolTip='Save changes for this Featured Performer.'>
					<CheckRoundedIcon/>
				</ButtonGroupButton>
				<ButtonGroupButton onClick={()=>{onCancel(eventFeaturedPerformer)}} toolTip='Finish editing this work, do not save your changes'>
					<CloseRoundedIcon/>
				</ButtonGroupButton>
			</ButtonGroupBar>
		</ArtiztaBorderBox>
	)
}