
// material
import {Box, Typography, useTheme} from '@mui/material';

export default function ThemeTest () {

	const theme=useTheme();

	console.log(theme);
	return (
		<Box sx={{backgroundColor:theme.palette.background.lightLabel}}>
			<Typography variant='h1' color={theme.palette.text.lightLabel}>H1 Light Label</Typography>
			<Typography variant='h2' color={theme.palette.text.lightLabel}>H2 Light Label</Typography>
			<Typography variant='h3' color={theme.palette.text.lightLabel}>H3 Light Label</Typography>
			<Typography variant='h4' color={theme.palette.text.lightLabel}>H4 Light Label</Typography>
			<Typography variant='h5' color={theme.palette.text.lightLabel}>H5 Light Label</Typography>
			<Typography variant='h6' color={theme.palette.text.lightLabel}>H6 Light Label</Typography>
		</Box>
	)
}