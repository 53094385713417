import { useState, useEffect, useContext } from 'react';

//material

//project
import ArtiztaSelect from './ArtiztaSelect.js';
import { OrganizationContext } from '../utils/OrganizationContext.js';

export default function ItemStatusSelect ({onChange, itemType, item, setDescription=null}) {
	const [selectedStatus, setSelectedStatus] = useState(null);
	const [currentOrganization, setCurrentOrganization] = useContext(OrganizationContext);	

	const [eventStatusList, setEventStatusList] = useState([ 
		{ name:'Draft',
			desc: `This ${itemType} is only visible to members of the ${currentOrganization.name}'s Leadership team` }, 
		{ name: 'Planning',
			desc: `This ${itemType} is in Planning and is visible to the ${currentOrganization.name}'s Personnel Managers and Librarians`}, 
		{ name: 'Organization',
			desc: `This ${itemType} is in Planning and is visible to the Everyone in the ${currentOrganization.name} who is a part of the Staff"`}, 
		{ name: 'Performers',
			desc: `Everyone Associated with the ${currentOrganization.name}, including Performers, can view this ${itemType}.`},
		{ name: 'Public',
			desc: `Anyone with access to this Application has access to view this ${itemType}.`}
	])

	useEffect(() => {
		if (item !== null && item !== undefined) {
			if (item.status === null || item.status === undefined) {
				item.status = 'Draft';
			}
			const theStatus = eventStatusList.find((status) => status.name.toLowerCase() === item.status.toLowerCase());
			setSelectedStatus(theStatus);
			if (setDescription !== null && setDescription !== undefined) {
				if (item.status === null || item.status === undefined) {
					setDescription('Currently there is no Access Level defined. This will default to Public, allowing all users to view this item.')
				}
				else {
					setDescription(theStatus.desc);
				}
			}
		}
	}, [item]);

	const handleStatusSelect = (status) => {
		setSelectedStatus(status);
		
		if (onChange !== null && onChange !== undefined) {
			onChange(status);
		}

		if (setDescription !== null && setDescription !== undefined) {
			setDescription(status.desc);
		}
	}

	return (
		<ArtiztaSelect
			itemList={eventStatusList}
			id={`${itemType}-status-select`}
			label={`${itemType} Access Level`}
			fullWidth
			value={selectedStatus}
			onChange={handleStatusSelect}/>
	)
}