// React imports
import React from 'react';
import {Link} from 'react-router-dom';

// Material imports
import {Button, useTheme} from '@mui/material';

// project files
import darkArtiztaLogo from '../assets/Artizta_Logo_Dark.svg';
import lightArtiztaLogo from '../assets/Artizta_Logo_Light.svg';
import darkSymphonEaseLogo from '../assets/SymphonEase_Logo_Dark.svg';
import lightSymphonEaseLogo from '../assets/SymphonEase_Logo_Light.svg';
import darkSymphonEaseLogo02 from '../assets/SymphonEase_Logo02_Dark.svg';
import lightSymphonEaseLogo02 from '../assets/SymphonEase_Logo02_Light.svg';
import darkSymphonEaseLogo03 from '../assets/SymphonEase_Logo03_Dark.svg';
import lightSymphonEaseLogo03 from '../assets/SymphonEase_Logo03_Light.svg';
import darkSymphonEaseLogo04 from '../assets/SymphonEase_Logo04_Dark.svg';
import lightSymphonEaseLogo04 from '../assets/SymphonEase_Logo04_Light.svg';
import darkSymphonEaseLogo05 from '../assets/SymphonEase_Logo05_Dark.svg';
import lightSymphonEaseLogo05 from '../assets/SymphonEase_Logo05_Light.svg';
import darkSymphonEaseLogo06 from '../assets/SymphonEase_Logo06_Dark.svg';
import lightSymphonEaseLogo06 from '../assets/SymphonEase_Logo06_Light.svg';
import symphonEaseNotes from '../assets/SymphonEase_Notes.svg';

export default function Dashboard(props) {
    const theme = useTheme();
  //             onClick={()=>{ setCurrentTab(0)}}>
  
    return (
        <Button component={Link} to='/' disableRipple onClick={props.action} 
            sx={{ 
                padding:0,
                height: '2.5em',
								[theme.breakpoints.down('lg')]: { height:'2em'},
                [theme.breakpoints.down('md')]: { height:'1.5em'},
                [theme.breakpoints.down('sm')]: { height:'1.0em'}}}>
            <img src={lightSymphonEaseLogo06} alt="company logo" height='100%'/>
						<img src={symphonEaseNotes} alt="company logo" height='100%'/>
        </Button>
    )
}