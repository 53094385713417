import React from 'react'

// material
import { Box } from '@mui/material'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

// project imports
import ButtonGroupBar from '../../common/ButtonGroupBar.js'
import ButtonGroupButton from '../../common/ButtonGroupButton.js'

export default function ButtonGroupBarDemo(props) {

	const handleOk = () => {
		console.log('Ok from ButtonGroupBarDemo');
	}

	const handleCancel = () => {
		console.log('Cancel from ButtonGroupBarDemo');
	}

	return (
		<Box sx={{width:'100%'}}>
			Demo for the ButtonGroupBar<p/>
			<ButtonGroupBar>
				<ButtonGroupButton onClick={handleOk}>
					<CheckRoundedIcon/>
				</ButtonGroupButton>
				<ButtonGroupButton onClick={handleCancel}>
					<CloseRoundedIcon/>
				</ButtonGroupButton>
			</ButtonGroupBar>
		</Box>
	)
}