// react imports
import React, {useEffect} from 'react';
import dayjs from 'dayjs';

// Material imports
import { Grid, Typography } from '@mui/material';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
// project imports
import ButtonGroupBar from '../../../common/ButtonGroupBar';
import ButtonGroupButton from '../../../common/ButtonGroupButton';

export default function DisplayEventPerformance({eventPerformance, onEdit, onDelete}) {

	return (
		<Grid item container direction='column' >
			{eventPerformance !== null && eventPerformance !== undefined &&
				<Grid item container direction='row' key={`ep-${eventPerformance.id}`} >					
					<Grid item xs={5}>
						<Typography fontSize={14}>{`${dayjs(eventPerformance.date).format('dddd, MMMM D, YYYY, h:mmA')}`} 
						</Typography>
					</Grid>
					<Grid item xs={5}>
						{eventPerformance.locationName}
					</Grid>
					<Grid item xs={2}>
						<ButtonGroupBar variant='outline' size='small'>
							<ButtonGroupButton toolTip='Edit Performance' placement='left' onClick={onEdit}>
								<EditRoundedIcon/>
							</ButtonGroupButton>
							<ButtonGroupButton toolTip='Remove Performance from this Event' placement='left-end' onClick={onDelete}>
								<DeleteForeverRoundedIcon/>
							</ButtonGroupButton>
						</ButtonGroupBar>			
					</Grid>
				</Grid>
			}
		</Grid>
	)
}