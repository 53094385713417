// material
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, IconButton, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import FeedbackIcon from '@mui/icons-material/Feedback';

// React
import React, { useState } from "react";

// project
import FeedbackClient from "../utils/FeedbackClient";
import ButtonGroupBar from "../common/ButtonGroupBar";
import ButtonGroupButton from "../common/ButtonGroupButton";
import ArtiztaIconButton from "../common/ArtiztaIconButton"


export default function FeedbackButton({currentUser, location}) {
  // state
  const [ showDialog, setShowDialog ] = useState(false)
  const [feedbackType, setFeedbackType] = React.useState('defect');

  const handleChange = (event) => {
    setFeedbackType(event.target.value);
  };

  const sendFeedback = (event) => {
    // Gather from the form fields
		event.preventDefault()
		const formData = new FormData(event.currentTarget)
		var formJson = Object.fromEntries(formData.entries())

    FeedbackClient.postFeedback(location, feedbackType, formJson.attemptedAction, formJson.whatHappened, formJson.suggestion, currentUser.user.displayName, currentUser.user.email)
      .then(json => {
        console.log("feedback:", json)
        setShowDialog(false)
      })
  }


  return (
    <React.Fragment>
      <ArtiztaIconButton toolTip="Send Feedback" onClick={() => {setShowDialog(true)}}>
        <FeedbackIcon />
      </ArtiztaIconButton>

      {/* Feedback Dialog */}
      <Dialog
        open={showDialog}
        onClose={()=>{setShowDialog(false)}}
        fullWidth
        maxWidth={'md'}
        PaperProps={{
          component: 'form',
          onSubmit: sendFeedback
        }}
      >
        <DialogTitle>Feedback</DialogTitle>
        <DialogContent>
          {/* Radio Buttons */}
          <FormControl>
            <RadioGroup
              row
              name="feedbackType"
              value={feedbackType}
              onChange={handleChange}
            >
              <FormControlLabel value="defect" control={<Radio />} label="Bug/Error" />
              <FormControlLabel value="suggestion" control={<Radio />} label="Suggestion" />
            </RadioGroup>
          </FormControl>

          {/* TextFields */}
          <Grid container direction={'column'} spacing={2}>
            <Grid item>
              <TextField 
                label="What were you trying to do?"
                id="attemptedAction"
                name="attemptedAction"
                multiline
                fullWidth
                minRows={2}
              />
            </Grid>
            {
              (feedbackType=="defect") ?
              <Grid item>
                <TextField 
                  label="What happened?"
                  id="whatHappened"
                  name="whatHappened"
                  multiline
                  fullWidth
                  minRows={2}
                />
              </Grid>
              :
              <Grid item>
                <TextField 
                  label="What is your suggestion"
                  id="suggestion"
                  name="suggestion"
                  multiline
                  fullWidth
                  minRows={2}
                />
              </Grid>
            }
          </Grid>
        </DialogContent>

        {/* Buttons */}
        <DialogActions>
          <ButtonGroupBar>
            <ButtonGroupButton sx={{borderRadius:10, p:1}} type="submit" toolTip='Save Person'>
              <CheckRoundedIcon/>
            </ButtonGroupButton>
            <ButtonGroupButton onClick={() => {setShowDialog(false)}} toolTip='Cancel'>
              <CloseRoundedIcon/>
            </ButtonGroupButton>
          </ButtonGroupBar>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}