// React imports
import { React, useContext, useState } from 'react';

// material imports
import { Typography, ListItemButton, List, Paper, Grid } from '@mui/material';

// project imports
import ContentBackground from '../../common/ContentBackground';
import ArtiztaCard from '../../common/ArtiztaCard.js';
import ArtiztaPageTitle from '../../common/ArtiztaPageTitle.js';
import ProfileSettings from './components/ProfileSettings.js';
import DanielsOnlineSettings from './components/DanielsOnlineSettings.js';
import OpenAISettings from './components/OpenAISettings.js';
import ChangeOrganizationSettings from './components/ChangeOrganizationSettings.js';
import { AuthContext } from '../../utils/AuthContext.js';
import { useLocation } from 'react-router-dom';

export default function SettingsPage() {
	// Context
	const [ currentUser ] = useContext(AuthContext)

	// State
	const [ selectedTab, setSelectedTab ] = useState(0)

	const listButtonStyle = {
		borderRadius: 2
	}

	return (
		<ContentBackground>
			{(currentUser!=null)&&
			<ArtiztaCard>
				<ArtiztaPageTitle>Settings</ArtiztaPageTitle>
				<Grid container spacing={2}>
					{/* List of Options */}
					<Grid item xs={3}>
						<List>
							<ListItemButton sx={listButtonStyle} selected={(selectedTab===0)} onClick={()=>setSelectedTab(0)}>
								<Typography>Profile</Typography>
							</ListItemButton>
							{
								(currentUser.person.isGlobalAdmin ||
								(currentUser.person.organizationIDs !== null && currentUser.person.organizationIDs !== undefined && currentUser.person.organizationIDs.length > 1)) &&
								<ListItemButton sx={listButtonStyle} selected={(selectedTab===1)} onClick={()=>setSelectedTab(1)}>
									<Typography>Change Organization</Typography>
								</ListItemButton>
							}
							
							<ListItemButton sx={listButtonStyle} selected={(selectedTab===2)} onClick={()=>setSelectedTab(2)}>
								<Typography>Daniels Online</Typography>
							</ListItemButton>
							<ListItemButton sx={listButtonStyle} selected={(selectedTab===3)} onClick={()=>setSelectedTab(3)}>
								<Typography>OpenAI</Typography>
							</ListItemButton>
						</List>
					</Grid>

					{/* The view */}
					<Grid item xs={9}>
						<Paper sx={{p:2}}>
						{
							(selectedTab===0) &&
							<ProfileSettings />
						}
						{
							(selectedTab===1) &&
							<ChangeOrganizationSettings />
						}
						{
							(selectedTab===2) &&
							<DanielsOnlineSettings />
						}
						{
							(selectedTab===3) &&
							<OpenAISettings />
						}
						</Paper>
					</Grid>
				</Grid>
			</ArtiztaCard>
}
		</ContentBackground>
	)
}