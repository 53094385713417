// React imports
import React, {useState} from 'react';

// material
import { 
	Box, 
	Typography, 
	Grid,
	Accordion,
	AccordionSummary,
	AccordionDetails } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Groups2RoundedIcon from '@mui/icons-material/Groups2Rounded';

// project imports
import AccordionTable from '../../../common/AccordionTable.js'

export default function AccordionTableDemo(props) {

	const u2Columns = [
		{
			xs:6,
			headerText:'Name',
			cell:'name'
		},
		{
			xs:6,
			headerText:'Title',
			cell:'title'
		}
	];

	const u2Data = [
		{key:1, name:'Adam Clayton', title:'Bass'},
		{key:2, name:'Bono', title:'Lead Singer'},
		{key:3, name:'The Edge', title:'Guitar'},
		{key:4, name:'Larry Mullen, Jr.', title:'Drums'},
		{key:5, name:'Brian Eno', title:'Producer'}
	];

	const customColumns = [
		{
			xs:6,
			headerText:'Name',
			cell:'name',
			headerComponent:()=>{return (
				<Box sx={{paddingLeft:'.5em'}}>
					<Grid container direction='row'>
						<Groups2RoundedIcon />
						<Typography  fontSize={20} sx={{marginLeft:'0.5em'}}><b>Name</b></Typography>
					</Grid>
				</Box>);},
			cellComponent:(row)=>{return (
				<Box sx={{paddingLeft:'.5em'}} >
					<Typography fontSize={16}>{row.name}</Typography>
				</Box>);}
		},
		{
			xs:6,
			headerText:'Title',
			cell:'title',
			headerComponent:()=>{return (
				<Box  sx={{paddingLeft:'.5em'}} >
					<Typography  fontSize={20}><b>Title</b></Typography>
				</Box>);
			}
		}
	];

	const customData = [
		{id:1, name:'Adam Clayton', title:'Bass'},
		{id:2, name:'Bono', title:'Lead Singer'},
		{id:3, name:'The Edge', title:'Guitar'},
		{id:4, name:'Larry Mullen, Jr.', title:'Drums'},
		{id:5, name:'Brian Eno', title:'Producer'}
	];

	const u2detailsPanel = (row) => {
		return (
			<Box  >
				<Grid container justifyContent='center' alignContent='center' direction='column'>
					<Grid item><Typography variant='h1'>{row.name}</Typography></Grid>
					<Grid item><Typography variant='h4'>{row.title}</Typography></Grid>
				</Grid>
			</Box>
		)
	}

	return (
		<Box>
			Demo for the <b>Accordion Table</b><br/>
			Out of the Box (no custom components):<p/>
			<AccordionTable data={u2Data} columns={u2Columns} detailsPanel={u2detailsPanel}/>
			<p/>
			With Custom Headers and Cell Components:<p/>
			<AccordionTable 
				data={customData} 
				columns={customColumns} 
				detailsPanel={u2detailsPanel}
				rowKey='id'/>
		</Box>
	)
}