// React imports
import React from 'react';
import {Link} from 'react-router-dom';
// Material imports
import {Tabs, Tab, useTheme} from '@mui/material';
//icons
import PlaceRoundedIcon from '@mui/icons-material/PlaceRounded';
import LibraryMusicRoundedIcon from '@mui/icons-material/LibraryMusicRounded';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import TheaterComedyRoundedIcon from '@mui/icons-material/TheaterComedyRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import BuildRoundedIcon from '@mui/icons-material/BuildRounded';
import DesignServicesRoundedIcon from '@mui/icons-material/DesignServicesRounded';

// project imports


export default function ArtiztaTabMenu(props) {
  
	const theme = useTheme();

	return (
		<Tabs  
				onChange={props.onChange} 
				value={props.currentValue}
				sx={{
					marginLeft: "auto",
					"& a.Mui-selected": {color: theme.palette.text.tabSelected}
				}}
				TabIndicatorProps={{
					 style: {
					 	backgroundColor: theme.palette.text.tabSelected
					 }
				}}>
			<Tab sx={{color: theme.palette.text.tab}}
				component={Link} to='/'
				label='Home' icon={<HomeRoundedIcon/>}/>
			{/*
			<Tab sx={{color: artiztaBlack}} 
				component={Link} to='/organizations'
				label='Organizations' icon={<BusinessRoundedIcon/>}/>
			*/}
			<Tab  sx={{color: theme.palette.text.tab}}
				component={Link} to='/locations'
				label='Locations' icon={<PlaceRoundedIcon/>}/>
			<Tab sx={{color: theme.palette.text.tab}}
				component={Link} to='/people'
				label='People' icon={<PeopleAltRoundedIcon/>}/>
			<Tab  sx={{color: theme.palette.text.tab}}
				component={Link} to='/events'
				label='Events' icon={<TheaterComedyRoundedIcon/>}/>
			<Tab  sx={{color: theme.palette.text.tab}}
				component={Link} to='/seasonDesigner'
				label='Season Designer' icon={<DesignServicesRoundedIcon/>}/>
			<Tab  sx={{color: theme.palette.text.tab}}
				component={Link} to='/works'
				label='Works' icon={<LibraryMusicRoundedIcon/>}/>
			<Tab sx={{color: theme.palette.text.tab}}
				component={Link} to='/settings'
				label='Settings' icon={<SettingsRoundedIcon/>}/> 
			<Tab  sx={{color: theme.palette.text.tab}}
				component={Link} to='/sandbox'
				label='Sandbox' icon={<BuildRoundedIcon/>}/>                     
		</Tabs> )
}