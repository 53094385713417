import React, {useEffect, useState, useContext} from 'react';
import dayjs from 'dayjs';

//material
import { Box, Grid, Typography, Divider } from '@mui/material';

//project
import { OrganizationContext } from '../../utils/OrganizationContext.js';
import OrganizationsClient from '../organizations/OrganizationsClient';
import EventsClient from './EventsClient.js';


export default function EventPromotionalDisplay ({event}) {
	const [series, setSeries] = useState(null);
	const [currentOrganization, setCurrentOrganization] = useContext(OrganizationContext);
	const [firstPerformanceDate, setFirstPerformanceDate] = useState(EventsClient.getFirstPerformanceDate(event));

	const briefDesc = (desc) => {
		if (desc !== null && desc !== undefined && desc.length > 20) {
			return desc.substring(0, 100)+'...';
		}
		return desc;
	}
	
	useEffect(()=>{
		if (event !== null && event !== undefined) {
			const theSeries = OrganizationsClient.getSeriesForEvent(currentOrganization, event);
			setSeries(theSeries);
		}
	}, [event]);

	return (
		<Box sx={{p:0, overflow:'hidden', m:'0.25em'}}>
			<Grid container direction='row'>
				<Grid item xs={1} key='large-date' sx={{paddingRight:'1em', paddingTop:'0.5em'}}>
					<Grid container direction='column' >
						<Grid item container justifyContent='flex-end' >
							<Typography fontSize={28}><b>{dayjs(firstPerformanceDate).format('MMM')}</b></Typography>
						</Grid>
						<Grid item container justifyContent='flex-end'>
							<Typography fontSize={28}><b>{dayjs(firstPerformanceDate).format('DD')}</b></Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={1} key='event-image' sx={{paddingTop:'0.5em'}}>
					{event.imageURL !== null && event.imageURL !== undefined && event.imageURL !== '' &&
						<img src={event.imageURL} alt={event.name} style={{ width:'100%'}}/>
					}
				</Grid>
				<Grid item xs={10} key='event-info'>
					<Box sx={{
						height: '100%',
						paddingLeft:'1em',
						paddingTop:'0.5em',
						paddingBottom: '0.5em'}}>
							<Grid container direction='column' justifyContent='space-between' sx={{height:'100%'}}>
								<Grid item>
									<Grid container direction='row' justifyContent='space-between'>
										<Grid item>
											<Typography variant='h2'><b>{event.name}</b></Typography>
										</Grid>
										<Grid item>
											<Typography fontSize={12}>
												{event.series !== undefined && event.series !== '' && event.series !== null &&
													<React.Fragment>
														{event.series} Series
													</React.Fragment>
												}
											</Typography>
										</Grid>
									</Grid>
								</Grid>
								<Grid item>
									<Typography fontSize={12}><i>{briefDesc(event.desc)}</i></Typography>
								</Grid>
								<Grid item>
									{event.performances !== null && event.performances !== undefined && event.performances.map((performance)=>{
										return (
											<Typography key={performance.date} fontSize={14}>{dayjs(performance.date).format('dddd, MMM D, h:mmA')} at {performance.locationName}</Typography>
										)
									})}
								</Grid>
							</Grid>
					</Box>
				</Grid>
			</Grid>
		</Box>
	)
}