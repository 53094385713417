// React imports
import React, { useEffect } from 'react';

// Material imports
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
//import Grid from '@mui/material/Unstable_Grid2';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


// project imports

import DanielsOnlineDisplay from './DanielsOnlineDisplay.js';

/**
 * This displays the DanielsOnline component in the Work
 * To display all the DanielsOnline things, use the DanielsOnlineNativeDisplay
 */
export default function WorkDanielsOnlineDisplay({ work, onEdit }) {

	useEffect(()=>{
		if (work !== null && work !== undefined) {
			if (work.daniels !== null && work.daniels !== undefined) {
				const movements = work.daniels.movements;
				if (movements !== null && movements !== undefined) {
					// order them
					movements.sort((a,b)=>{
						return parseInt(a.order)-parseInt(b.order);
					}) 
				}
			}
		}
	},[work]);

	if ( work===null || work === undefined ||
		work.daniels === null || work.daniels === undefined) {
		return;
	}

	return (
		<Accordion>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon/>}>
					Additional Information from Daniels Online
			</AccordionSummary>
			<AccordionDetails>
				<DanielsOnlineDisplay danielsOnline={work.daniels}/>
			</AccordionDetails>
		</Accordion>
	)
/*
	return (
		<Accordion>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon/>}>
					Additional Information from Daniels Online
			</AccordionSummary>
			<AccordionDetails>
				<Grid container direction='column' sx={{marginTop:1, marginBottom:1}}>
					<Grid container item direction='row' >
						<Grid item xs={3}>
							<b>Title </b>
						</Grid >
						<Grid item xs={9}>
							<Tooltip title="Title as it appears in DanielsOnline">
								<span>{currentWork.daniels.title}</span>
							</Tooltip>
						</Grid> 
					</Grid>
					<Grid container item direction='row' >
						<Grid item xs={3}>
							<b>ID </b>
						</Grid >
						<Grid item xs={9}>
							<Tooltip title="Daniels Online unique ID for this Work">
								<span>{currentWork.daniels.id}</span>
							</Tooltip>
						</Grid> 
					</Grid>
					<Grid container item direction='row' >
						<Grid item xs={3}>
							<b>Remarks</b>
						</Grid >
						<Grid item xs={9}>
							{currentWork.daniels.remarks}
						</Grid> 
					</Grid>
				</Grid>
				{currentWork.daniels !== null && currentWork.daniels !== undefined &&
					<Grid container item direction='row' >
						<Grid item xs={3}>
							<b>Movements</b>
						</Grid >
						<Grid item container xs={9} direction='column'>
							{currentWork.daniels.movements.map((movement)=> {
								return (
									<Grid key={movement.order}>{movement.name}, {movement.duration} minutes</Grid>
								)
							})}
						</Grid> 
					</Grid>
				}
			</AccordionDetails>
		</Accordion>
	)
	*/
}