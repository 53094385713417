// React imports
import React, { useContext, useState, useEffect } from 'react';
import dayjs from 'dayjs';
// material imports
import { Box, Tabs, Tab, Grid } from '@mui/material';
import TheaterComedyRoundedIcon from '@mui/icons-material/TheaterComedyRounded';
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

// project imports
import ContentBackground from '../../common/ContentBackground';
import EventsList from './EventsList.js';
import OrganizationChange from '../organizations/components/OrganizationChange.js';
import { OrganizationContext } from '../../utils/OrganizationContext.js';
import ArtiztaCard from '../../common/ArtiztaCard.js';
import ArtiztaPageTitle from '../../common/ArtiztaPageTitle.js';
import OrganizationName from '../organizations/components/OrganizationName.js';
import ArtiztaIconButton from '../../common/ArtiztaIconButton.js';
import EditEventComponent from './EditEventComponent.js';
import EventsClient from './EventsClient.js';
import ArtiztaEditComponent from '../../common/ArtiztaEditComponent.js';
import ArtiztaAlertDialog, { clearAlertDialog } from '../../common/ArtiztaAlertDialog.js';
import ArtiztaSnackbar from '../../common/ArtiztaSnackbar.js';
import CommentsDisplay from '../../common/CommentsDisplay.js';

export default function EventsPage(props) {
	const [currentEvent, setCurrentEvent] = useState(null);
	const [currentTab, setCurrentTab] = useState(0);
	const [currentOrganization, setCurrentOrganization] = useContext(OrganizationContext);
	const [showAddEventDisplay, setShowAddEventDisplay] = useState(false);
	const [events, setEvents] = useState(null)
	const [alertDialogOptions, setAlertDialogOptions] = useState(clearAlertDialog); 
	const [snackBarOpen, setSnackBarOpen] = useState(false);
	const [snackBarMessage, setSnackBarMessage] = useState(null);
	const [addIconDisabled, setAddIconDisabled] = useState(false)
	
	const handleTabChange = (event, newValue) => {
		setCurrentTab(newValue);
	}

	const handleAddEvent = () => {
		// Change icon to grey
		setAddIconDisabled(true)
		setCurrentEvent({new:true, name:'New Event Added on '+dayjs().format('MM/DD hh:mm A')});
		setShowAddEventDisplay(true);
	}

	const saveEvent = async (event) => {
		setShowAddEventDisplay(false);
		if (event.performances === null || event.performances === undefined || event.performances.length === 0) {
			const performanceDateJS = dayjs().add(1, 'month').hour(20).minute(0).second(0).millisecond(0);
			event.performances = [{date:performanceDateJS.toDate()}];
		}
		const theEvent = await EventsClient.save(currentOrganization, event);
		const theEvents = await EventsClient.getAll(currentOrganization);
		setEvents(theEvents);
		setSnackBarMessage(`Saved '${event.name}'`);
		setSnackBarOpen(true);
		setAddIconDisabled(false)
		fetchEvents();
	}

	const handleCancelAddEvent = () => {
		setShowAddEventDisplay(false);
		setAddIconDisabled(false);
		fetchEvents();
	}

	const handleEditEvent = (event) => { 
		setCurrentEvent(event);
		setShowAddEventDisplay(true);
		setAddIconDisabled(true)
	}

	const handleDeleteEvent = async (event) => { 
		setAlertDialogOptions({
			open:true,
			title:`Are you sure you want to delete this event?`,
			message:`This will remove '${event.name}' and all associate Event and Performance data permanently. Are you sure?`,
			onAgree: async () => {
				const theEvent = await EventsClient.delete(currentOrganization, event);
				const theEvents = await EventsClient.getAll(currentOrganization);
				setEvents(theEvents);
				setAlertDialogOptions(clearAlertDialog)
				setSnackBarMessage(`Deleted '${event.name}'`);
				setSnackBarOpen(true);
				fetchEvents();},
			onDisagree: () => {
				// no harm no foul
				setAlertDialogOptions(clearAlertDialog)}
		});
	}

	const fetchEvents = async () => {
		const theEvents = await EventsClient.getAll(currentOrganization);
		setEvents(theEvents);
	}

	useEffect(()=>{
		fetchEvents();
	}, []);

	return (
		<ContentBackground>
			<ArtiztaSnackbar open={snackBarOpen} message={snackBarMessage} setOpen={setSnackBarOpen}/>
			<ArtiztaAlertDialog dialogOptions={alertDialogOptions}/> 
			<ArtiztaCard>
				<Grid container justifyContent='space-between' alignItems='center' direction='row'>
					<ArtiztaPageTitle>Events</ArtiztaPageTitle>
					<ArtiztaIconButton 
						toolTip='Add a new Event' 
						disabled={currentOrganization===null || addIconDisabled} 
						onClick={handleAddEvent}>
						<AddRoundedIcon/>
					</ArtiztaIconButton>
				</Grid>
				<Grid container direction='row' justifyContent='space-between'>
					<Tabs value={currentTab} onChange={handleTabChange}>
						<Tab label='Upcoming' icon={<TheaterComedyRoundedIcon/>} />
						<Tab label='Previous' icon={<HistoryRoundedIcon/>}/>
					</Tabs>
					<OrganizationName/>
				</Grid>
				{showAddEventDisplay &&
					<Box>
						<ArtiztaEditComponent 
								title={`Editing '${currentEvent.name}'`}
								onSave={saveEvent} 
								onCancel={handleCancelAddEvent}
								item={currentEvent}
								effect='stars'>
							<EditEventComponent
									title='Add New Event' 
									setEvents={setEvents}
							/>
						</ArtiztaEditComponent>
						{currentEvent.id !== null && currentEvent.id !== undefined &&
							<Box sx={{paddingTop:'0.5em', paddingBottom:'0.5em'}}>
								{/* <CommentsDisplay item={currentEvent} type='event'/> */}
							</Box>
						}
					</Box>
				}
				{!showAddEventDisplay &&
					<Box>
						{currentTab===0 &&
							<EventsList title='Upcoming Events' type='upcoming' numEvents={null} onEdit={handleEditEvent} onDelete={handleDeleteEvent} events={events}/>
						}
						{currentTab===1 &&
							<EventsList title='Previous Events' type='previous' numEvents={null} onDelete={handleDeleteEvent} events={events}/>
						}
					</Box>
				}
			</ArtiztaCard>
		</ContentBackground>
	)
}