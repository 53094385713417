// React imports
import React, { useState, useEffect } from 'react';

// material
import { OutlinedInput, Select, MenuItem, InputLabel, FormControl } from '@mui/material';


export default function ArtiztaSelect({ itemList, id, label, value, onChange }) {
	const [selectedValue, setSelectedValue] = useState('');

	const handleChange = (e) => {
		
		setSelectedValue(e.target.value);
		if (onChange !== null ) {
			onChange(e.target.value);
		}
	}

	useEffect(() => {
		if (value !== null && value !== undefined && itemList !== null && itemList !== undefined && itemList.includes(value)) {
			setSelectedValue(value);
		}
	}, [value, itemList]);
	
	return (
		<FormControl fullWidth>
			<InputLabel id={id+'-label'}>{label}</InputLabel>
			<Select
				labelId={id+'-label'}
				label={label}
				id={id+'-select'}
				value={selectedValue}
				onChange={handleChange}>
					<MenuItem value={''} sx={{width:'100%'}}>None</MenuItem>
					{itemList !== null && itemList !== undefined && itemList.map((item) => { return(
						<MenuItem value={item} key={item.name} sx={{width:'100%'}}>
							{item.name}
						</MenuItem>
					)})}
			</Select>
		</FormControl>
	)
}