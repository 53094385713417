import React from 'react';

// material
import { Box, Grid, Typography } from '@mui/material';

// project imports
import CommentDisplay from '../../common/CommentsDisplay.js';

export default function EventDisplay( { event }) {

	return (
		<Box key={event.id+'box'}>
			<Grid container direction = 'column' key={event.id+'column'}>
				{/* Performance dates & Feturing */}
				<Grid container direction={'row'} key={event.id+'row'}>
					{/* Performance Dates */}
					<Grid item xs={8}>
						<Typography fontWeight={'bold'} variant='h2'>Performance Dates</Typography>
						{(event.performances !== null && event.performances !== undefined && event.performances.length > 0) ?
							event.performances.map((performance) => {
								const performanceDate = new Date(performance.date);
								
								return (
									<React.Fragment key={performanceDate}>
										<Grid item container direction='row' key={performanceDate}>
											<Grid item xs={5}>
												<Typography fontSize={14}>
													{
													`${performanceDate.toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"long", day:"numeric"})}, 
													${performanceDate.toLocaleTimeString('en-us', { hour:"numeric", minute:"numeric", hour12: true})}`
													} 
												</Typography>
											</Grid>
											<Grid item xs={7}>
												<Typography fontSize={14}>{performance.locationName}</Typography>
											</Grid>	
										</Grid>
									</React.Fragment>
							)})
							:
							<Typography>None</Typography>
						}
					</Grid>

					{/* Featuring */}
					<Grid item xs={4}>
						<Typography fontWeight={'bold'} variant='h2'>Featuring</Typography>
						{(event.featuredPerformers != undefined && event.featuredPerformers != null && event.featuredPerformers.length > 0) ?
							event.featuredPerformers.map((performer) => {
								return (
									<Grid container direction={'row'} key={event.id+performer+performer.name+performer.performanceSkill}>
										<Typography sx={{marginRight:1}}>{performer.name} | </Typography>
										<Typography fontStyle={'italic'}>{performer.performanceSkill}</Typography>
									</Grid>
								)
							})
							:
							<Typography>None</Typography>
						}
					</Grid>

				</Grid>
				{/* Performances */}
				

				{/* Featured Performers */}
				

				{/* Works */}
				<Typography fontWeight={'bold'} sx={{marginTop:2}} variant='h2'>Works</Typography>
				{(event.works != undefined && event.works != null && event.works.length > 0)?
					event.works.map((work) => {
						return (
							<Grid sx={{marginTop:1}} container key={work.order} justifyContent={'space-between'} alignItems={'center'}>
								<Grid item xs={3}><Typography>{work.title}</Typography></Grid>
								<Grid item xs={3}><Typography>{work.composer}</Typography></Grid>
								<Grid item xs={3}><Typography>{(work.duration==undefined)?"no duration":work.duration+ " min."}</Typography></Grid>
								<Grid item xs={3}><Typography>{(work.instrumentation==undefined)?"No Instrumentation":work.instrumentation}</Typography></Grid>
							</Grid>
						)
					})
					:
					<Typography>None</Typography>
				}
			</Grid>
			<CommentDisplay item={event} type='event'/>
		</Box>
	)
}