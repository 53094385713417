// React imports
import React, { useState, useContext, useEffect } from 'react';

// Material imports
import { Box, TextField, Autocomplete, Grid } from '@mui/material';

// project imports
import { OrganizationContext } from '../../../utils/OrganizationContext.js';
import OrganizationChange from '../../organizations/components/OrganizationChange.js';
import { AuthContext } from '../../../utils/AuthContext.js';
import WorksClient from '../WorksClient.js';
import WorkDisplay from './WorkDisplay.js';
import EditWorkComponent from './EditWorkComponent.js';
import ArtiztaEditDialog from '../../../common/ArtiztaEditDialog.js';

export default function WorksSearch({isEditable=true, onSelectWork=null}) {
  const [currentWork, setCurrentWork] = useState(null);
	const [currentEditingWork, setCurrentEditingWork] = useState(null);	
  const [currentOrganization, setCurrentOrganization] = useContext(OrganizationContext);
  const [currentUser, setCurrentUser] = useContext(AuthContext);
	const [displayItems, setDisplayItems] = useState([]);
	const [showEditWorkWindow, setShowEditWorkWindow] = useState(false);

	useEffect(() => {
		const fetchWorks = async () => {
			if (currentOrganization !== null) {
				const works = await WorksClient.getAll(currentOrganization);
				setDisplayItems(works);
			}
		}

		fetchWorks();
	}, [currentOrganization]);

	useEffect(() => {
		const element = document.getElementById('worksSearch');
		if ( element !== null && element !== undefined) {
			document.getElementById('worksSearch').value = '';
		}
	}, [currentWork]);

	if (currentUser === null) {

		return (
			<Box>
				No User Selected
			</Box>
		);
	}

	if (currentOrganization === null ) {
		return (
			<Box sx={{marginTop:'1em'}}>
				<OrganizationChange/>
			</Box>
		)
	}

	const handleSelection = (event, newValue) => {
		setCurrentWork(newValue);
		if (onSelectWork !== null) {
			onSelectWork(newValue);
		}
	}

	// Search Helper
	const handleInputChange = (event, newValue) => {
		// Right now, just load all works in an organization and search over them
		return;
	}

	const handleEditWork = (work) => {
		setCurrentEditingWork(work);
	}

	const saveWork = async (newWork) => {

		setCurrentEditingWork(null);
		const savedWork = await WorksClient.update(currentOrganization, newWork);
		// need a snackbar `'${savedWork.title}' updated with id ${savedWork.id}`
	}

	return (
		<Box>
			<Grid container direction='column'>
				<Grid sx={{marginTop:1.5, marginLeft:1.5, marginBottom:0}}>
				</Grid>
				<Grid sx={{m:2}}>
					<Autocomplete 
							id="worksSearch"
							name="worksSearch"
							autoHighlight={true}
							options={displayItems}
							getOptionLabel={option=>{ return (`${option.title} - ${option.composer}`)}}
							isOptionEqualToValue={(option, value) => {return option.id===value.id}}
							renderInput={(params) => <TextField {...params} label="Search for a Work in your Library" />}
							onChange={handleSelection}
							onInputChange={handleInputChange}
						/>
				</Grid>
				{currentWork !== null &&
					<Grid item>
						<WorkDisplay work={currentWork} isEditable={isEditable} onEdit={handleEditWork}/>
					</Grid>
				}
			</Grid>
			<ArtiztaEditDialog 
				title='Edit Work' 
				onSave={saveWork} 
				item={currentEditingWork} 
				onCancel={()=>{setCurrentEditingWork(null)}}>
				<EditWorkComponent />
			</ArtiztaEditDialog>
		</Box>
	)
}