
// material
import { Box, Grid, Typography } from '@mui/material';

export default function ArtiztaEditBox({message, onEdit}) {

	return (
		<Box sx={{
			borderRadius:2,									
			width:'100%', 
			height:'100%',
			'&:hover': {
				cursor:'pointer',
				border: 1,
				borderStyle:'dashed'}
			}}
			onClick={()=>onEdit()}
		>
			<Grid container direction='row' justifyContent='center' alignItems='center'>
				<Grid item>
					<Typography>{message}</Typography>
				</Grid>
			</Grid>
		</Box>
	)
}
