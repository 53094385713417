
// project imports
import { FirebaseFileClient } from "../../utils/FirebaseFileClient";

const addJSONExtension = (filename) => {
	const parts = filename.split('.');
	const lastPart = parts[parts.length-1];
	if (lastPart.toLowerCase() === 'json') {
		return filename;
	}
	else {
		return filename+'.json'
	}
}


export default class SettingsClient {
	/**
	 * Use this for the Roles settings
	 */
	static roles='roles';

	/**
	 * Use this for the Performance Skills settings
	 */
	static performanceSkills='performanceSkills';

	/**
	 * Returns a settings json for a name from Firebase storage. use SettingsClient.roles for a list of roles and SettingsClient.performanceSkills for a list of performance skills
	 * This will return null if the setting does not exist. Need to await this function as it returns a promise.
	 * @param {string} name the settings name
	 * @returns {{}} a dictionary of the settings
	 */
	static getForName = async (name) => {
		const filename = addJSONExtension(name);
		try {
			const json = await FirebaseFileClient.getJSONForFilename('settings', filename);
			
			return json;
		}
		catch (error) {
			return null;
		}
	}
	
	/**
	 * returns all settings in Firebase storage as an array
	 */
	static getAll = () => {
		return FirebaseFileClient.getJSONFiles('settings');
	}

	static getTitle = (json) => {
		return json.title;
	}

	static getUsage = (json) => {
		return json.usage;
	}

	/**
	 * This returns the actual settings from the json returned from Firestore
	 * Not very useful as you have to then parse the settings.
	 * use getSettingsTitles() and getSettingsForSettingsTitle instead.
	 */
	static getSettings = (json) => {
		// find the headings, it's the one that is not 'title' or 'usage'. should only be one more
		const keys = Object.keys(json);
		let headingsKey = null;
		for(var i=0; i<keys.length; i++) {
			const key = keys[i];
			if (key!=='title' && key!=='usage') {
				headingsKey = key;
				break;
			}
		}
		if (headingsKey == null) {
			return null;
		}
		return (json[headingsKey]);
	}

	/** 
	 * This returns an array of titles for the settings. These titles can be used in getSettingsForSettingsTitle()
	 */
	static getSettingsTitles = (json) => {
		const titles = [];
		const headings = SettingsClient.getSettings(json);
		headings.forEach((heading)=> {
			const keys = Object.keys(heading);
			titles.push(keys[0]);
		});
		return titles;
	}

	/**
	 * Returns an array of settings given a title. Usage: get the json first using const json = await SettingsClient.getForName(<name>)
	 * Then const settings = getSettingsForSettingsTitle(json, 'Roles'); example for roles
	 */
	static getSettingsForSettingsTitle = (json, title) => {
		const settings = SettingsClient.getSettings(json);
		var returnValue = null;

		settings.forEach((setting) =>{
			const keys = Object.keys(setting);
			// should only be one
			if (keys[0] === title) {
				returnValue = setting[title];
			}
		});
		return returnValue;
	}
}