// React Imports
import { useState, useContext, useEffect, useRef } from "react";

// Material Imports
import {  Checkbox, 
					Box,
          Button, 
          Dialog, 
          DialogActions, 
          DialogContent, 
          DialogTitle, 
          FormControlLabel, 
          Grid, 
          TextField, 
          Tooltip,
					Typography } from "@mui/material";
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

// Project Imports
import FileSelector from "../../../common/FileSelector";
import { FirebaseFileClient } from "../../../utils/FirebaseFileClient";
import { OrganizationContext } from "../../../utils/OrganizationContext";
import { SettingsContext } from "../../../utils/SettingsContext";
import OrganizationsClient from "../../organizations/OrganizationsClient";
import PeopleClient from "../PeopleClient";
import PerformanceSkillsSelector from "./PerformanceSkillsSelector";
import ArtiztaIconButton from "../../../common/ArtiztaIconButton";
import ButtonGroupBar from "../../../common/ButtonGroupBar";
import ButtonGroupButton from "../../../common/ButtonGroupButton";
import PeopleEmailSearch from './PeopleEmailSearch.js';

//import PeopleClient from "../PeopleClient";
// import amazonIcon from '../../../assets/amazon-icon.svg';
// import { CheckBox } from "@mui/icons-material";

export default function PeopleNew({ open, setOpen, person=null, setPerson, onSave=null}) {
  // Context 
  const [ currentOrganization, setCurrentOrganization ] = useContext(OrganizationContext)
  const [ settings ] = useContext(SettingsContext)
	const [ selectedRoles, setSelectedRoles ] = useState([]);
	const [ emailFound, setEmailFound ] = useState(null);
	const [checkedBoxes, setCheckedBoxes] = useState({
		"Organization Admin": false,
		"Leadership": false,
    "Librarian": false,
    "Personnel Manager": false,
    "Staff": true,
    "Performer": false
	})

	const resetCheckedBoxes = () => { 
		setCheckedBoxes({
			"Organization Admin": false,
			"Leadership": false,
			"Librarian": false,
			"Personnel Manager": false,
			"Staff": true,
			"Performer": false
		});
	}

	// Temp, until api is set up
	const allRoles = [
    "Organization Admin",
    "Leadership",
    "Librarian",
    "Personnel Manager",
    "Staff",
    "Performer"
  ]
	const firstNameRef = useRef(null);
	const titleRef = useRef(null);

	const setSelectedCheckboxesFromPerson = (aPerson) => {
		const newCheckBoxes = {};

		allRoles.map((role) => {
			if (aPerson === null || aPerson.roles === undefined ) {
				newCheckBoxes[role]=false;
			}
			else {
				const findRole = aPerson.roles.find(item=>item===role)
				newCheckBoxes[role]=(findRole!== null && findRole !== undefined);
			}
		})
		setCheckedBoxes(newCheckBoxes);
	}

	const setRolesFromSelectedCheckboxes = (aPerson) => {
		aPerson.roles = [];

		allRoles.map((role) => {
			if (checkedBoxes[role] === true) {
				aPerson.roles.push(role);
			}
		})
	}

	const handleRoleChange = (event) => {
		const { name, checked } = event.target;
		const change = {};
		change[name]=checked;
		const newCheckedBoxes = {...checkedBoxes, ...change};
		setCheckedBoxes( newCheckedBoxes );
	}

  // Key person star
  const [ isKeyPerson, setIsKeyPerson ] = useState(person!=null ? person.isKeyPerson : false)
  const handleClickKeyPerson = () => {
    setIsKeyPerson(!isKeyPerson);
  }

  // Performance Skills
  const [performanceSkills, setPerformanceSkills] = useState( person!=null ? person.performanceSkills : [])

  // Error Handling
  const [ emailError, setEmailError ] = useState(false)

  // File Selector
  const [filename, setFilename] = useState('No File Selected')
  const [imageData, setImageData] = useState(null)
  const selectImage = (theFile, theImageData) => {
    if (theFile) {

      setImageData(theImageData);
      FirebaseFileClient.upload(theFile, 'images', (url) => {
          setFilename(url);
      });
    }
  }
  const deleteImage = (url) => {
    setFilename('deleted');
    setImageData(null);
  }

  // State Helper
  const handleClose = () => {
		setEmailFound(null);
    setOpen(false);
    setPerformanceSkills([]);
		setSelectedRoles(null);
		setPerson(null);
    deleteImage();
		resetCheckedBoxes();
  }

	const handleSelectEmail = (newPerson) => {

		if (newPerson !== null && newPerson !== undefined) {
			if (currentOrganization.people === null || currentOrganization.people === undefined) {
				currentOrganization.people = [];
			}
			const orgPerson = currentOrganization.people.find(person => person.email === newPerson.email);

			if (orgPerson !== null && orgPerson !== undefined) {
				newPerson.roles = orgPerson.roles;
				newPerson.title = orgPerson.title;
				newPerson.isKeyPerson = orgPerson.isKeyPerson;
			}
			setPerson(newPerson);
			setEmailFound(newPerson.email);
		}
		populateFields();
	}

	const [email, setEmail] = useState(null);

	const handleEmailBlur = (event) => {
		const typedEmail = event.target.value;
		if (typedEmail !== emailFound) {
			setEmailFound(null);
			setPerson({email:typedEmail})
			setEmail(typedEmail);
			populateFields();
			//firstNameRef.current.focus();
		}
	}

	const valueEmptyCheck = (value, emptyValue) => {
		if (value === null || value === undefined) {
			return emptyValue;
		}
		else {
			return value;
		}
	}
	const populateFields = () => {
		if (document.getElementById('title') === null || document.getElementById('title') === undefined) {
			return;
		}
		if (person !== null && person !== undefined) {
			if (emailFound === null ) {
				document.getElementById('firstName').value = valueEmptyCheck(person.firstName, '');
				document.getElementById('lastName').value = valueEmptyCheck(person.lastName, '');
				document.getElementById('tagLine').value = valueEmptyCheck(person.tagLine, '');
				document.getElementById('profile').value = valueEmptyCheck(person.profile, '');
			}
			document.getElementById('title').value = valueEmptyCheck(person.title, '');
			setIsKeyPerson(person.isKeyPerson);
			setPerformanceSkills(valueEmptyCheck(person.performanceSkills,[]));
			setSelectedCheckboxesFromPerson(person);
			// need to add image
		}
	}

	const handleFormSubmit = async (event) => {
		// Gather from the form fields
		event.preventDefault()
		const formData = new FormData(event.currentTarget);
		var formJson = Object.fromEntries(formData.entries());

		// Add & tweak data
		formJson.performanceSkills = performanceSkills;
		formJson.profileImage = filename;
		if ( email !== null ) {
			formJson.email = email;
		}
		setRolesFromSelectedCheckboxes(formJson);

		formJson.isKeyPerson = isKeyPerson;
		if (emailFound !== null && emailFound !== undefined) {
			delete person.roles;
			delete person.title;
			delete person.isKeyPerson;
			
			formJson = {...formJson, ...person};
		}

		await OrganizationsClient.setPersonInOrganization(currentOrganization, formJson);

		const newOrg = await OrganizationsClient.getOrganization(currentOrganization);
		if ( onSave !== null && onSave !== undefined ) {
			onSave(formJson);
		}

		setCurrentOrganization(newOrg);
		setPerson(null);
		handleClose();

		return;
	}

	/*
	useEffect(()=>{
		setCurrentPerson(person);
	}, [person])
	*/
	useEffect(()=>{
		populateFields();
	},[person])

  return (
    <Dialog
      sx={{marginTop: '5em'}}
      fullWidth={true}
      maxWidth={'lg'}
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit: handleFormSubmit
      }}
    >
      <Grid container justifyContent={'space-between'}>
        {/* Title*/}
        {
          (person==null) ?
            <DialogTitle>Adding New Person to {currentOrganization.name} </DialogTitle>
          :
            <DialogTitle>Editing {person.firstName} {person.lastName} </DialogTitle>
        }
        {/* Is Key Person */}
        <Tooltip title={isKeyPerson ? "Unmake Key Person" : "Make Key Person"}>
          <Button
            // toolTip="Key Person"
            onClick={handleClickKeyPerson}
          >
            {
            (isKeyPerson)? 
              <StarIcon sx={{m:1.5}}/>
            :
              <StarBorderIcon sx={{m:1.5}}/>
            }
          </Button>
        </Tooltip>
        
      </Grid> 
      <DialogContent >
        {/* CONTENT */}
        <Grid container direction='column'>
          {/* <img src={amazonIcon} alt='Amazon'/> */}
          {/* <DialogContentText>Fill out these fields </DialogContentText> */}
						<Grid container direction='row'  spacing={1} justifyContent='space-between'>
							<Grid container direction='column' item xs={8} sx={{paddingLeft:0, paddingTop:0}} spacing={1} justifyContent='space-between'>
								<Grid item >
									{/*
									<TextField 
										required
										error={emailError}
										
										id="email"
										name="email"
										fullWidth
										label="Email Address"
										helperText={(emailError) && "Email Already exists"}
										defaultValue={person==null ? '' : person.email}
									/>
									*/}
									<PeopleEmailSearch onSelectEmail={handleSelectEmail} onBlur={handleEmailBlur}/>
								</Grid>							
								<Grid item container direction='row' >
									<Grid item xs={6} sx={{paddingRight:'0.5em'}}>
										{emailFound === null ?
										<TextField 
											id="firstName"
											name="firstName"
											label="First Name"
											fullWidth
											defaultValue={person==null ? '' : person.firstName}
											ref={firstNameRef}
											InputLabelProps={{ shrink: person !== null ? person.firstName : false }} 
										/>
										:
										<Typography variant='h2'>{person.firstName}</Typography>
										}
									</Grid>
									<Grid item xs={6}>
										{emailFound === null ?
										<TextField 
											id="lastName"
											name="lastName"
											label="Last Name"
											fullWidth
											defaultValue={person==null ? '' : person.lastName}
											InputLabelProps={{ shrink: person !== null ? person.lastName : false }}
										/>
										:
										<Typography variant='h2'>{person.lastName}</Typography>
										}
									</Grid>
								</Grid>
								<Grid item >
									<TextField 
										id="title"
										name="title"
										label="Title"
										fullWidth
										defaultValue={person==null ? '' : person.title}
										ref={titleRef}
										InputLabelProps={{ shrink: person !== null ? person.title : false }}
									/>
								</Grid>
							</Grid>
							<Grid item xs={4} >
								<FileSelector
									title="Profile Image Picker"
									accept='image/*'
									onSelect={selectImage}
									onDelete={deleteImage}
									dropZoneMessage='Drag an image to upload'
									width={256}
									imageData={imageData}
								>
									<img src={imageData} alt='Selected' width={256}/>
								</FileSelector>
							</Grid>
						</Grid>
          {/* Others */}
					{emailFound === null ?
          <TextField 
            sx={{'width':'100%', 'margin':'0.5em', marginLeft:0}}
            id="tagLine"
            name="tagLine"
            label="Tagline"
            defaultValue={person==null ? '' : person.tagLine}
						InputLabelProps={{ shrink: person !== null ? person.tagLine : false }}
          />
					:
					<Typography><i>{person.tagLine}</i></Typography>
					}
					{emailFound === null ?
          <TextField 
            sx={{'width':'100%', 'margin':'0.5em', marginLeft:0}}
            id="profile"
            name="profile"
            multiline={true}
            minRows={4}
            label="Profile Description"
            defaultValue={person==null ? '' : person.firstName}
						InputLabelProps={{ shrink: person !== null ? person.profile : false }}
          />
					:
					<Typography>{person.profile}</Typography>
					}
        </Grid>
        
        {/* Roles */}
        <Grid container flexDirection={'row'}>
          <Grid item xs container flexDirection={'column'}>
            <h3>Roles</h3>
            {
              /*settings[2]['content']['roles'][0]["Roles"].map(function(role)*/
							allRoles.map((role) => {
                var defaultChecked = false
                // remove admin
                if (role == 'Admin'){
                  return
                }

                // autocheck staff if adding new person
                if (person == null && role=='Staff'){
                  defaultChecked = true
                }

                // See if role is active
                if (person != null && 'roles' in person){
                  if (person.roles.includes(role)){
                    defaultChecked = true
                  }
                }
                return (
                  <FormControlLabel 
                    key={role} 
                    name={role} 
                    id={role} 
										checked={checkedBoxes[role]}
                    control={<Checkbox />} 
                    label={role} 
										onChange={handleRoleChange}
                  />
                )
              })
            }
          </Grid>
          {/* Performance Skills */}
          <PerformanceSkillsSelector selectedPerformanceSkills={performanceSkills} setSelectedPerformanceSkills={setPerformanceSkills}/>

        </Grid>
        {/* Is key person */}
        {/* <h3>Key Person?</h3>
        <FormControlLabel name="isKeyPerson" id="isKeyPerson" control={<Checkbox />} label="Yes" /> */}

        {/* Performance Skillz */}
        {/* <PerformanceSkillsSelector performanceSkills={performanceSkills} setPerformanceSkills={setPerformanceSkills}/> */}
        
      </DialogContent>
      <DialogActions>
        <ButtonGroupBar>
					<ButtonGroupButton sx={{borderRadius:10, p:1}} type="submit" toolTip='Save Person'>
						<CheckRoundedIcon/>
					</ButtonGroupButton>
					<ButtonGroupButton onClick={handleClose} toolTip='Cancel'>
						<CloseRoundedIcon/>
					</ButtonGroupButton>
				</ButtonGroupBar>
      </DialogActions>
    </Dialog>
  )
}

