import React, { useEffect, useState, useContext } from 'react';	
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale'

// material
import { Grid } from '@mui/material';

// project
import CalendarWeek from './CalendarWeek.js';
import { SeasonDesignerContext } from '../SeasonDesignerPage.js';

export default function CalendarMonth ( { 
			events, 
			startDateJS, 
			currentMonth, 
			currentYear,
			onEditEvent,
			onAddEvent,
			startDay=1/* Sunday=0 */} ) {
	const [weeks, setWeeks] = useState(null);
	const [selectedSeason, setSelectedSeason, selectedSeries, setSelectedSeries] = useContext(SeasonDesignerContext);

	const calculateWeeks = () => {
		dayjs.extend(updateLocale);
		dayjs.updateLocale('en', {
			weekStart: 1,
		});

		const theWeeks = [];

		let currentDateJS = startDateJS.startOf('month').startOf('week');
		const endDateJS = startDateJS.endOf('month').endOf('week');

		while (currentDateJS.isBefore(endDateJS)) {
			theWeeks.push(currentDateJS);
			currentDateJS = currentDateJS.add(1, 'week');
		}
		setWeeks(theWeeks);
	}

	useEffect(()=>{
		if (startDateJS !== null) {
			calculateWeeks();
		}
	}, [startDateJS]);

	return (
		<Grid container direction='column' sx={{paddingTop:'1em'}}>
			{weeks !== null && weeks.map((week)=> {
				return (
					<CalendarWeek 
						startDateJS={week} 
						startDay={startDay} 
						events={events}
						key={week.format('YYYYMMDD')}
						currentMonth={currentMonth}
						currentYear={currentYear}
						onEditEvent={onEditEvent}
						onAddEvent={onAddEvent}/>
				)
			})}
		</Grid>
	)
}