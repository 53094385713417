import React, { useEffect, useState, useContext } from 'react';
import dayjs from 'dayjs';

//material
import { Box, Grid, Typography } from '@mui/material';

//project
import { OrganizationContext } from '../../utils/OrganizationContext.js';
import SeasonClient from './SeasonClient.js';
import SeasonWeek from './SeasonWeek.js';
import EventsClient from '../events/EventsClient.js';
import ArtiztaEditComponent from '../../common/ArtiztaEditComponent.js';
import EditEventComponent from '../events/EditEventComponent.js';
import ArtiztaBorderBox from '../../common/ArtiztaBorderBox.js';

export default function SeriesDisplay({events, season, selectedSeries, onSaveEvent, onDeleteEvent}) {
	const boxOffset=325;
	const [seasonWeeks, setSeasonWeeks] = useState(null);
	const [boxHeight, setBoxHeight] = useState(window.innerHeight-boxOffset);
	const [currentOrganization, setCurrentOrganization] = useContext(OrganizationContext);
	const [selectedEditingEvent, setSelectedEditingEvent] = useState(null);

	useEffect(()=>{
		if (season !== null && season !== undefined) {
			const seasonRows = SeasonClient.calculateSeasonWeeks(season, 1); // Monday based schedule
			SeasonClient.placeEventsInSeasonWeeks(seasonRows, events);
			setSeasonWeeks([...seasonRows]);
		}
	},[season, events])

	const updateBoxHeight = () => {
		setBoxHeight(window.innerHeight-boxOffset);
	}

	useEffect(()=>{
		window.addEventListener('resize', updateBoxHeight);
	})

	const handleSaveEvent = (event) => {
		const savedEvent = onSaveEvent(event);

		SeasonClient.placeEventInSeasonWeeks(seasonWeeks, savedEvent);
		setSeasonWeeks([...seasonWeeks]);
		setSelectedEditingEvent(null);
	}

	const handleDeleteEvent = (event) => {
		onDeleteEvent(event);
		SeasonClient.removeEventFromSeasonWeeks(seasonWeeks, event);
		setSeasonWeeks([...seasonWeeks]);
	}

	const DisplaySeasonWeeks = ({ seasonWeeks, onEditDetails }) => {
		return (
			<React.Fragment>
				{seasonWeeks!==null && seasonWeeks.map((week)=>{
					return ( 
						<SeasonWeek 
							week={week} 
							events={events}
							season={season} 
							selectedSeries={selectedSeries} 
							key={week.startDateJS.toString()} 
							onSaveEvent={handleSaveEvent}
							onDeleteEvent={handleDeleteEvent}
							onEditDetails={onEditDetails}/> 
					);
				})}
			</React.Fragment>
		)
	}

	return (
		<ArtiztaBorderBox>
		<Box sx={{maxHeight:boxHeight+'px', overflowY: "auto"}}>
			{selectedEditingEvent !== null && selectedEditingEvent !== undefined ?
				<ArtiztaEditComponent 
					title={`Editing '${selectedEditingEvent.name}'`}
					item={selectedEditingEvent} 
					onSave={handleSaveEvent} 
					onCancel={()=>{setSelectedEditingEvent(null)}}>
					<EditEventComponent />
				</ArtiztaEditComponent>
			:
				<DisplaySeasonWeeks 
					seasonWeeks={seasonWeeks} 
					onEditDetails={(event)=>{
					setSelectedEditingEvent(event)}}/>
			}
		</Box>
		</ArtiztaBorderBox>
	)
}