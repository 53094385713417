// React imports
import React, {useContext, useState} from 'react';

// material
import { Box } from '@mui/material'

// project imports

import FileSelector from '../../../common/FileSelector.js';
import { FirebaseFileClient } from '../../../utils/FirebaseFileClient.js';
import { Typography } from '@mui/material';

export default function FileSelectorDemo(props) {
	// For testing
	const testEvent = {
		"name": "Mahler's First Concerto",
		"series": "Symphony",
		"season": "2023-2024",
		"featuredPerformers":[{
			"performanceSkill": "Violin",
			"name": "Daisuke Yamamoto"
		}],
		"performances": [
			{
				"date": "09-30-2023 20:00",
				"locationID": "gZRo92vAY7yBheyZVAJm",
				"locationName": "The Carpenter Theatre at the Dominion Energy Center",
				"locationsShortAddress": "600 E Grace St, Richmond, VA"
			},
			{
				"date": "10-01-2023 15:00",
				"locationID": "gZRo92vAY7yBheyZVAJm",
				"locationName": "The Carpenter Theatre at the Dominion Energy Center",
				"locationsShortAddress": "600 E Grace St, Richmond, VA"
			}
		],
		"desc": "The new season begins! Experience the sonic splendor of the Richmond Symphony and Valentina Peleggi in Mahler's wild, exuberant First Symphony. Plus, concertmaster Daisuke Yamamoto performs the world premiere of a new Violin Concerto by one of Italy's most acclaimed composers, Andrea Portera.",
		"works": [
			{
				"order": "1",
				"name": "Eudaimonic Concerto",
				"composer": "Andrea Portera"
			},
			{
				"order": "2",
				"name": "Symphony No. 1, D major",
				"composer": "Gustav Mahler",
				"instrumentation": "4[1.2/pic2.3/pic1.4/pic3]  4[1.2.3/Eh.4]  4[1.2.3/bcl/Ebcl2.4/Ebcl1]  3[1.2.3/cbn] — 7  4  3  1 — 2tmp+3 — hp — str perc: bd, bd/cym, cym, sus cym, tri, tamtam",
				"duration": "53"
			}
		]
	}

	const [filename, setFilename] = useState('No File Selected')
	const [imageData, setImageData] = useState(null)
	
	const selectImage = async (theFile, theImageData=null) => {
		if (theImageData != null) {
			console.log("is not null")
			setImageData(theImageData);
			FirebaseFileClient.upload(theFile, 'images', (url) => {
				console.log('should have an image now? '+url);
				setFilename(url);
			});
		}else{
			setImageData(theFile)
			// const response = await fetch( theFile );
			// const image = await response.blob();
			// const arrayBuffer = await image.arrayBuffer()
			// console.log(arrayBuffer)
			// setImageData(arrayBuffer)
			// console.log('imageData', imageData)
		}
	}

	const deleteImage = (url) => {
		setFilename('deleted');
		setImageData(null);
	}

	return (
		<Box>
			Demo for the <b>File Selector</b><br/>
			This demo will upload files to the Firebase storage, please remember to clean up any test uploads.<br/>
			<b>Filename of Uploaded File:</b>: 
			{filename!==null &&
				<Typography>{filename}</Typography>
			}
			<br/>
			<Box maxWidth={300}>
			<FileSelector 
				title='Demo Image Picker' 
				accept='image/*'
				onSelect={selectImage} 
				onDelete={deleteImage}
				dropZoneMessage='Drag an image to upload'
				imageData={imageData}
				imgEvent={testEvent}
				imgStyle={'photorealistic'}
				>
				<img src={imageData} width='100%' alt='Selected'/>
			</FileSelector>
			</Box>
		</Box>
	)
}