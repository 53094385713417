import React, { useState } from "react"

export const OrganizationContext = React.createContext()

export const OrganizationContextProvider = ({ children }) => {
  const [currentOrganization, setCurrentOrganization] = useState(null)


  return (
    <OrganizationContext.Provider value={[currentOrganization, setCurrentOrganization]}>
      {children}
    </OrganizationContext.Provider>
  )
}