import React, { useContext, useState } from "react";
import dayjs from "dayjs";

// material
import { Grid,Typography, Avatar, Divider, TextField } from "@mui/material";
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

// project imports
import CommentsClient from "../utils/CommentsClient"
import { AuthContext } from '../utils/AuthContext';
import { OrganizationContext } from "../utils/OrganizationContext";
import { useEffect } from "react";
import ArtiztaEditBox from "./ArtiztaEditBox";
import ButtonGroupBar from "./ButtonGroupBar";
import ButtonGroupButton from "./ButtonGroupButton";
import ArtiztaBorderBox from "./ArtiztaBorderBox";
import PeopleClient from "../pages/people/PeopleClient";
import { spotlightColor, curtainColor, shadowColor, stageColor, nightColor } from "./SymphonEaseColors.js";

export default function CommentsDisplay ( { item, type, newCommentLabel='Add a comment' }) {
	const [currentOrganization, setCurrentOrganization] = useContext(OrganizationContext);
	const [currentUser, setCurrentUser] = useContext(AuthContext);
	const [comments, setComments] = useState([]);
	const [editingComment, setEditingComment] = useState(null);
	const [isEditing, setIsEditing] = useState(false);
	const [people, setPeople] = useState([]);

	const fetchEventComments = async () => {
		const theComments = await CommentsClient.get(currentOrganization, currentUser.person, type, item);
		setComments(theComments);
	}

	const fetchPeopleForComments = async () => {
		
		if (comments !== null && comments !== undefined) {
			for (var i=0; i<comments.length; i++) {
				const comment = comments[i];
				// fetch person for comment
				var thePerson=people.find(person => person.id === comment.personID);
				if (thePerson === null || thePerson === undefined) {
					// fetch person
					thePerson = await PeopleClient.get(comment.personID);
					// add person to people
					people.push(thePerson);
				}
				if (thePerson.profileImage !== null && thePerson.profileImage !== undefined && thePerson.profileImage !== "No File Selected"){
					comment.profileImage = thePerson.profileImage;
				}
			}
		}
	}

	useEffect(() => {
		fetchEventComments();
		setIsEditing(false);
	}, [item]);

	useEffect(() => {
		fetchPeopleForComments();
	}, [comments]);

	const handleSaveComment = async () => {
		const newComment = await CommentsClient.post(currentOrganization, currentUser.person, type, item, editingComment);
		setIsEditing(false);
		fetchEventComments();
	}

	const CommentDisplay = ({comment}) => {
		const [avatarLetter, setAvatarLetter] = useState(null);

		const [avatarSX, setAvatarSX] = useState({height:24, width:24});

		const avatarColors = [
			spotlightColor[20],
			curtainColor[40],
			curtainColor[50],
			shadowColor[20],
			shadowColor[30],
			stageColor[40],
			stageColor[50],
			nightColor[20],
			nightColor[30]
		]

		useEffect(() => {
			const theLetter = comment.createdBy.charAt(comment.createdBy.length-2).toUpperCase();
			const pct = (theLetter.charCodeAt(0) - ('A').charCodeAt(0))/26.0;
			const colorIndex = Math.floor(pct*avatarColors.length);

			setAvatarSX({...avatarSX, ...{bgcolor: avatarColors[colorIndex]}});
			setAvatarLetter(theLetter);
		}, [comment]);

		return (
			<React.Fragment>
			{comment.personID === currentUser.person.id ?
				<Grid item container direction='row' justifyContent='flex-end' sx={{marginBottom:'0.25em'}}>
					<Grid item xs={7} container direction='row' justifyContent='flex-end' alignContent='center'>
						<Typography><i>"{comment.comment}"</i></Typography>
						<Typography sx={{paddingLeft:'0.1em'}}><b>You</b>, {dayjs(comment.createdDate).format('DD-MMM')}</Typography>
					</Grid>
					<Grid item sx={{paddingLeft:'0.5em'}}>
						{comment.profileImage !== null && comment.profileImage !== undefined ?
							<Avatar sx={avatarSX} src={comment.profileImage} alt={comment.createdBy}/>
							:
							<Avatar sx={avatarSX} >{comment.createdBy.charAt(comment.createdBy.length-2)}</Avatar>
						}
					</Grid>
				</Grid>
			:
			<Grid item container direction='row' justifyContent='flex-start' alignContent='center' sx={{marginBottom:'0.25em'}}>
				<Grid item sx={{paddingRight:'0.5em'}}>
					{comment.profileImage !== null && comment.profileImage !== undefined ?
						<Avatar sx={avatarSX} src={comment.profileImage} alt={comment.createdBy}/>
						:
						<Avatar sx={avatarSX} >{comment.createdBy.charAt(comment.createdBy.length-2)}</Avatar>
					}
				</Grid>
				<Grid item xs={7} container direction='row' justifyContent='flex-start' alignContent='center'>
					<Typography sx={{paddingRight:'0.1em'}}><b>{comment.createdBy}</b>, {dayjs(comment.createdDate).format('DD-MMM')}</Typography>
					<Typography><i>"{comment.comment}"</i></Typography>
				</Grid>
			</Grid>
			}
				
			</React.Fragment>
		)
	}

	return (
		<ArtiztaBorderBox>
			<Grid container direction='column'>
				<Grid item>
					<Typography variant='h6'>Comments</Typography>
				</Grid>
				{/* No comments */}
				{comments !== null && comments !== undefined && comments.length === 0 &&
					<Grid item xs={12} container  direction='row' justifyContent={'center'}>
						<Grid item>
							<Typography><i>No comments for this performance</i></Typography>
						</Grid>
					</Grid>
				}

				{/* Comments */}
				{comments !== null && comments !== undefined && comments.map(comment => {
					return (
						<CommentDisplay comment={comment} key={comment.id}/>
				)})}
				{/* Add comment */}
				{!isEditing ?
					<Grid item>
						<ArtiztaEditBox message={newCommentLabel} onEdit={()=>setIsEditing(true)}/>
					</Grid>
					:
					<Grid item container direction='row'>
						<Grid item xs={11}>
							<TextField
								autoFocus
								InputProps={{
									sx: {
										height: '100%',
										alignItems: 'start'
									}}}
								id="ec-comment"
								name="ec-comment"
								label={newCommentLabel}
								minRows={4}
								fullWidth
								multiline
								onChange={(e)=> setEditingComment(e.target.value)}
							/>
						</Grid>
						<Grid item xs={1}>
							<ButtonGroupBar variant='outline' size='small'>
								<ButtonGroupButton toolTip={`Save this comment`} placement='left-end' onClick={handleSaveComment}>
									<CheckRoundedIcon/>
								</ButtonGroupButton>
								<ButtonGroupButton toolTip={`Cancel editing this comment`} placement='left-end' onClick={()=>setIsEditing(false)}>
									<CloseRoundedIcon/>
								</ButtonGroupButton>
							</ButtonGroupBar>	
						</Grid>
					</Grid>
				}

			</Grid>
		</ArtiztaBorderBox>	)
}