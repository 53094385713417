// React imports
import React, {useState, useEffect, useContext} from 'react';

// Material Imports
import { TextField, Grid, Box } from '@mui/material';
import FileCopyRoundedIcon from '@mui/icons-material/FileCopyRounded';

// project imports
import WorkDanielsOnlineDisplay from './WorkDanielsOnlineDisplay.js';
import SpotifyButton from '../../../common/SpotifyButton.js';
import { ItemContext } from '../../../common/ArtiztaEditComponent';
import ArtiztaIconButton from '../../../common/ArtiztaIconButton.js';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import DanielsOnlineSearchComponent from './DanielsOnlineSearchComponent.js';

/**
 * Displays a Component to edit a Work
 */

export default function EditWorkComponent({ series=null }) {
	const defaultDanielsTooltip = 'Enter two letters of both the title and composer to search for this work on Daniels Online';
  const [editingItem, setEditingItem] = useContext(ItemContext);
	const [spotifySearchString, setSpotifySearchString] = useState(null);
	const [danielsOnlineResults, setDanielsOnlineResults] = useState(null);

	const handleWorkSelected = (work) => {
		setEditingItem({...editingItem, ...work});
	}

	const handleCopyStringsInstrumentation = () => {
		setEditingItem({...editingItem, ...{stringsInstrumentation: series.stringsInstrumentation}});
		document.getElementById('ew-stringsInstrumentation').value = series.stringsInstrumentation;
	}

	useEffect(()=>{
		if (editingItem !== null && editingItem !== undefined ) {
			if ( editingItem.title !== null && editingItem.title !== undefined && editingItem.title !== ''
				&& editingItem.composer !== null && editingItem.composer !== undefined && editingItem.composer !== '') {
				setSpotifySearchString(`${editingItem.title} - ${editingItem.composer}`);
			}
		}

	},[editingItem, editingItem.title, editingItem.composer]);

	return (
		<Box sx={{width:'100%'}}>
				<Grid container direction='column' id='ew-top-grid' >
					<Grid container direction='row' id='ew-daniels-searchbar-grid' justifyContent='space-between' alignItems='center' spacing={1}>
						<Grid item xs={6}>
							<TextField
								sx={{marginTop:1}}
								id="ew-title"
								name="ew-title"
								label="Work Title"
								value={editingItem.title}
								fullWidth
								InputLabelProps={{ shrink: true }}
								defaultValue={editingItem.title}
								onChange={(e) => setEditingItem({...editingItem, ...{title: e.target.value}})}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								sx={{marginTop:1}}
								id="ew-composer"
								name="ew-composer"
								label="Composer"
								value={editingItem.composer}
								fullWidth
								InputLabelProps={{ shrink: true }}
								defaultValue={editingItem.composer}
								onChange={(e) => setEditingItem({...editingItem, ...{composer: e.target.value}})}
							/>
						</Grid>
					</Grid>
					{spotifySearchString !== null &&
						<Box sx={{marginTop:'0.2em'}}>
							<SpotifyButton searchString={spotifySearchString}/>
						</Box>
					}
					<Grid item xs={12}>
						<DanielsOnlineSearchComponent 
							workTitle={editingItem.title} 
							composerName={editingItem.composer} 
							onWorkSelected={handleWorkSelected}/>
					</Grid>
					<Box id='ew-manual-entry-box'>
						<TextField
							sx={{marginTop:1}}
							id="ew-duration"
							name="ew-duration"
							label="Estimated Duration in Minutes"
							fullWidth
							InputLabelProps={{ shrink: true }}
							defaultValue={editingItem.duration}
							value={editingItem.duration}
							onChange={(e) => setEditingItem({...editingItem, ...{duration: e.target.value}})}
						/>
						<TextField
							sx={{marginTop:1}}
							id="ew-instrumentation"
							name="ew-instrumentation"
							label="Instrumentation"
							fullWidth
							InputLabelProps={{ shrink: true }}
							value={editingItem.instrumentation}
							defaultValue={editingItem.instrumentation}
							onChange={(e) => setEditingItem({...editingItem, ...{instrumentation: e.target.value}})}
						/>
						{/* String Instrumentation */}
						<Grid container direction='row' alignItems='center' justifyContent='space-between' spacing={1}>
							<Grid item xs={11.5}>
								<TextField
									sx={{marginTop:1}}
									id="ew-stringsInstrumentation"
									name="ew-stringsInstrumentation"
									label="Strings Instrumentation"
									fullWidth
									InputLabelProps={{ shrink: true }}
									value={editingItem.stringsInstrumentation}
									defaultChecked={editingItem.stringsInstrumentation}
									onChange={(e) => setEditingItem({...editingItem, ...{stringsInstrumentation: e.target.value}})}
								/>
							</Grid>
							<Grid item xs={0.5}>
								<ArtiztaIconButton
									disabled={series===null || series.stringsInstrumentation===null || series.stringsInstrumentation===undefined}
									onClick={handleCopyStringsInstrumentation}
									toolTip={series===null ? 
										'This Work is not associated with a Series, cannot copy Strings Instrumentation' :
										`Copy the Strings Instrumentation from the ${series.name} Series to this Work`}>
									<FileCopyRoundedIcon/>
								</ArtiztaIconButton>
							</Grid>
						</Grid>
						<TextField
							sx={{marginTop:1}}
							id="ew-percussionInfo"
							name="ew-percussionInfo"
							label="Percussion Information"
							value={editingItem.percussionInfo}
							fullWidth
							InputLabelProps={{ shrink: true }}
							defaultChecked={editingItem.percussionInfo}
							onChange={(e) => setEditingItem({...editingItem, ...{percussionInfo: e.target.value}})}
						/>
						<TextField
							sx={{marginTop: '1em', height: '100%', display:'flex', flexGrow:1}}
							InputProps={{
								sx: {
									height: '100%',
									alignItems: 'start'
								}}}
							id="ew-desc"
							name="ew-desc"
							label="Description"
							rows={10}
							fullWidth
							multiline
							defaultValue={editingItem===null || editingItem===undefined ? '' : editingItem.desc}
							onChange={(e)=> setEditingItem({...editingItem, ...{desc:e.target.value}})}
							InputLabelProps={{ shrink: true}}
						/>
					</Box>
	
				</Grid>
		</Box>
	)
}