// React imports
import React, {useContext, useState, useEffect} from 'react';
import { Link } from 'react-router-dom';

// material
import { Box, Grid, AppBar, Toolbar, Typography } from '@mui/material';

// project imports
import { AuthContext } from '../../utils/AuthContext';
import lightSymphonEaseLogo06 from '../../assets/SymphonEase_Logo06_Light.svg';
import minimalistPerformanceWide from '../../assets/marketing/minimalistPerformanceWide.png';
import scribledNotesCalendarWide from '../../assets/marketing/scribledNotesCalendarWide.png';
import fullStageSymphonyWide from '../../assets/marketing/fullStageSymphonyWide.png';
import oldSchoolLibraryWide from '../../assets/marketing/oldSchoolLibraryWide.png';
import violinOnTableWide from '../../assets/marketing/violinOnTableWide.png';
import symphyWide from '../../assets/marketing/symphyWide.png';
import symphonEaseNotes from '../../assets/SymphonEase_Notes.svg';
import LoginWindow from '../login/LoginWindow';

export default function MarketingPage(props) {
	// Context

	useEffect(() => { 
		document.body.style.backgroundColor = 'black';
		document.body.style.margin = 0; 
		document.body.style.padding = 0; 
	}, [])

	const titleFontSize = 42;
	const taglineFontSize = 28;	
	const bodyFontSize=16;
	const smallFontSize=12;
	const overlapWidth='55%';
	const standardPadding='2em';

	// UI
	return (
		<React.Fragment>
			<AppBar position="fixed" sx={{bgcolor:'black'}}>
				<Toolbar>
					<Grid container direction={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{paddingBottom:'1em', paddingTop:'2em'}}>
						<img src={lightSymphonEaseLogo06} alt="SymphonEase" height={48}/>
						<img src={symphonEaseNotes} alt="Notes Logo" height={48}/>
					</Grid>
				</Toolbar>
			</AppBar>
					{/* Page Content */}
					<Box sx={{paddingTop:'4em'}}>
						{/* The Images */}
						{/* Minimalist Performance */}
						<Box style={{position:'relative'}}>
							<img src={minimalistPerformanceWide}  alt='Performance' width='100%' id={minimalistPerformanceWide+'-img'} />
							<Box sx={{position:'absolute', top:'0%'}}>
								<Grid container direction='row' justifyContent='flex-start'>
									<Box sx={{textAlign:'left', width:overlapWidth, paddingLeft: standardPadding}}>
										<Typography fontFamily='Prompt' fontSize={titleFontSize} sx={{color:'white'}}>From Score to Stage</Typography>
										<p/>
										<Typography fontFamily='Prompt' fontSize={taglineFontSize} sx={{color:'white'}}>
											Transforming Orchestra Management Through Innovation and Simplicity
										</Typography>

										<p/>
										{/* Center the Login Window */}
										<Grid container direction='row' justifyContent='flex-start'>
											<Grid item sx={{paddingLeft:'4em', paddingBottom:'2em', paddingTop:'2em'}}>
												<LoginWindow/>
											</Grid>
										</Grid>
										<p/>
										<Typography fontFamily='Montserrat' fontSize={bodyFontSize} sx={{color:'white'}}>
										<b>SymphonEase</b> is designed to strike this delicate balance between logistics and artistry, offering an intuitive, all-in-one platform that empowers orchestras to flourish both behind the scenes and in the spotlight.
										</Typography>
									</Box>
								</Grid>
							</Box>
						</Box>

						{/* Season Designer */}
						<Box style={{position:'relative'}}>
							<img src={scribledNotesCalendarWide} alt='Calendar' width='100%' id={scribledNotesCalendarWide+'-img'} />
							<Box sx={{position:'absolute', top:'0%'}}>
								<Grid container direction='row' justifyContent='flex-end'>
									<Box sx={{textAlign:'right', width:overlapWidth, paddingRight:standardPadding}}>
										<Typography fontFamily='Prompt' fontSize={titleFontSize} sx={{color:'white'}}>Craft your Season with Brilliance</Typography>
										<p/>
										<Typography fontFamily='Prompt' fontSize={taglineFontSize} sx={{color:'white'}}>
											Orchestrate a Seamless and Impactful Season with Precision
										</Typography>
										<p/>
										<Typography fontFamily='Montserrat' fontSize={bodyFontSize} sx={{color:'white'}}>
										Unlock the full potential of your orchestra's annual repertoire with SymphonEase's <b>Season Designer</b>. This sophisticated tool blends powerful technology with an intuitive interface, empowering you to design not just a single season but multiple concert series tailored to diverse audience segments.  
										</Typography>
									</Box>
								</Grid>
							</Box>
						</Box>

						{/* Perfecting Performances */}
						<Box style={{position:'relative'}}>
							<img src={fullStageSymphonyWide} alt='Performance' width='100%' id={fullStageSymphonyWide+'-img'} />
							<Box sx={{position:'absolute', top:'0%', textAlign:'left', width:overlapWidth, paddingLeft:standardPadding}}>
								<Typography fontFamily='Prompt' fontSize={titleFontSize} sx={{color:'white'}}>Perfect Every Performance</Typography>
								<p/>
								<Typography fontFamily='Prompt' fontSize={taglineFontSize} sx={{color:'white'}}>
									Effortlessly Coordinate Every Element of Your Orchestra's Events
								</Typography>
								<p/>
								<Typography fontFamily='Montserrat' fontSize={bodyFontSize} sx={{color:'white'}}>
								Navigating the complexities of organizing orchestra events demands precision and attention to detail - qualities embodied by SymphonEase's advanced <b>Event Management</b> module. Designed to harmonize the myriad elements that contribute to successful performances and rehearsals, this feature ensures nothing is left to chance. From initial planning to final applause, SymphonEase empowers you to manage events with unparalleled ease and accuracy, transforming potential chaos into orchestrated success.
								</Typography>
							</Box>
						</Box>

						{/* Mastering your Library */}
						<Box style={{position:'relative'}}>
							<img src={oldSchoolLibraryWide} alt='Library' width='100%' id={oldSchoolLibraryWide+'-img'} />
							<Box sx={{position:'absolute', top:'0%'}}>
								<Grid container direction='row' justifyContent='flex-end'>
									<Box sx={{textAlign:'right', width:overlapWidth, paddingRight:standardPadding}}>
										<Typography fontFamily='Prompt' fontSize={titleFontSize} sx={{color:'white'}}>Master Your Music Library</Typography>
										<p/>
										<Typography fontFamily='Prompt' fontSize={taglineFontSize} sx={{color:'white'}}>
											Quickly Organize and Access Your Orchestra's Repertoire
										</Typography>
										<p/>
										<Typography fontFamily='Montserrat' fontSize={bodyFontSize} sx={{color:'white'}}>
										SymphonEase's Library Management feature revolutionizes the way orchestras handle their sheet music collections, making it simpler and more efficient than ever to manage, access, and utilize your musical assets. Designed with the needs of modern orchestras in mind, our tool ensures that your music library is not only organized but also fully integrated with your overall season planning and event management.
										</Typography>
									</Box>
								</Grid>
							</Box>
						</Box>

						{/* Perfecting Performances */}
						<Box style={{position:'relative'}}>
							<img src={symphyWide} alt='Symphy' width='100%' id={symphyWide+'-img'} />
							<Box sx={{position:'absolute', top:'0%', textAlign:'left', width:overlapWidth, paddingLeft:standardPadding}}>
								<Typography fontFamily='Prompt' fontSize={titleFontSize} sx={{color:'white'}}>Orchestrating Excellence with AI</Typography>
								<p/>
								<Typography fontFamily='Prompt' fontSize={taglineFontSize} sx={{color:'white'}}>
										Enhance Every Aspect of Orchestra Management with Intelligent Technology
								</Typography>
								<p/>
								<Typography fontFamily='Montserrat' fontSize={bodyFontSize} sx={{color:'white'}}>
								At SymphonEase, we integrate advanced AI technology throughout our entire platform to revolutionize how orchestras are managed. From planning seasons to managing your music library, AI is embedded in every feature, ensuring that every decision is supported by powerful, data-driven insights. This comprehensive use of AI not only simplifies management tasks but also elevates the artistic and operational capabilities of your orchestra.
								</Typography>
							</Box>
						</Box>

						{/* <img src={violinOnTableWide} alt='ViolinOnTable' width='100%' id={violinOnTableWide+'-img'} /> */}

						{/* Bottom Text */}
						<Box sx={{textAlign:'center', width:'100%', paddingLeft:'10em', paddingRight:'10em', paddingTop:'2em', paddingBottom:'0em'}}>
							<Typography fontFamily='Prompt' fontSize={titleFontSize} sx={{color:'white'}}>Ready to Elevate Your Orchestra?</Typography>
							<p/>
							<Typography fontFamily='Prompt' fontSize={taglineFontSize} sx={{color:'white'}}>
							Streamline operations from Score to Stage
							</Typography>

							<p/>
							{/* Center the Login Window */}
							<Grid container direction='row' justifyContent='center' alignItems='center' >
								<Grid item sx={{paddingLeft:'4em', paddingBottom:'2em', paddingTop:'2em'}}>
									<LoginWindow/>
								</Grid>
								<Grid item sx={{paddingLeft:'2em'}}>
									<img src={symphonEaseNotes} alt="company logo" height={36}/>
								</Grid>
							</Grid>
							<p/>
							<Typography fontFamily='Montserrat' fontSize={bodyFontSize} sx={{color:'white'}}>
							Sign up today and step into a new era of orchestra management. Explore how SymphonEase can make your day-to-day operations more efficient and your artistic decisions more impactful. Start your journey towards an effortlessly managed season that captivates and delights. Join us and transform your orchestra's future.
							</Typography>

							{/* Copyright */}
							<Grid container direction='row' justifyContent='center' justifyItems='center' sx={{paddingTop:'2em'}} >
								<Grid item>
									<img src={lightSymphonEaseLogo06} alt="SymphonEase" height={14}/>
								</Grid>
								{/* <Grid item sx={{paddingLeft:'2em'}}>
									<img src={symphonEaseNotes} alt="company logo" height={18}/>
								</Grid> */}
								<Grid item sx={{paddingLeft:'2em'}}>
									<Typography fontFamily='Montserrat' fontSize={smallFontSize} sx={{color:'white'}}>© 2024 coderPalooza, LLC</Typography>	
								</Grid>
							</Grid>
						</Box>
					</Box>
		</React.Fragment>
	)
}