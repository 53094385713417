import React, { useContext, useEffect, useState } from 'react';

// material imports
import { TextField, Grid, Button, Tooltip, CircularProgress } from '@mui/material';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';


// project imports
import ArtiztaBorderBox from '../../common/ArtiztaBorderBox';
import PerformanceDateTimeEdit from '../events/eventPerformance/PerformanceDateTimeEdit.js';
import ButtonGroupBar from '../../common/ButtonGroupBar.js';
import ButtonGroupButton from '../../common/ButtonGroupButton.js';
import RecommendationClient from '../../utils/RecommendationsClient.js';
import { AuthContext } from '../../utils/AuthContext.js';
import artiztaConfetti from '../../utils/ArtiztaConfetti.js';

export default function EventQuickEdit ({event, onSave, onCancel, onEditDetails}) {
	const [currentUser] = useContext(AuthContext)
	const [editingEvent, setEditingEvent] = useState(null);
	const [performanceDate, setPerformanceDate] = useState(null);
	const [saveEnabled, setSaveEnabled] = useState(false);

	const handleDateChange = (e) => {
		if (event.performances === null || event.performances === undefined) {
			event.performances = [];
		}
		const newDate = e.target.value;
	}

	const onEditEvent = () => {
		if (onEditDetails !== null && onEditDetails !== undefined) {
			onEditDetails(editingEvent);
		}
	}

	const handleCancel = () => {
		onCancel(event);
	}

	const handleSaveEvent = (e) => {
		event.performances[0].date = performanceDate;
		if (editingEvent.id === null || editingEvent.id === undefined) {
			artiztaConfetti(e, 'stars');
		}
		onSave(editingEvent);
	}

	const enableSaveIfChanged = () => {
		if (JSON.stringify(editingEvent) === JSON.stringify(event)) {
			setSaveEnabled(false);
		}
		else {
			setSaveEnabled(true);
		}
	}

	useEffect(() => {
		if (event !== null && event !== undefined) {
			const clone = structuredClone(event);
			setEditingEvent(clone);
		}

	}, [event]);

	useEffect(() => {
		if (editingEvent !== null) {
			setPerformanceDate(editingEvent.performances[0].date);
			enableSaveIfChanged();
		}
	}, [editingEvent]);

	useEffect(() => {
		if (editingEvent !== null && editingEvent !== undefined) {
			editingEvent.performances[0].date = performanceDate;
			setEditingEvent({...editingEvent});
		}
		enableSaveIfChanged();
	}, [performanceDate]);

	// Description Generation
	const [savedText, setSavedText] = useState(null)
	const [ showDescriptionLike, setShowDescriptionLike ] = useState(false)
	const [ descriptionLoading, setDescriptionLoading ] = useState(false)

	// Symphy's Recommendations
	const recommendDescription = () => {
		setDescriptionLoading(true)
		RecommendationClient.getDescriptionRecommendation(currentUser.person.settings.openAiAPIKey, editingEvent)
			.then(json => {
				setShowDescriptionLike(true)
				if (editingEvent.desc !== undefined || editingEvent.desc !== null) {
					setSavedText(editingEvent.desc)
				}
				setEditingEvent({...editingEvent, ...{desc:json}})
				document.getElementById('ew-desc').value = json
				setDescriptionLoading(false)
			})
	}
	const loveIt = () => {
		setShowDescriptionLike(false)
	}

	const hateIt = () => {
		if(savedText != null){
			setEditingEvent({...editingEvent, ...{desc:savedText}})
			document.getElementById('ew-desc').value = savedText
		}else{
			setEditingEvent({...editingEvent, ...{desc:""}})
			document.getElementById('ew-desc').value = ""
		}
		setShowDescriptionLike(false)
	}


	return (
		<Grid container direction='column' sx={{paddingRight:'0.5em'}} spacing={1}>
			{/* Name, Date, start time, buttons */}
			<Grid item container direction='row' justifyContent='space-between' spacing='0.5em'>
				<Grid item xs={3} xl={4.5}>
					<TextField
						id="eq-name"
						name="eq-name"
						label="Event Name"
						defaultValue={event.name}
						onKeyDown={(e) => (
							e.keyCode === 13 ? handleSaveEvent() : null
						)}
						fullWidth
						onChange={(e) => {
							setEditingEvent({...editingEvent, name:e.target.value})
							enableSaveIfChanged();
						}}
					/>
				</Grid>
				<Grid item xs={7} xl={5.5}>
					<PerformanceDateTimeEdit
						performanceDate={performanceDate}
						setPerformanceDate={setPerformanceDate}
					/>
				</Grid>
				<Grid item xs={2} xl={2}>
					<ButtonGroupBar variant='outline' size='small'>
						<ButtonGroupButton toolTip='Edit Event Details' placement='left' onClick={onEditEvent} >
							<EditRoundedIcon/>
						</ButtonGroupButton>
						<ButtonGroupButton toolTip='Save Event' placement='bottom' onClick={handleSaveEvent} disabled={!saveEnabled}>
							<CheckRoundedIcon/>
						</ButtonGroupButton>
						<ButtonGroupButton toolTip='Cancel Editing' placement='bottom' onClick={handleCancel}>
							<CloseRoundedIcon/>
						</ButtonGroupButton>
					</ButtonGroupBar>	
				</Grid>
			</Grid>
			
			{/* Description & AI */}
			<Grid item container direction='row'>
				{/* Description */}
				<Grid item xs={10}>
					<TextField
						sx={{marginTop:0}}
						id="ew-desc"
						name="ew-desc"
						label="Description"
						defaultValue={event.desc}
						multiline
						minRows={2}
						fullWidth
						InputLabelProps={{shrink: true}}
						onChange={(e) => {
							setEditingEvent({...editingEvent, ...{desc: e.target.value}})
							enableSaveIfChanged();
						}}
					/>
				</Grid>

				{/* AI */}
				<Grid item xs={2} container alignItems={'center'} justifyContent={'flex-end'}>
					{(descriptionLoading)&& <CircularProgress />}
					<ButtonGroupBar variant='outline' size='small'>
						<ButtonGroupButton toolTip="Generate Description" disabled={descriptionLoading} sx={{m:1, p:1}} onClick={recommendDescription}>
							<SmartToyIcon />
						</ButtonGroupButton>
						<ButtonGroupButton disabled={!showDescriptionLike} toolTip="Love it!" onClick={loveIt}>
							<ThumbUpIcon />
						</ButtonGroupButton>
						<ButtonGroupButton disabled={!showDescriptionLike} toolTip="Hate it..." onClick={hateIt}>
							<ThumbDownIcon />
						</ButtonGroupButton>
					</ButtonGroupBar>
				</Grid>
			</Grid>
		</Grid>
	)
}
