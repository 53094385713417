import dayjs from 'dayjs';

// project imports
import APIClientManager from '../../utils/APIClientManager';

/*
	OrganizationsAPI.js - encapsulation of the calls to api/organizations
		searchForName(searchString) - given a string, searches for all organizations with that name. Expect an array to be returned
		getOrganization(id) - given an ID, go get the organization. Only one record should be returned
		putOrganization(organization) - call the PUT (edit existing) for an organization
		deleteOrganization(organization) - call DELETE
*/

export default class OrganizationsClient {

	static async addSeason(organization, season) {
		const seasons = organization.seasons;
		if (seasons===null || seasons===undefined) {
			organization.seasons=[];
		}
		organization.seasons.push(season);
		return await OrganizationsClient.updateOrganizationSeasons(organization);
	}

	static async updateSeason(organization, season) {
		// assumes the season exists, find by name
		const seasons = organization.seasons;
		for (var i=0; i<seasons.length; i++) {
			if (seasons[i].name===season.name) {
				organization.seasons[i]=season;
				break;
			}
		}
		return await OrganizationsClient.updateOrganizationSeasons(organization);
	}

	static async addSeriesToSeason(organization, season, series) {
		// assumes the season exists, find by name
		for (var i=0; i<organization.seasons.length; i++) {
			if (organization.seasons[i].name===season.name) {
				if (organization.seasons[i].series===null || organization.seasons[i].series===undefined) {
					organization.seasons[i].series=[];
				}
				organization.seasons[i].series.push(series);
				break;
			}
		}
		return await OrganizationsClient.updateOrganizationSeasons(organization);
	}

	static async updateSeriesInSeason(organization, season, series) {
		// assumes the season exists, find by name
		const seasons = organization.seasons;
		for (var i=0; i<seasons.length; i++) {
			if (seasons[i].name===season.name) {
				const seriesList = organization.seasons[i].series;
				for (var j=0; j<seriesList.length; j++) {
					if (seriesList[j].name===series.name) {
						organization.seasons[i].series[j]=series;
						break;
					}
				}
				break;
			}
		}
		return await OrganizationsClient.updateOrganizationSeasons(organization);
	}

	static async updateOrganizationSeasons(organization) {
		// remove everything except the seasons and id
		const newOrgUpdate = {id: organization.id, seasons: organization.seasons};
		return await OrganizationsClient.updateOrganization(newOrgUpdate);
	}

	static async updateOrganization(organization) {
		const url = `organizations/${organization.id}`;
		const response = await APIClientManager.put(url, organization);
		const json = await response.json();
		return json;
	}

	static async searchForName(searchString) {
		const response = await APIClientManager.get('organizations', {search: searchString});
		const json = await response.json();
		return json;
	}

	static async getOrganization(obj) {
		var id=obj;
		if (typeof obj === 'object') {
			id=obj.id;
		}
		// TO DO
		const response = await APIClientManager.get('organizations/'+id);
		const json = await response.json();
		return json;
	}

	/**
	 * set a person in an organization. If the person is not present, adds them. 
	 * @param {{}} organization expects an organization as a dictionary with valid id
	 * @param {{}} orgPerson expects an orgPerson as a dictionary 
	 * @returns {{}} json representing the organization. does not return the person.
	 */
	static setPersonInOrganization = async (organization, orgPerson) => {

		const url = `organizations/${organization.id}/person/`;
		const response = await APIClientManager.put(url, orgPerson);
		const json = await response.json();
		return json;
	}

	/**
	 * deletes a person in an organization. If the person is not present, adds them. 
	 * @param {{}} organization expects an organization as a dictionary with valid id
	 * @param {{}} person expects a person as a dictionary with a valid id
	 * @returns {{}} json representing the organization. The associated person will not be returned.
	 */
	static removePersonFromOrganization = async (organization, person) => {
		const url = `organizations/${organization.id}/person/${person.id}`;
		const response = await APIClientManager.delete(url);
		const json = await response.json();
		return json;
	}

	static addOrganization(organization) {
		// TO DO

	}

	static autoCalculateNextSeason = (organization, startMonth=8) => {
		let newSeason={};
		// if no seasons exist, start a new one from the new month, defaults to August
		const today = dayjs(new Date());
		let startYear = today.year()+1;
		const thisMonth = today.month();

		let startDayJS=today;

		if (organization.seasons===null || organization.seasons===undefined) {
			if (thisMonth>startMonth) {
				startYear=startYear+1;
			}
			const monthStr = `${startYear}-${startMonth}-1`;
			newSeason.startDate =  new Date(monthStr);
			startDayJS = dayjs(newSeason.startDate);
		}
		else {
			// have some season(s)
			const seasons = organization.seasons;
			// find the latest year
			let lastYear=0;
			for (let i=0; i<seasons.length; i++) {
				const endDate = dayjs(seasons[i].endDate);

				if (endDate.year()>lastYear) {
					lastYear=endDate.year();
					startDayJS=endDate.add(1,'day');
				}
			}
			startYear=lastYear;
		}

		newSeason.name=`${startYear}-${startYear+1}`;
		const endDayJS = startDayJS.add(1,'year').subtract(1,'month').endOf('month');
		newSeason.startDate=startDayJS.toDate();
		newSeason.endDate = endDayJS.toDate();

		return newSeason;
	}

	static calculateSeriesForNewSeason = (organization) => {
		// find the latest season by end date
		let lastSeason=null;
		for (let i=0; i<organization.seasons.length; i++) {
			if (lastSeason===null) {
				lastSeason=organization.seasons[i];
			}
			else {
				if (organization.seasons[i].endDate > lastSeason.endDate) {
					lastSeason=organization.seasons[i];
				}
			}
		}
		return lastSeason.series;
	}

	static getSeasonForEvent = ( organization, event ) => {
		const seasonName = event.season;
		if (seasonName === null || seasonName === undefined) {
			return null;
		}
		if (organization.seasons === null || organization.seasons === undefined) {
			return null;
		}
		const season = organization.seasons.find((s)=>s.name===seasonName);

		return season;

	}
	static getSeriesForEvent = ( organization, event ) => {
		const season = OrganizationsClient.getSeasonForEvent(organization, event);
		if (season === null) {
			return null;
		}
		const seriesName = event.series;
		if (seriesName === null || seriesName === undefined) {
			return null;
		}
		const series = season.series.find((s)=>s.name===seriesName);
		return series;
	}

	// Takes in list of roles + org & auth contexts 
	// Outputs true or false 
	static canView = (roles, currentOrganization, currentUser) => {
		// If Global Admin, let them pass
    if(currentUser.person.isGlobalAdmin) {
      return true
    }

    // Try to find the person in the org
		if (currentOrganization !== undefined && currentOrganization !== null && currentOrganization.people !== undefined && currentOrganization.people !== null){
			const orgPerson = currentOrganization.people[currentOrganization.people.findIndex(x => x.id === currentUser.person.id)]
			if (orgPerson !== undefined && orgPerson !== null){
				// If any role matches, let em pass
				for (var r in roles){
					if (orgPerson.roles.includes(r)) {
						return true
					}
				}
			}
		}

		// else, return false
		return false
	}

}