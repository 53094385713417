// React imports
import React, {useContext, useState, useEffect} from 'react';

// material
import { Box, Grid, Typography, Divider, ListItemButton } from '@mui/material';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';

// project imports
import {AuthContext} from '../../utils/AuthContext.js';
import OrganizationChange from '../organizations/components/OrganizationChange.js';
import { OrganizationContext } from '../../utils/OrganizationContext.js';
import SeriesChip from './SeriesChip.js';
import EventDisplay from './EventDisplay.js';
import EventPromotionalDisplay from './EventPromotionalDisplay.js';
import ButtonGroupBar from '../../common/ButtonGroupBar.js';
import ButtonGroupButton from '../../common/ButtonGroupButton.js';
import EventsClient from './EventsClient.js';

/**
 * Displays a set of events either in the past or future
 * @param {string} title title to be displayed, defaults to 'Upcoming Events'
 * @param {string} type either 'previous' or 'upcoming', defaults to 'upcoming'
 * @param {integer} numEvents the number of events to display, defaults to 5. If null, displays all events
 */
export default function EventsList( { title='Upcoming Events', type='upcoming', numEvents=5, onEdit=null, onDelete=null, events=null}) {
	const [currentUser] = useContext(AuthContext);
	const [currentOrganization, setCurrentOrganization] = useContext(OrganizationContext);
	const [upcomingEvents, setUpcomingEvents] = useState(null);

	const tableData = {
		data: upcomingEvents,
		rowKey: 'name',
		columns: [
			{xs: 2.5, headerText: 'Event', cellComponent:(item)=>{return (<EventPromotionalDisplay event={item}/>)} }
		]
	}

	const organizeUpcomingEvents = () => {

		if (events === null) {
			return;
		}

		const upcoming = [];
		
		var eventsAdded=0;
		const today = new Date();

		if (type==='previous') { events.reverse(); }
		for (var i=0; i<events.length; i++) {
			const event = events[i];

			var addEvent=false;
			event.performances.map((performance) => {
				const performanceDate = new Date(performance.date);
				if ((type==='upcoming' && performanceDate>today) || (type==='previous' && performanceDate<today)) {
					addEvent=true;
				}
			});

			if (addEvent) {
				upcoming.push(event);
				eventsAdded++;
				if (numEvents !== null && eventsAdded >= numEvents) {
					break;
				}
			}
		}
		setUpcomingEvents(upcoming);
	}
	
	const DisplayEventRow = ({ event, onClick }) => {
		const GuestArtists = (props) => {
			var returnValue='';

			return(
				<Grid container direction='column'>
					{(event.featuredPerformers !== null && event.featuredPerformers!== undefined) &&
						event.featuredPerformers.map((performer)=>{
							return (
							<Grid item key={performer.name}>
								<Typography fontSize={14}>{`${performer.name}, ${performer.performanceSkill}`}</Typography>
							</Grid>)
						})
					}
				</Grid>
			)
		}

		return (
			<Box onClick={onClick} sx={{cursor: 'pointer'}}>
				<Grid container direction='row' borderTop={0.5} sx={{}}>
					<Grid item xs={2.5} container direction='column' justifyContent='flex-start' sx={{marginBottom:'0.5em'}}>
						<Grid item>
							<Typography variant='h2'><b>{event.name}</b></Typography>
						</Grid>
						<Grid item>
							<SeriesChip seasonName={event.season} seriesName={event.series}/>
						</Grid>
					</Grid >
					<Grid item xs={2.5}>
						<GuestArtists/>
					</Grid>
					<Grid item container xs={7} direction='column'>
						{event.performances.map((performance)=>{ 
							const performanceDate = new Date(performance.date);
							return (
								<Grid item container direction='row' key={performanceDate}>
									<Grid item xs={5}>
										<Typography fontSize={14}>{
											`${performanceDate.toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"long", day:"numeric"})}, 
											${performanceDate.toLocaleTimeString('en-us', { hour:"numeric", minute:"numeric", hour12: true})}`} 
										</Typography>
									</Grid>
									<Grid item xs={7}>
										<Typography fontSize={14}> {performance.locationName}</Typography>
									</Grid>								
								</Grid>
							)})}
					</Grid>
				</Grid>
			</Box>
		)
	}

	const EventListItem = ({event}) => {
		const [ isExpanded, setIsExpanded ] = useState(false)
		return (
			<Box key={event.id}>
				{/* list event display */}
				<Grid container direction='row' key={event.name+'-'+event.id}>
					<Grid item xs={11} onClick={()=>{setIsExpanded(!isExpanded)}} 
						sx={{
							'&:hover': {
								cursor:'pointer',
								// border: 0.5
								// borderStyle:'d'
							}
						}}
					>
						{/* <ListItemButton onClick={()=>{setIsExpanded(!isExpanded)}}> */}
						<EventPromotionalDisplay 
							event={event} 
							key={event.name} 
							onClick={(clickEvent)=>{console.log(event.name)}}
							onDelete={onDelete}
							onEdit={onEdit}
						/>
						{/* </ListItemButton> */}
					</Grid>
					<Grid item xs={1}>
						<ButtonGroupBar size='small' variant='outline'>
							{onEdit !== null &&
								<ButtonGroupButton toolTip='Edit Event' onClick={()=>{onEdit(event)}}>
									<EditRoundedIcon/>
								</ButtonGroupButton>
							}
							{onDelete !== null &&
								<ButtonGroupButton toolTip='Delete Event' onClick={()=>{onDelete(event)}}>
									<DeleteForeverRoundedIcon/>
								</ButtonGroupButton>
							}
						</ButtonGroupBar>
					</Grid>
				</Grid>
				{/* Expanded Event display */}
				{(isExpanded)&&
					<EventDisplay event={event} />
				}
				
				<Divider sx={{marginTop: 2}}/>
			</Box>
		)
	}

	useEffect(()=>{
		organizeUpcomingEvents();
	},[events]);

	return (
		<Box sx={{p:'0.5em', marginTop:'0.5em'}}>
			{/*<ArtiztaPageTitle>{title}</ArtiztaPageTitle>*/}
			<Grid container width='100%' x={{marginTop:'0.5em'}} direction='column'>
				{currentOrganization == null ?
					<OrganizationChange/>
				:
					<React.Fragment>
						{upcomingEvents === null || upcomingEvents === undefined || upcomingEvents.length === 0 ? 
							<React.Fragment>
								No {type} events
							</React.Fragment>
						:
							<React.Fragment>
								{upcomingEvents.map((event)=>{
									return (
										<EventListItem event={event} key={event.id}/>
									)
								})}
							</React.Fragment>
						}
					</React.Fragment>
				}

			</Grid>
		</Box>
	)
}