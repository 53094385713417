// React imports
import React, { useState, useEffect, useCallback, useContext } from 'react';

// material
import { Typography, Grid, Box,  } from '@mui/material';
import PausePresentationRoundedIcon from '@mui/icons-material/PausePresentationRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import AttachFileRoundedIcon from '@mui/icons-material/AttachFileRounded';

// Project
import ButtonGroupBar from '../../../common/ButtonGroupBar';
import ButtonGroupButton from '../../../common/ButtonGroupButton';

import DisplayEventWork from './DisplayEventWork';
import ArtiztaEditDialog from '../../../common/ArtiztaEditDialog';
import EditWorkComponent from '../../works/components/EditWorkComponent';
import WorksSearch from '../../works/components/WorksSearch';
import FindEventWorkComponent from './FindEventWorkComponent';
import { ItemContext } from '../../../common/ArtiztaEditComponent';
import WorkRecommendations from '../../../common/workRecommendations/WorkRecommendations';
// works style
const worksStyle = {
  border: "1px dashed gray",
  padding: "0.5rem 1rem",
  marginBottom: ".5rem",
  backgroundColor: "white",
  cursor: "move"
};

export const WorksHoverContext = React.createContext();

export default function EditEventWorksComponent( { selectedSeries=null }) {
	const [totalDuration, setTotalDuration] = useState(0);
	const [editingEventWork, setEditingEventWork] = useState(null);
	const [editingNewEventWork, setEditingNewEventWork] = useState(null);	
	const [editingSearchEventWork, setEditingSearchEventWork] = useState(null);
	const [editingDialogTitle, setEditingDialogTitle] = useState('Edit Event Work');
	const [editingItem, setEditingItem] = useContext(ItemContext);
	const [editingEventWorkIndex, setEditingEventWorkIndex] = useState(null);


	// Drag n Drop
	const [hoverIndicatorIndex, setHoverIndicatorIndex] = useState(null);
	const moveEventWork = useCallback((dragIndex, hoverIndex) => {
		setHoverIndicatorIndex(hoverIndex);
	})

	const handleAddNewEventWork = () => {
		setEditingNewEventWork({});
	}

	const setDuration = (eventWork) => {
		if (eventWork.duration === undefined || eventWork.duration === null || isNaN(eventWork.duration)) {
			eventWork.duration = 0;
		}
		else {
			eventWork.duration = parseInt(eventWork.duration);
		}
	}

	const handleSaveNewEventWork = (newEventWork) => {
		setDuration(newEventWork);

		if (editingItem.works === null || editingItem.works === undefined) {
			newEventWork.order=0;
			editingItem.works=[newEventWork];
		}
		else {
			newEventWork.order=editingItem.works.length;
			editingItem.works.push(newEventWork);
		}

		updateTotalDuration();

		setEditingNewEventWork(null);
		setEditingSearchEventWork(null);
		editingItem.works = [...editingItem.works];
		setEditingItem({...editingItem});
	}

	const handleCancelNewEventWork = (eventWork) => {
		if (eventWork.new === true) {
			// delete it
			removeEventWork (eventWork);
		}
		setEditingEventWork(null);		
		setEditingNewEventWork(null);
		setEditingSearchEventWork(null);
	}

	const handleEditEventWork = (eventWork) => {
		const index = editingItem.works.findIndex((item) => item.order === eventWork.order);
		setEditingEventWorkIndex(index);
		setEditingEventWork(eventWork);
	}

	const handleSaveEditingEventWork = (eventWork) => {
		setDuration(eventWork);

		editingItem.works[editingEventWorkIndex] = eventWork;
		editingItem.works = [...editingItem.works];
		setEditingItem({...editingItem});
		setEditingEventWork(null);
		setEditingEventWorkIndex(null);
		updateTotalDuration();
	}

	const handleCancelEditingEventWork = (eventWork) => {
		setEditingEventWork(null);
		setEditingEventWorkIndex(null);
	}

	const handleAddIntermission = () => {
		const intermission = {isIntermission:true, title:"intermission"};

		if (editingItem.works === null || editingItem.works === undefined) {
			intermission.order=0;
			editingItem.works=[intermission];
		}
		else {
			intermission.order=editingItem.works.length;
			editingItem.works.push(intermission);
		}
		editingItem.works = [...editingItem.works];
		setEditingItem({...editingItem});
	}

	const updateTotalDuration = () => {
		// update total duration
		let tempTotal=0;
		if (editingItem.works !== null && editingItem.works !== undefined) {
			editingItem.works.map(eventWork => {
				let duration=0;
				if (eventWork.duration !== undefined) {
					duration=eventWork.duration
				}

				tempTotal += duration;
			})
		}
		setTotalDuration(tempTotal);
	}

	const removeEventWork = (eventWork) => {
		editingItem.works = editingItem.works.filter((item)=> item.order !== eventWork.order);
		editingItem.works = [...editingItem.works];
		setEditingItem({...editingItem});
	}

	useEffect(()=>{
		updateTotalDuration();

	},[editingItem.works]);

	const onMoveEnd = newList => {
		// log(newList);
	}

	const reorderWorks = (moveOrder, toOrder) => {
		if (moveOrder === toOrder) {
			return;
		}

		const newEventWorks = [];
		let lastDisplayOrder=0;
		var newOrder=0;

		if (toOrder < 0) {
			// move to end
			for (let i=0; i<editingItem.works.length; i++) {
				if (editingItem.works[i].order !== moveOrder) {
					newEventWorks.push({...editingItem.works[i], ...{order:newOrder}});
					newOrder++;
				}
			}
			newEventWorks.push(editingItem.works[moveOrder]);	
		}
		else {
			// move the item in moveOrder to before the item in toOrder
			for (let i=0; i<editingItem.works.length; i++) {
				if (editingItem.works[i].order === toOrder) {
					newEventWorks.push({...editingItem.works[moveOrder], ...{order:newOrder}});
					newOrder++;
					newEventWorks.push({...editingItem.works[toOrder], ...{order:newOrder}});
					newOrder++;
				}
				else if (editingItem.works[i].order !== moveOrder) {
					newEventWorks.push({...editingItem.works[i], ...{order:newOrder}});
					newOrder++
				}
			}
		}

		editingItem.works = [...newEventWorks];
		setEditingItem({...editingItem});
	}

	const handleAddEventWorkFromLibrary = () => {
		setEditingSearchEventWork({});
	}

	return (
		<React.Fragment>
			<Grid container direction='column'>
				<Grid container direction='row' alignItems='center'>
					<Grid item xs={4}>
						<Typography variant='h3'>Number of works: {(editingItem.works !== null && editingItem.works !== undefined) ? editingItem.works.length : 0}</Typography>
					</Grid>
					<Grid item xs={4}>
						<Typography variant='h3'>Total Duration: {totalDuration} minute{totalDuration!==1 && 's'}</Typography>
					</Grid>
					<Grid item xs={4}>
						<ButtonGroupBar size='small'>
							<ButtonGroupButton toolTip='Add a new Work to the Event' onClick={()=>{handleAddNewEventWork()}}>
								<AddRoundedIcon/>
							</ButtonGroupButton>
							<ButtonGroupButton toolTip='Add an Intermission to this Event' onClick={()=>{handleAddIntermission()}}>
								<PausePresentationRoundedIcon/>
							</ButtonGroupButton>
							<ButtonGroupButton toolTip='Find a Work in your Library to Add' onClick={()=>{handleAddEventWorkFromLibrary()}}>
								<AttachFileRoundedIcon/>
							</ButtonGroupButton>
							{/* Ai Generation Button */}
							<WorkRecommendations event={editingItem} stateSet={setEditingNewEventWork}/>
						</ButtonGroupBar>
					</Grid>
				</Grid>
				<WorksHoverContext.Provider value={[hoverIndicatorIndex, setHoverIndicatorIndex]}>
				{(editingItem.works !== undefined && editingItem.works !== null) &&
					<Box>
						{ editingItem.works.map((eventWork) => {
								return (<DisplayEventWork 
									eventWork={eventWork} 
									key={eventWork.order}
									index={eventWork.order}
									onReorder={reorderWorks}
									onEdit={()=>handleEditEventWork(eventWork)}
									onDelete={()=>removeEventWork(eventWork)}/>
								);
							})
						}
					</Box>
				}
				</WorksHoverContext.Provider>
			</Grid>
			<ArtiztaEditDialog 
				title="Edit this Work in the Event"
				item={editingEventWork} 
				onSave={handleSaveEditingEventWork} 
				onCancel={handleCancelEditingEventWork}>
				<EditWorkComponent series={selectedSeries}/>
			</ArtiztaEditDialog>
			<ArtiztaEditDialog 
				title="Add a New Work to this Event"
				item={editingNewEventWork} 
				onSave={handleSaveNewEventWork} 
				onCancel={handleCancelNewEventWork}>
				<EditWorkComponent series={selectedSeries}/>
			</ArtiztaEditDialog>
			<ArtiztaEditDialog 
				title="Find a Work to Add to this Event"
				item={editingSearchEventWork} 
				onSave={handleSaveNewEventWork} 
				onCancel={handleCancelNewEventWork}>
				<FindEventWorkComponent/>
			</ArtiztaEditDialog>
		</React.Fragment>
	)
}