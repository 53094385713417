// React imports
import React from 'react';

// Material imports
import { Grid, Typography  } from '@mui/material';
//import Grid from '@mui/material/Unstable_Grid2';
//import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


// project imports


/**
 * This displays the output from work from DanielsOnline
 * @param {{}} danielsOnline this is the work id from Daniels Online
 */
export default function DanielsOnlineDisplay({ children, danielsOnline }) {

	const isField = (field) => {
		return (field !== null && field !== undefined && field !== '');
	}

	const displayRowForField = (field, title=null, direction='row') => {
		if (isField(field)) {
			const titleGridSize=title===null ? 12 : 3;
			const valueGridSize=title===null ? 12 : 9;

			return (				
			<Grid item container direction={direction}>
				{title !== null &&
					<Grid item xs={titleGridSize}>
						<Typography fontSize={14}><b>{title}</b></Typography>
					</Grid>
				}
				<Grid item xs={valueGridSize}>
					<Typography fontSize={14}>{field}</Typography>
				</Grid>
			</Grid>)
		}
		else {
			return (
				<Grid item xs={12}>
					<Typography fontSize={14}><i>No {title} available</i></Typography>
				</Grid>
			)
		}
	}

	const displayMovements = () => {

		if (isField(danielsOnline.movements)) {
			const movements = danielsOnline.movements;
			movements.sort((a,b)=>{ return parseInt(a.order)-parseInt(b.order); })

			return (
				<Grid container item direction='column'>
					<Grid item><Typography fontSize={14}><b>Movements</b></Typography></Grid>
					{movements.map((item)=>{ return (
						<Grid item key={item.order}>
							<Typography fontSize={14} sx={{marginLeft:'0.5em'}}>{item.name} - {item.duration} Mins</Typography>
						</Grid>)
					})}
				</Grid>
			)
		}
		return null;
	}

	return (
		<Grid container direction='column'>
			{displayRowForField(danielsOnline.title, "Title from Daniels Online")}
			{displayRowForField(danielsOnline.details.formula, "Instrumentation")}
			{displayRowForField(danielsOnline.details.perc_info, "Percussion")}
			{displayRowForField(danielsOnline.details.remarks, "Remarks", 'column')}
			{displayRowForField(danielsOnline.details.additional_info, "Additional Information")}
			{displayMovements()}
			<Grid item container xs={12}>
				{children}
			</Grid>
		</Grid>
	)
}