import React, { useState, useEffect } from 'react';

// material
import { Box, Grid, Typography, Dialog } from '@mui/material';
import EastRoundedIcon from '@mui/icons-material/EastRounded';

//project imports
import { curtainColor, stageColor, nightColor, spotlightColor, shadowColor } from './SymphonEaseColors.js';

export default function ArtiztaColorSelect ({ onSelectColor, defaultColorName }) {
	const [showColorList, setShowColorList] = useState(false);

	const colorOptions = [
		{	name: 'Curtain', 
			color: shadowColor[30],
			contrastColor: spotlightColor[90] },
		{	name: 'Stage',
			color: stageColor[50],
			contrastColor: nightColor[10] },
		{	name: 'PopsLOL',
			color: shadowColor[90],
			contrastColor: nightColor[10] },
		{	name: 'Night',
			color: nightColor[30],
			contrastColor: spotlightColor[90] },
		{	name: 'Highlight',
			color: curtainColor[70],
			contrastColor: shadowColor[10] },
		{	name: 'Spotlight',
			color: spotlightColor[90],
			contrastColor: curtainColor[10] },
		{	name: 'Theater Sky',
			color: nightColor[80],
			contrastColor: shadowColor[10] }
	]
	const [selectedColor, setSelectedColor] = useState(null);
	const ColorContext = React.createContext();

	useEffect(()=>{
		if (defaultColorName !== null && defaultColorName !== undefined) {
			const foundColor = colorOptions.find((color)=>color.name===defaultColorName);
			if (foundColor !== null && foundColor !== undefined) {
				setSelectedColor(foundColor);
			}
		}
		else {
			setSelectedColor(colorOptions[0]);
		}
	},[defaultColorName]);

	const DisplayColorList = () => {
		return (
			<Grid container direction='column' >
				{colorOptions.map((color) => {

					return (
						<Grid item key={color.name} xs={12}>
							<DisplayColor 
								color={color}
								selectedColor={selectedColor}
								onClick={(color)=> {
									setSelectedColor(color)
									setShowColorList(false)
									if (onSelectColor !== null && onSelectColor !== undefined) {
										onSelectColor(color);
									}
								}}/>
						</Grid>
					)
				})}
			</Grid>
		)
	}

	const DisplayColor = ({ color, onClick, selectedColor }) => {
		const [boxSx, setBoxSx] = useState(null);

		const handleClick = (e) => {
			onClick(color);
		}



		useEffect(()=>{
			if (color!==null && color!==undefined && selectedColor!==null && selectedColor!==undefined) {
				const selectedSX = {p:'0.2em', m:'0.2em', bgcolor:color.color, border:3, borderRadius:2, borderColor:color.contrastColor, cursor:'default'};
				const unselectedSX = {p:'0.2em', m:'0.2em', bgcolor:color.color, border:1, borderRadius:2, borderColor:color.contrastColor, cursor:'pointer'};

				if (selectedColor.name === color.name) {
					setBoxSx(selectedSX)
				}
				else {
					setBoxSx(unselectedSX);
				}
			}
		},[color, selectedColor])

		return (
			<React.Fragment>
				{color!==null && color!==undefined &&
					<Box sx={boxSx}
							onClick={handleClick}>
						<Grid container direction='row' justifyContent='center' alignItems='center'>
							<Typography sx={{color:color.contrastColor}}>{color.name}</Typography>
						</Grid>
					</Box>
				}
			</React.Fragment>
		)
	}

	return (
			<Grid 
					container direction='row' justifyContent='space-between' alignItems='center' 
					sx={{marginTop:'0.5em', marginBottom:0, maxHeight:24}}>
				<Grid item xs={4}>
					<Typography>Select a Color for the Series</Typography>
				</Grid>
				<Grid item xs={8}>
					<ColorContext.Provider value={[selectedColor, setSelectedColor]}>
						<DisplayColor 
							id='ColorSelect'
							color={selectedColor}
							selectedColor={{name:'none', color:'none', contrastColor:'none'}}
							onClick={(color)=>{ 
								setShowColorList(true);
							}}/>
						
						{showColorList && 
							<Dialog 
								onClose={()=>setShowColorList(false)}
								fullWidth
								maxWidth='sm'
								open={showColorList}>
								<DisplayColorList/>
							</Dialog>
						}
					</ColorContext.Provider>
				</Grid>
			</Grid>
	)
}