// React imports
import React, { useState, useContext, useEffect } from 'react';

// material
import { TextField, Autocomplete } from '@mui/material';


// Project
import { SettingsContext } from '../../utils/SettingsContext';

export default function PerformanceSkillSelect ({id, defaultValue}) {
	const [settings, setSettings, performanceSkills, setPerformanceSkills] = useContext(SettingsContext);
	const [performanceSkillValue, setPerformanceSkillValue] = useState(null);
	const [defaultOption, setDefaultOption] = useState(null);

	const setDefaultOptionFromValue = () => {
		if (defaultValue !== '' && performanceSkills !== null) {
			const theOption = performanceSkills.find((item)=>item.skill===defaultValue);
			if (theOption !== undefined) {
				setDefaultOption(theOption);
				setPerformanceSkillValue(theOption.skill);
			}
		}
	}

	useEffect(()=>{
		setDefaultOptionFromValue();
	}, [defaultValue]);

	const optionIsEqual = (option, value) => {
		if (typeof value == 'string') {
			return (option.skill === value);
		}
		else {
			return (option === value);
		}
	}

	const getLabelOption = (option) => {
		if (typeof option == 'string') { 
			return option;
		}
		else {
			return option.skill;
		}
	}

	return (
		<Autocomplete
			id={id}
			autoComplete={true}
			autoHighlight={true}
			options={performanceSkills}
			groupBy={(option) => option.category}
			getOptionLabel={getLabelOption}
			sx={{ width: '100%' }}
			renderInput={(params) => <TextField {...params} label="Performance Skills" />}
			defaultValue={defaultOption}
			isOptionEqualToValue={optionIsEqual}
			onChange={(e, value)=>{setPerformanceSkillValue(value)}}
			value={performanceSkillValue}
		/>
	)
}