// React imports
import { React, useContext, useState } from 'react';

// material imports
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

// Project
import { AuthContext } from '../../../utils/AuthContext';
import ArtiztaEditComponent from '../../../common/ArtiztaEditComponent';
import PersonEditComponent from '../../people/components/PersonEditComponent';
import PeopleClient from '../../people/PeopleClient';
import ArtiztaSnackbar from '../../../common/ArtiztaSnackbar';

export default function ProfileSettings() {
  // Context
	const [ currentUser, setCurrentUser ] = useContext(AuthContext)
	
	//state
	const [ openSnackbar, setOpenSnackbar] = useState(false)

	// Save
	const onSave = (savePerson) => {
		PeopleClient.update(savePerson)
			.then(json => {
				setCurrentUser({...currentUser, ...{person: json}})
				setOpenSnackbar(true)
			})
	}

	// Cancel
	const onCancel = () => {
	}

	return (
		<Grid container>
			<ArtiztaEditComponent 
				item= {currentUser.person}
				onSave = {onSave}
				onCancel = {onCancel}
				overrideSaveDisabled={true}
			>
				<PersonEditComponent />
			</ArtiztaEditComponent>

			{/* Snackbar */}
			<ArtiztaSnackbar message={"Profile Updated"} open={openSnackbar} setOpen={setOpenSnackbar}/>
		</Grid>
	)
}


