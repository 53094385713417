import { Autocomplete, TextField } from "@mui/material"
import React, { useContext, useState } from "react"

// Project Imports
import PeopleClient from '../PeopleClient.js'

export default function PeopleEmailSearch( { onSelectEmail, onBlur }) {
  // context

  // People Search
  const [lastFetchSearchValue, setLastFetchSearchValue] = useState('');
  const [lastFetchResults, setLastFetchResults] = useState([]);
  const [displayItems, setDisplayItems] = useState([]);

  // Search helper
  const handleSelection = (event, newValue) => {
		if (onSelectEmail !== null && onSelectEmail !== undefined) {
			onSelectEmail(newValue);
		}
  }

  // Search Helper
  const handleInputChange = (event, newValue) => {
    // need to determine if a new fetch is needed
    let newFetchNeeded=false;

    if (newValue==='') { setLastFetchSearchValue(''); setLastFetchResults([]); setDisplayItems([]); return;}
    if (lastFetchSearchValue==='') {newFetchNeeded=true;}
    if (!newValue.startsWith(lastFetchSearchValue)) {newFetchNeeded=true;}

    if (newFetchNeeded) {
      setLastFetchSearchValue(newValue);
      PeopleClient.searchForEmail(newValue)
        .then(json => {
          setLastFetchResults(json);
          setDisplayItems(json);
        });
    }
    else {
      return;
    }
  }

	const handleBlur = (event) => {
		const typedEmail = event.target.value;
		const person = displayItems.find(item=>item.email === typedEmail);
		if (person !== null && person !== undefined) {
			handleSelection(event, person);
		}
		else {
			onBlur(event);
		}
	}

  return (
	<Autocomplete 
		freeSolo
		id="people-email-search"
		autoHighlight={true}
		options={displayItems}
		getOptionLabel={option=>`${option.email}`}
		isOptionEqualToValue={(option, value) => {return option.emaild===value.email}}
		renderInput={(params) => <TextField {...params} fullWidth label="Search for Person by e-mail" onBlur={handleBlur}/>}
		onChange={handleSelection}
		onInputChange={handleInputChange}
	/>
  )
}