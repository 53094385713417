
// material-ui
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from "@mui/material";
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';


// projects
import ButtonGroupBar from "./ButtonGroupBar";
import ButtonGroupButton from "./ButtonGroupButton";
import { useEffect, useState } from "react";

export const clearAlertDialog = { open: false, title: '', message: '', onAgree: null, onDisagree: null };

/** Displays a standard alert dialog given the options
 * @param {{ open, title, message, onAgree, onDisagree }} dialogOptions options including whether the dialog is open, the title, the message, and the callbacks for agree and disagree
 */
export default function ArtiztaAlertDialog({ dialogOptions }) {
	const [showDialog, setShowDialog] = useState(false);
	
	useEffect(() => {
		setShowDialog(dialogOptions.open);
	}, [dialogOptions]);

	return (
		<Dialog
			open={showDialog}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">{dialogOptions.title}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					{dialogOptions.message}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<ButtonGroupBar>
					<ButtonGroupButton onClick={dialogOptions.onAgree} toolTip={`Agree and Continue`}>
						<CheckRoundedIcon/>
					</ButtonGroupButton>
					<ButtonGroupButton onClick={dialogOptions.onDisagree} toolTip={`Disagree and Go Back`}>
						<CloseRoundedIcon/>
					</ButtonGroupButton>
				</ButtonGroupBar>
			</DialogActions>
		</Dialog>
	)
}