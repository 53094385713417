// React
import { useContext, useState } from "react";

// Material
import { Dialog, Divider, Grid, IconButton, List, ListItem, Skeleton, Stack, Tooltip, Typography } from "@mui/material";
import SmartToyIcon from '@mui/icons-material/SmartToy';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';

// Project
import WorkRecommendationCell from "./WorkRecommendationCell";
import ButtonGroupButton from "../ButtonGroupButton";
import DanielsOnlineClient from "../../pages/works/DanielsOnlineClient";
import { AuthContext } from "../../utils/AuthContext";
import RecommendationClient from "../../utils/RecommendationsClient";


export default function WorkRecommendations({ event, stateSet }) {
  // Context
  const [ currentUser ] = useContext(AuthContext)
  
  // state
  const [ response, setResponse ] = useState(null)
  const [ showResults, setShowResults ] = useState(false)
  const [ isLoading, setIsLoading ] = useState(false)
  
  // Reach out
  const askChat = () => {
    setShowResults(true)
    // Only call this once
    if (response == null){
      // only call if there's works
      if (event.works != undefined || event.works.length > 0) {
        setIsLoading(true)
        RecommendationClient.getWorkRecommendations(currentUser.person.settings.openAiAPIKey, event)
        .then(json => {
          setIsLoading(false)
          setResponse(json)
        })
      }
    }else {
      setShowResults(!showResults)
    }
  }

  const refreshList = () => {
    setIsLoading(true)
      RecommendationClient.getWorkRecommendations(currentUser.person.settings.openAiAPIKey, event)
      .then(json => {
        setIsLoading(false)
        setResponse(json)
        setShowResults(true)
      })
  }

  // dialout close
  const onDialogClose = () => {
    setShowResults(false)
  }

  // On save from AI
  const onSave = (work, isManual) => {
    // Adding manually
    if (isManual == true){
      stateSet(work)
      setShowResults(false)
    }else{
      // adding from daniels - parse first
      const parsedWork = DanielsOnlineClient.getWorkFromDanielsRecord(work)
      stateSet(parsedWork)
      setShowResults(false)
    }
  }

  // UI
  return (
    <Grid>
      {/* Recommend Works Button */}
      {(currentUser.person.settings.openAiAPIKey!=undefined)?
        <ButtonGroupButton 
          disabled={(event.works == undefined || event.works.length < 1 || isLoading)} 
          toolTip={(event.works == undefined || event.works.length < 1) ? "Need to add a work first" : "See Recommended Works"}
          onClick={askChat}
        >
          <SmartToyIcon />
        </ButtonGroupButton>
      :
        <ButtonGroupButton disabled={true} toolTip="Unavialable, seek out Settings">
          <SmartToyIcon />
        </ButtonGroupButton>
      }

      {/* Skeleton */}
      {/* <Skeleton variant="rectangular" width={210} height={118}/> */}

      {/* Dialog */}
      <Dialog
        sx={{marginTop: '5em'}}
        fullWidth={true}
        maxWidth={'lg'}
        open={showResults}
        onClose={onDialogClose}
      >
        {(response != null) ?
          <Grid container direction={'column'}>
            {/* Title and x button */}
            <Grid container justifyContent={'space-between'}>
              <Grid container item xs={10}>
                <Typography sx={{m:2}} variant="h1">
                  Recommending works for '{event.name}' based on "{event.works[0].title}" by {event.works[0].composer}
                </Typography>
                <Tooltip title="Refresh List">
                  <IconButton
                    onClick={refreshList}
                    disabled={isLoading}
                  >
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={2}>
              <Tooltip title="Hide Recommendations">
                <IconButton 
                  onClick={onDialogClose}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8
                  }}
                >
                  <CloseIcon />
                </IconButton>
                
                {/* <Button variant="outlined" onClick={onDialogClose}>X</Button> */}
              </Tooltip>
              </Grid>
            </Grid>
            <Divider />

            {/* List of recommended works */}
            <List sx={{ borderRadius: 5}} >
              {/* This is iterating over the works in the list, will be empty if parsing failed */}
              {response.worksList.map((item)=> { 
                return (
                  <ListItem key={item.work} divider>
                    <WorkRecommendationCell onSave={onSave} item={item}/>
                  </ListItem>
                )
              })}
            </List>
          </Grid>
        :
        <Stack spacing={2} sx={{m:2}}>
          {(event.works !== undefined && event.works !== null && event.works.length>=1 )&&
            <Typography sx={{m:2}} variant="h1">
              Recommending works for '{event.name}' based on "{event.works[0].title}" by {event.works[0].composer}
            </Typography>
          }
          <Skeleton variant="circular" width={40} height={40} />
          <Skeleton variant="rounded" height={60} />
          <Skeleton variant="rounded" height={60} />
          <Skeleton variant="rounded" height={60} />
        </Stack>
        }
      </Dialog>
    </Grid>
  )
}