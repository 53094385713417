// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

export const API_KEY='AIzaSyCATQoZndRYSnIRDlO0BQqga9TVGBTevs4';
export const CLIENT_ID='789819114008-d4ib3d5bm9t7cs4ps9mge5nulsc4okjc.apps.googleusercontent.com';

// PILOT
export const firebaseConfig = {
  apiKey: "AIzaSyCATQoZndRYSnIRDlO0BQqga9TVGBTevs4",
  authDomain: "symphonease-pilot-f30e7.firebaseapp.com",
	//authDomain:"pilot.symphonease.com",
	//authDomain::localhost",
  projectId: "symphonease-pilot-f30e7",
  storageBucket: "symphonease-pilot-f30e7.appspot.com",
  messagingSenderId: "789819114008",
  appId: "1:789819114008:web:0b3181936aeb4861d8f96d",
  measurementId: "G-ZTL493746J"
};

// DEV
// const firebaseConfig = {
//   apiKey: "AIzaSyBkB0c6VqkTtjOJjOe-avgiMn62ADrvqBg",
//   authDomain: "artizta-7ff7e.firebaseapp.com",
//   projectId: "artizta-7ff7e",
//   storageBucket: "artizta-7ff7e.appspot.com",
//   messagingSenderId: "1001546169454",
//   appId: "1:1001546169454:web:482421e008814a3049fa7f",
//   measurementId: "G-Z7DQRFF0GF"
// };


