
// React, ReactRouter imports
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

// MUI imports
import {Box, CssBaseline, Grid} from '@mui/material';
import {ThemeProvider} from '@mui/material/styles';

// project imports
import ArtiztaHeader from './header/ArtiztaHeader.js';
import Dashboard from './pages/dashboard/DashboardPage.js';
import Organizations from './pages/organizations/OrganizationsPage.js';
import PeoplePagev2 from './pages/people/PeoplePagev2.js';
import LocationsPage from './pages/locations/LocationsPage.js';
import EventsPage from './pages/events/EventsPage.js';
import WorksPage from './pages/works/WorksPage.js';
import Settings from './pages/settings/SettingsPage.js';
import { AuthContextProvider } from './utils/AuthContext.js';
import { OrganizationContextProvider } from './utils/OrganizationContext.js';
import { SettingsContextProvider } from './utils/SettingsContext.js';
import SandboxPage from './pages/sandbox/SandboxPage.js';
import SeasonDesignerPage from './pages/seasonDesigner/SeasonDesignerPage.js';
import LocationsClient from './pages/locations/LocationsClient.js';
import { symphonEaseTheme } from './common/SymphonEaseTheme.js';
import { summerProgressionsTheme } from './common/SummerProgressionsTheme.js';

export const LocationsContext = React.createContext(); // TODO: Separate to another component like the others

function App() {
	const [locations, setLocations] = useState(null);
	
	const fetchLocations = async () => {
		const locationsArray = await LocationsClient.getAll();
		setLocations(locationsArray);
	}

	useEffect(()=>{
		if (locations === null || locations === undefined) {
			fetchLocations();
		}
	}, [locations]);

  return (
    <ThemeProvider theme={summerProgressionsTheme}>
      <AuthContextProvider>
        <OrganizationContextProvider>
					<SettingsContextProvider>
						<LocationsContext.Provider value={[locations, setLocations]}>
							<DndProvider backend={HTML5Backend}>
								<BrowserRouter>
									<Box>
										<Grid container direction='column'>
											<CssBaseline/>
											<Grid item><ArtiztaHeader/></Grid>
											<Grid item>
												<Routes>
													<Route path='/' element={<Dashboard/>}/>
													<Route path='/organizations' element={<Organizations/>}/>
													<Route path='/locations' element={<LocationsPage/>}/>
													{/* <Route path='/people' element={<People/>}/> */}
													<Route path='/people' element={<PeoplePagev2/>}/>
													<Route path='/events' element={<EventsPage/>}/>
													<Route path='/seasonDesigner' element={<SeasonDesignerPage/>}/>
													<Route path='/works' element={<WorksPage/>}/>
													<Route path='/settings' element={<Settings/>}/>
													<Route path='/sandbox' element={<SandboxPage/>}/>
												</Routes>
											</Grid>
										</Grid>
									</Box>
									</BrowserRouter>
								</DndProvider>
							</LocationsContext.Provider>
					</SettingsContextProvider>
        </OrganizationContextProvider>
      </AuthContextProvider>
    </ThemeProvider>
  );
}

export default App;
