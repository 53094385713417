import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';

// material
import { Box, Grid, Typography, useTheme  } from '@mui/material';



export default function CalendarHeader( { startDay=1 /* Sunday=0 */} ) {

	const todayJS = dayjs();
	const weekStart = todayJS.day(startDay);

	const days = [weekStart];
	let currentDay=weekStart.add(1, 'day');

	for (let i=0; i<6; i++) {
		days.push(currentDay);
		currentDay = currentDay.add(1, 'day');
	}

	const theme = useTheme();

	return (
		<Grid container direction='row'>
			{days.map((dayJS)=> {
				const headerSX = {borderBottom:2, p:0.5}
				let variant='h3'
				if (dayJS.day() == 6 || dayJS.day() == 0) {
					//headerSX.backgroundColor = theme.palette.background.calendarWeekend;
					variant='h2'
				}

				return (
					<Grid item container xs={12/7} key={dayJS.format('dddd')}
						sx={headerSX} justifyContent='center'>
							<Typography variant={variant}>{dayJS.format('dddd')}</Typography>
					</Grid>
				)
			})}
		</Grid>
	)
}