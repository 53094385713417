// react
import React, { useContext, useEffect, useState } from "react";

// project
import { OrganizationContext } from "./OrganizationContext";
import { AuthContext } from "./AuthContext";
import OrganizationsClient from "../pages/organizations/OrganizationsClient";

export default function RoleWrapper(props) {
  // state
  const [ canView, setCanView ] = useState(false)

  // context
  const [ currentOrganization ] = useContext(OrganizationContext)
  const [ currentUser ] = useContext(AuthContext)

  // check current user with current organization 
  useEffect(() => {
    setCanView(OrganizationsClient.canView(props.roles, currentOrganization, currentUser))
  },[currentUser]) // If currentUser changes, run this again

  return (
    <React.Fragment>
      {(canView) ?
        props.children
      :
        <div></div>
      }
    </React.Fragment>
  )
}