//material 
import { Grid, Box, Typography, Divider, Button } from "@mui/material";
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';

// project
import summerProgressionsLogo from '../../assets/summerProgressions/SummerProgressionsLogo.svg';
import fundamentalChangesLogo from '../../assets/summerProgressions/SummerProgressionsFundamentalChanges.png';
import leadershipFeaturesLogo from '../../assets/summerProgressions/SummerProgressionsLeadershipFeatures.png';
import libraryManagementLogo from '../../assets/summerProgressions/SummerProgressionsLibraryManagementFeatures.png';
import DesignServicesRoundedIcon from '@mui/icons-material/DesignServicesRounded';
import LibraryMusicRoundedIcon from '@mui/icons-material/LibraryMusicRounded';
import FeedbackIcon from '@mui/icons-material/Feedback';


export default function SummerProgressionsContent({ setShowDialog }) { 

	return (
		<Box>
			{/* Introduction */}
			<Box sx={{paddingLeft:'1em', paddingRight:'1em', textAlign:'center'}}>
				<Typography variant='h1'>
					This summer, we are progressing <b>SymphonEase</b> by focusing on Features for the <b><DesignServicesRoundedIcon fontSize='sm'/> Season Designer</b>, along with an initial release for <b> <LibraryMusicRoundedIcon fontSize='sm'/> Library Management</b>, and <b>Fundamental Improvements</b>. 
				</Typography><br/>
				<Typography variant='body2'>
					We are also introducing the idea of a <i><b>Season</b></i>. During each Season we identify the Seasonal Focus and change Theme to match.<br/>
					In the future, you will be able to switch to a prior Themes or the SymphonEase default or dark Themes. 
				</Typography>
				<br/>
			</Box>
			<Divider/>
			<Grid container direction='row'>
				<Grid item container direction='column'xs={8}>
					{/* Leadership Changes */}
					<Grid item container direction='row' alignItems='center'>
						<Grid item xs={2.5} sx={{p:'1em'}}>
							<img src={leadershipFeaturesLogo} width='100%' alt='Summer Progressions'/>
						</Grid>
						<Grid item xs={9.5} sx={{p:'0.5em'}}>
							<Typography variant='h2'>Features for <b>Leadership</b> will Drive Ease of Use and Product Value</Typography><br/>
							<Typography variant='body2'>
							<ArrowRightRoundedIcon fontSize='sm'/> First version of the <b>Expense Center</b><br/>
							<ArrowRightRoundedIcon fontSize='sm'/> Improved <b>Recommendation Engine</b> to include instrumentation<br/>
							<ArrowRightRoundedIcon fontSize='sm'/> <b>Vertical View</b> for all the Artistic Directors with sideways monitors<br/>
							</Typography>
						</Grid>
					</Grid>	
					<Divider/>		
					{/* Library Management Changes */}
					<Grid item container direction='row' alignItems='center'>
						<Grid item xs={9.5} sx={{p:'0.5em'}}>
							<Typography variant='h2'>Initial Release for <b>Library Management</b> will enable a Pilot for Librarians</Typography><br/>
							<Typography variant='body2'>
							<ArrowRightRoundedIcon fontSize='sm'/> Library Management features and data loading will provide a foundation<br/>
							<ArrowRightRoundedIcon fontSize='sm'/> Focus on Instrumentation will enable rapid Ensemble Creation<br/>
							</Typography>
						</Grid>
						<Grid item xs={2.5} sx={{p:'1em'}}>
							<img src={libraryManagementLogo} width='100%' alt='Summer Progressions'/>
						</Grid>
					</Grid>			
					<Divider/>		

					{/* Fundamental Changes */}
					<Grid item container direction='row' alignItems='center'>
						<Grid item xs={2.5} sx={{p:'1em'}}>
							<img src={fundamentalChangesLogo} width='100%' alt='Summer Progressions'/>
						</Grid>
						<Grid item xs={9.5} sx={{p:'0.5em'}}>
							<Typography variant='h2'><b>Fundamental</b> Changes will enable SymphonEase for Future Development</Typography><br/>
							<Typography variant='body2'>
							<ArrowRightRoundedIcon fontSize='sm'/> Migration from Firestore to Postgres to enable complex datasets, critical for Personnel Management<br/>
							<ArrowRightRoundedIcon fontSize='sm'/> User Access - Ensure appropriate Access for all Data<br/>
							<ArrowRightRoundedIcon fontSize='sm'/> <b>Instrumentation Decomposition</b> reliably converts instrumentation text to structured data for better searching and recommendations<br/>
							<ArrowRightRoundedIcon fontSize='sm'/> Automated Testing will allow for rapid, stable deployments<br/>
							</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid item container xs={4}>
					<img src={summerProgressionsLogo} width='100%' alt='Summer Progressions'/>
				</Grid>
			</Grid>
			<Divider/>
			{/* Conclusion */}
			<Box sx={{p:'2em', textAlign:'center'}}>
				<Typography variant='h1'>
					We hope you enjoy using <b>SymphonEase</b>, please remember to Submit Any <FeedbackIcon fontSize='sm'/> Feedback, and <b>Thank You</b> for your help! 
				</Typography><br/>
			</Box>
		</Box>
	)
}