
// project imports
import APIClientManager from './APIClientManager.js';

export default class CommentsClient {
	static baseURL = 'comments';

	static get = async (organization, person, type, item, commentThread=null) => {

		const organizationID = typeof organization === 'object' ? organization.id : organization;
		const personID = typeof person === 'object' ? person.id : person;
		const itemID = typeof item === 'object' ? item.id : item;
		var commentThreadID = commentThread;
		if (commentThreadID !== null && typeof commentThreadID === 'object') {
			commentThreadID = commentThread.id;
		}
		var url = `${CommentsClient.baseURL}/${organizationID}/${personID}/${type}/${itemID}`;
		if (commentThreadID !== null) {
			url += `/${commentThreadID}`;
		}
		const response = await APIClientManager.get(url);
		const json = await response.json();

		return json;
	}

	static post = async (organization, person, type, item, commentStr, commentThread=null) => {
		const organizationID = typeof organization === 'object' ? organization.id : organization;
		const personID = typeof person === 'object' ? person.id : person;
		const itemID = typeof item === 'object' ? item.id : item;
		var commentThreadID = commentThread;
		if (commentThreadID !== null && typeof commentThreadID === 'object') {
			commentThreadID = commentThread.id;
		}
		var url = `${CommentsClient.baseURL}/${type}`;
		if (commentThreadID !== null) {
			url += `/${commentThreadID}`;
		}
		
		const comment = {
			organizationID: organizationID,
			entityID: itemID,
			personID: personID,
			type: type,
			comment: commentStr,
		};

		const response = await APIClientManager.post(url, comment);
		const json = await response.json();

		return json;
	}

	/**
	 * This has not been tested
	 */
	static deleteCommentsForItem = async (organization, person, type, item) => {
		const organizationID = typeof organization === 'object' ? organization.id : organization;
		const personID = typeof person === 'object' ? person.id : person;
		const itemID = typeof item === 'object' ? item.id : item;
		const url = `${CommentsClient.baseURL}/${organizationID}/${personID}/${type}/${itemID}`;
		const response = await APIClientManager.delete(url);
		const json = await response.json();

		return json;
	}

}