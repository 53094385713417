

/* Use API Client Manager to manage all api access
	usage:
		post() submits a post request. Two required paramneters:
			relativeURL - the URL of the api, relative to the 'api' directory. 
			data - data to be posted, in json format 
		get() submits a get request for the URL. it takes one required and one optional parameter
			relativeURL - the URL of the api, relative to the 'api' directory. 
			queryParameters (OPTIONAL) - dictionary of query parameters to be used in the get
*/

// TO DO - add Token


export default class APIClientManager {
	static headers = {'Content-Type': 'application/json'};

	static setPersonID = (id) => {
		APIClientManager.personID = id;
	}

	static baseURL = (window.location.hostname === "localhost") ? 
		process.env.REACT_APP_API_URL_LOCAL+'/api/' : 
		process.env.REACT_APP_API_URL+'/api/';

	static post = (relativeURL, data) => {
		const url = APIClientManager.baseURL+relativeURL;
		const body = JSON.stringify(data);

		return fetch(url, {
			method: 'POST',
			headers: APIClientManager.headers,
			body: body });
	}

	static put = (relativeURL, data) => {
		const url = APIClientManager.baseURL+relativeURL;
		const body = JSON.stringify(data);

		return fetch(url, {
			method: 'PUT',
			headers: APIClientManager.headers,
			body: body });
	}

	static delete = (relativeURL) => {
		return fetch(APIClientManager.baseURL+relativeURL, {method: 'DELETE'});
	}

	static get = (relativeURL, queryParameters=null) => {
		let queryString = '';

		if (queryParameters!==null) {
			queryString = '?';
			const keys = Object.keys(queryParameters);
			for (let i=0; i<keys.length; i++) {
				queryString += `${keys[i]}=${queryParameters[keys[i]]}`;
				if (i<keys.length-1) {
					queryString += '&';
				}
			}
		}

		const url = APIClientManager.baseURL+relativeURL+queryString;
		return fetch(url, {headers: APIClientManager.headers});
	}
}

