// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "./FirebaseConfig";

//import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
import { 
	getAuth, 
	signInWithPopup, 
	signInWithRedirect,
	GoogleAuthProvider, 
	signInWithEmailAndPassword, 
	FacebookAuthProvider, 
	createUserWithEmailAndPassword,
	getRedirectResult} from 'firebase/auth';
// https://firebase.google.com/docs/web/setup#available-libraries


// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);

const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();
googleProvider.setCustomParameters( {
  prompt: "select_account"
})
//const emailProvider = new EmailAuthProvider();

export const auth = getAuth();
export const signInWithGooglePopup = () => signInWithPopup(auth, googleProvider);
export const signInWithGoogleRedirect = () => signInWithRedirect(auth, googleProvider);
export const signInWithFacebookPopup = () => signInWithPopup(auth, facebookProvider);
export const getGoogleRedirectResult = () => getRedirectResult(auth);
export const signInWithEmail = (email, password) => {
	return signInWithEmailAndPassword(auth, email, password).catch((error) =>{ return false});
}
export const createUserWithEmail = (email, password) => createUserWithEmailAndPassword(auth, email, password);
