import React, {useContext, useState, useEffect} from 'react';

// material imports
import { Chip, useTheme } from '@mui/material';

// project imports
import { OrganizationContext } from '../../utils/OrganizationContext.js';

export default function SeriesChip ({ seasonName, seriesName, series=null, size='small' }) {
	const theme = useTheme();
	const [currentOrganization, setCurrentOrganization] = useContext(OrganizationContext);
	const [displaySeries, setDisplaySeries] = useState({color:theme.palette.background.defaultSeries, contrastColor:theme.palette.text.defaultSeries, name:'Default'});

	const findSeries = () => {
		const season = currentOrganization.seasons.find((item)=>{return item.name===seasonName});
		if (season !== undefined) {
			const series = season.series.find((item)=>{return item.name===seriesName;})
			if ( series !== undefined) {
				setDisplaySeries(series);
			}
		}
	}

	useEffect(()=>{
		if (series === null) {
			findSeries();
		}
	},[])

	useEffect(()=>{
		if (series != null) {
			setDisplaySeries(series);
		}
	},[series])

	return (
		<React.Fragment>
			{displaySeries !== null && displaySeries !== undefined &&
				<Chip label={`${displaySeries.name} Series`} size={size} sx={{
					borderColor: displaySeries.contrastColor,
					backgroundColor: displaySeries.color,
					color: displaySeries.contrastColor,
					border:1
				}}/>
			}
		</React.Fragment>
	)
}