// React imports
import React, { useState, useContext, useEffect } from 'react';
import dayjs from 'dayjs';

// material
import { Typography, Grid, Box } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

// Project
import ButtonGroupBar from '../../../common/ButtonGroupBar';
import ButtonGroupButton from '../../../common/ButtonGroupButton';
import EditEventPerformance from './EditEventPerformance';
import DisplayEventPerformance from './DisplayEventPerformance';
import { ItemContext } from '../../../common/ArtiztaEditComponent';
import { LocationsContext } from '../../../App.js';
import EventsClient from '../EventsClient.js';

export default function EditEventPerformancesComponent() {
	const [editingItem, setEditingItem] = useContext(ItemContext);
	const [editingEventPerformance, setEditingEventPerformance] = useState(null);
	const [locations, setLocations] = useContext(LocationsContext);

	// const reorderPerformances = () => {
	// 	// based on the date/time
	// 	editingItem.performances.sort((a, b)=> {return a.date-b.date});
	// 	editingItem.performances = [...editingItem.performances]; 
	// 	setEditingItem({...editingItem});
	// }

	const handleAddEventPerformance = () => {
		let performanceDateJS = dayjs(new Date());
		// default to 8PM start time
		performanceDateJS.hour(20);
		performanceDateJS.minute(0);
		performanceDateJS.second(0);
		performanceDateJS.millisecond(0);

		// if there is another performance, set this one for the day after the last one
		if (editingItem.performances !== null && editingItem.performances !== undefined && editingItem.performances.length > 0) {
			const lastPerformance = editingItem.performances[editingItem.performances.length-1];
			const lastPerformanceDateJS = dayjs(lastPerformance.date);
			const nextPerformanceDateJS = lastPerformanceDateJS.add(1, 'day');
			performanceDateJS = nextPerformanceDateJS;
		}
		

		const newEventPerformance={
			new: true,
			date: performanceDateJS.toDate()
		}
		if (editingItem.performances === null || editingItem.performances === undefined) {
			newEventPerformance.id=0;
			editingItem.performances = [newEventPerformance]
		}
		else {
			newEventPerformance.id=editingItem.performances.length;
			editingItem.performances.push(newEventPerformance);
			editingItem.performances = [...editingItem.performances];
		}
		setEditingEventPerformance(newEventPerformance);
		setEditingItem({...editingItem});
	}

	const handleEditEventPerformance = (item) => {
		setEditingEventPerformance(item);
	}
	
	const handleRemoveEventPerformance = (item) => {
		removeEventPerformance (item);
	}

	const removeEventPerformance = (eventPerformance) => {
		editingItem.performances = editingItem.performances.filter((item)=> item.id !== eventPerformance.id);
		editingItem.performances = [...editingItem.performances];
		setEditingEventPerformance(null);
		setEditingItem({...editingItem});
	}

	const handleSaveEditEventPerformance = (eventPerformance) => {
		editingEventPerformance.notes = document.getElementById('ep-notes').value;
		delete editingEventPerformance.new;
		setEditingEventPerformance(null);
		EventsClient.sortPerformances(editingItem);
		// Refreshing the performances array
		editingItem.performances = [...editingItem.performances];
		setEditingItem({...editingItem});
	}

	const handleCancelEditEventPerformance = (eventPerformance) => {
		if (eventPerformance.new) {
			removeEventPerformance (eventPerformance);
		}
		setEditingEventPerformance(null);
	}

	useEffect(() => {
		if (editingItem !== null && editingItem !== undefined) {
			EventsClient.sortPerformances(editingItem);
		}
	}, [editingItem]);

	return (
		<React.Fragment>
			{editingItem !== null && editingItem !== undefined &&
				<Grid container direction='column'>

					<Grid container direction='row' alignItems='center' >
						<Grid item xs={6}>
							{editingItem.performances !== null && editingItem.performances !== undefined ? 
								<Typography variant='h3'>Number of performances: {editingItem.performances.length}</Typography>
							:
								<Typography variant='h3'>There are no event performances scheduled.</Typography>
							}
						</Grid>
						<Grid item xs={6}>
							<ButtonGroupBar>
								<ButtonGroupButton toolTip='Add a new Performance to the Event' onClick={handleAddEventPerformance}>
									<AddRoundedIcon/>
								</ButtonGroupButton>
							</ButtonGroupBar>
						</Grid>
					</Grid>
					{(editingItem.performances !== undefined && editingItem.performances !== null) &&
						<Box>
							{editingItem.performances.map((eventPerformance) => {
								if (editingEventPerformance === eventPerformance) {
									return (<EditEventPerformance key={eventPerformance.date.toString()+eventPerformance.locationID}
										eventPerformance={eventPerformance} 
										onSave={()=>handleSaveEditEventPerformance(eventPerformance)}
										onCancel={()=>handleCancelEditEventPerformance(eventPerformance)}/>)
								}
								else {
									return (<DisplayEventPerformance key={eventPerformance.date.toString()+eventPerformance.locationID}
										eventPerformance={eventPerformance} 
										onEdit={()=>handleEditEventPerformance(eventPerformance)} 
										onDelete={()=>handleRemoveEventPerformance(eventPerformance)}/>)
								}
							})}
						</Box>
					}
				</Grid>	
		}
		</React.Fragment>	
	)
}