import { useEffect, useState } from 'react';

// material
import { Box, Tooltip, Grid, Typography } from '@mui/material';

export default function ArtiztaClickableBox({onClick, sx, message, tooltip}) {
	const defaultSX = {
		borderRadius:2,
		width:'100%',
		height:'100%',
		p:'0.25em',
		textAlign:'center',
		'&:hover': {
			cursor:'pointer',
			border: 1,
			borderStyle:'dashed'}
	}
	const [isHovering, setIsHovering] = useState(false);
	const [finalSX, setFinalSX] = useState(defaultSX);



	useEffect(()=>{
		let theSX = {...defaultSX};
		if (sx === null || sx === undefined) {
			theSX = {...defaultSX, ...sx};
		}
		setFinalSX(theSX);
	}, [sx])

	return (
		<Box sx={{width:'100%', height:'100%', p:'0.25em'}}>
			<Tooltip title={tooltip}>
				<Box sx={finalSX}
					onClick={(e)=>onClick(e)}
					onMouseEnter={()=>setIsHovering(true)}
					onMouseLeave={()=>setIsHovering(false)}	
					>
						{isHovering &&
							<Grid container direction='column' justifyContent='center' alignItems='center' sx={{height:'100%'}}>
								<Grid item >
									<Typography variant='h3'>{message}</Typography>
								</Grid>
							</Grid>
						}
				</Box>
			</Tooltip>
		</Box>
	)
}