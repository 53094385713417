// material
import { Button, Divider, Grid, Typography } from "@mui/material";
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

// react
import React, { useContext, useEffect, useState } from "react";

// project
import { AuthContext } from "../../../utils/AuthContext";
import { OrganizationContext } from "../../../utils/OrganizationContext";
import OrganizationsClient from "../../organizations/OrganizationsClient";
import RoleWrapper from "../../../utils/RoleWrapper";
import OrganizationChange from "../../organizations/components/OrganizationChange";
import PeopleClient from "../../people/PeopleClient";
import ArtiztaSnackbar from "../../../common/ArtiztaSnackbar";
import OrganizationName from "../../organizations/components/OrganizationName"

export default function ChangeOrganizationSettings() {
  // Context
  const [ currentUser ] = useContext(AuthContext)
  const [ currentOrganization, setCurrentOrganization] = useContext(OrganizationContext)

  // State
  const [ userOrgs, setUserOrgs ] = useState([])
  const [ openSnackbar, setOpenSnackbar] = useState(false)

  // Load orgs the person is apart of
  useEffect(() => {
    async function fetchData() {
      for (var i in currentUser.person.organizationIDs) {
        getOrg(currentUser.person.organizationIDs[i])
      }
    }
    fetchData()
	}, []);

  // Gets org & adds it to the state
  const getOrg = async (orgId) => {
    const json = await OrganizationsClient.getOrganization(orgId)
    userOrgs.push(json)
    setUserOrgs([...userOrgs])
  }

  // Set org
  const handleSelection = async (newOrg) => {
    // Context
    setCurrentOrganization(newOrg)
    
    // DB
    currentUser.person.settings.selectedOrganization = newOrg.id

    await PeopleClient.update(currentUser.person)

    // snacks
    setOpenSnackbar(true)
  }

  return (
    <Grid container direction={'column'}>
      {/* Admin View */}
      <RoleWrapper roles={['Global Admin']}>
        <Typography>Admin View</Typography>
        <OrganizationChange />
        <Divider sx={{marginTop: 2, marginBottom: 2}} />
      </RoleWrapper>
      
      {/* Show orgs if there is more than 1 */}
      {(userOrgs.length > 0)&&
        <Grid >
          <Typography variant="h2" sx={{marginBottom: 2}}>
            Organizations you are apart of
          </Typography>
          {
            userOrgs.map((org) => {
              return (
                <Grid sx={{borderRadius: 4, border:(currentOrganization.id === org.id) ? 1 :0}} key={org.id} container alignItems={'center'} justifyContent={'space-between'}>
                  <Button 
                    onClick={() => handleSelection(org)} 
                  >
                    <OrganizationName organization={org}/>
                  </Button>
                  {
                    (currentOrganization.id === org.id) &&
                    <CheckRoundedIcon sx={{m:1}}/>
                  }
                </Grid>
              )
            })
          }
        </Grid>
      }

      {/* Snackbar */}
      <ArtiztaSnackbar message={`Changed organization to "${currentOrganization.name}"`} open={openSnackbar} setOpen={setOpenSnackbar}/>
    </Grid>
  )
}