// material
import { Box, FormControlLabel, Grid, TextField, Typography, Checkbox, Tooltip, Button} from "@mui/material"
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';

// Project
import { ItemContext } from "../../../common/ArtiztaEditComponent"
import { AuthContext } from "../../../utils/AuthContext";
import FileSelector from "../../../common/FileSelector"
import { FirebaseFileClient } from "../../../utils/FirebaseFileClient"
import PerformanceSkillsSelector from "./PerformanceSkillsSelector"

// React
import { useContext, useState } from "react"

// This is if the organization has rights to the person
export default function PersonFullEditComponent() {
  // context
  const [ editingItem, setEditingItem ] = useContext(ItemContext)
  const [ currentUser ] = useContext(AuthContext)
  // state
  const [performanceSkills, setPerformanceSkills] = useState( editingItem!=null ? editingItem.performanceSkills : [])

  // handle performance skills
  const handlePerformanceSkills = (newSkills) => {
    // state
    setPerformanceSkills(newSkills)

    // context
    setEditingItem({...editingItem, ...{performanceSkills: newSkills}})
  }

  // Key person star
  const [ isKeyPerson, setIsKeyPerson ] = useState(editingItem!=null ? editingItem.isKeyPerson : false)
  const handleClickKeyPerson = () => {
    // State
    setIsKeyPerson(!isKeyPerson);

    // Context
    setEditingItem({...editingItem, ...{isKeyPerson: !isKeyPerson}})
  }

  // File Selector
  const [filename, setFilename] = useState('No File Selected')
  const [imageData, setImageData] = useState(null)
  const selectImage = (theFile, theImageData) => {
    if (theFile) {

      setImageData(theImageData);
      FirebaseFileClient.upload(theFile, 'images', (url) => {
          setFilename(url);
      });
    }
  }
  const deleteImage = (url) => {
    setFilename('deleted');
    setImageData(null);
  }

  // Temp, until api is set up
	const allRoles = [
    "Organization Admin",
    "Leadership",
    "Librarian",
    "Personnel Manager",
    "Staff",
    "Performer"
  ]
  // Role stuff
  const [checkedBoxes, setCheckedBoxes] = useState({
		"Organization Admin": editingItem.roles.includes("Organization Admin"),
		"Leadership": editingItem.roles.includes("Leadership"),
    "Librarian": editingItem.roles.includes("Librarian"),
    "Personnel Manager": editingItem.roles.includes("Personnel Manager"),
    "Staff": editingItem.roles.includes("Staff"),
    "Performer": editingItem.roles.includes("Performer")
	})
  const handleRoleChange = (event) => {
    // Change Check boxes
		const { name, checked } = event.target;
		const change = {};
		change[name]=checked;
		const newCheckedBoxes = {...checkedBoxes, ...change};
		setCheckedBoxes( newCheckedBoxes );

    // Update context
    var newRoles = []
    for (var k in newCheckedBoxes){
      if (newCheckedBoxes[k] == true){
        newRoles.push(k)
      }
    }
    setEditingItem({...editingItem, ...{roles: newRoles}})
	}

  // UI
  return (
    <Box>
      <Grid container justifyContent={'space-between'} alignItems={'center'}>
        <Typography sx={{marginBottom: 2}}>
          {editingItem.email}
        </Typography>
        {/* Key Person */}
        <Tooltip>
          <Button onClick={handleClickKeyPerson}>
            {(isKeyPerson) ?
              <StarIcon />
              :
              <StarBorderIcon />
            }
          </Button>
        </Tooltip>
      </Grid>
      {/* Info & Image section */}
      <Grid container justifyContent={'space-between'} spacing={2}>
        {/* Name & Title */}
        <Grid item xs={8} container direction={'column'} justifyContent={'flex-start'}>
          {/* First & Last Name */}
          <Grid item container direction={'row'} spacing={2}>
            <Grid item xs={6}>
              <TextField 
                id="firstName"
                name="firstName"
                label="First Name"
                fullWidth
                defaultValue={editingItem.firstName}
                onChange={(e) => setEditingItem({...editingItem, ...{firstName: e.target.value}})}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField 
                id="lastName"
                name="lastName"
                label="Last Name"
                fullWidth
                defaultValue={editingItem.lastName}
                onChange={(e) => setEditingItem({...editingItem, ...{lastName: e.target.value}})}
              />
            </Grid>
          </Grid>
          {/* Title */}
          <TextField 
            sx={{marginTop: 2}}
            id="title"
            name="title"
            label="Title"
            fullWidth
            defaultValue={editingItem.title}
            onChange={(e) => setEditingItem({...editingItem, ...{title: e.target.value}})}
          />
        </Grid>
        {/* Image */}
        <Grid item xs={4}>
          <FileSelector
            title="Profile Image Picker"
            accept='image/*'
            onSelect={selectImage}
            onDelete={deleteImage}
            dropZoneMessage='Drag an image to upload'
            width={256}
            imageData={imageData}
          >
            <img src={imageData} alt='Selected' width={256}/>
          </FileSelector>
        </Grid>
      </Grid>

      {/* Tagline & Profile */}
      <TextField 
        sx={{marginTop: 2}}
        id="tagLine"
        name="tagLine"
        label="Tagline"
        fullWidth
        defaultValue={editingItem.tagLine}
        onChange={(e) => setEditingItem({...editingItem, ...{tagLine: e.target.value}})}
      />
      <TextField 
        sx={{marginTop: 2}}
        id="profile"
        name="profile"
        label="profile"
        multiline
        minRows={4}
        fullWidth
        defaultValue={editingItem.profile}
        onChange={(e) => setEditingItem({...editingItem, ...{profile: e.target.value}})}
      />

      {/* Roles & Performance Skills */}
      <Grid container direction={'row'}>
        <Grid item xs container direction={'column'}>
          <h3>Roles</h3>
          {
            allRoles.map((role) => {
              return (
                <FormControlLabel 
                  key={role} 
                  name={role} 
                  id={role} 
                  control={<Checkbox checked={checkedBoxes[role]}/>} 
                  label={role} 
                  onChange={handleRoleChange}
                />
              )
            })
          }
        </Grid>
        {/* Performance Skills */}
        <PerformanceSkillsSelector selectedPerformanceSkills={performanceSkills} setSelectedPerformanceSkills={handlePerformanceSkills} />
      </Grid>
    </Box>
  )
}