// project
import APIClientManager from "./APIClientManager";

export default class ImageClient {
  // static baseURL = 'http://'+process.env.REACT_APP_API_URL+'/api/'
	/** generates an image and returns the URL for the image 
	 * @param {string} orgId the organization ID
	 * @param {string} personId the person ID
	 * @param {string} type the type of image to generate
	 * @param {string} entity the entity to generate the image for
	 * @param {string} style optional - the style of the image to generate. If null, a random style will be used
	*/
  static generateImage = async (openAiAPIKey, orgId, personId, type, entity, style=null) => {

    const bundle = {
      'organizationID': orgId,
      'personID': personId,
			'type': type,
      'entity': entity,
      'token': openAiAPIKey
      // 'token': "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"
    }
		if (style !== null) {
			bundle.style = style;
		}
    const response = await APIClientManager.post('images', bundle)
    const json = await response.json()
    return json;
  }
}