import React, { useState } from 'react';

// material
import { Button, Dialog, DialogActions, DialogContent, Tooltip, useTheme, Grid } from '@mui/material';

// project files
import summerProgressionsLogo from '../../assets/summerProgressions/SummerProgressionsLogo.svg';
import SummerProgressionsContent from './SummerProgressionsContent';
import DirectionsRunRoundedIcon from '@mui/icons-material/DirectionsRunRounded';
import artiztaConfetti from '../../utils/ArtiztaConfetti.js';

export default function SummerProgressions() {
	const [showDialog, setShowDialog] = useState(false);

	const theme = useTheme();

	return (
		<React.Fragment>
			<Dialog 
					open={showDialog} 
					onClose={()=>setShowDialog(false)} 
					maxWidth='xl' 
					fullWidth
					sx={{marginTop:'4.5em'}}
					>
				<DialogContent>
					<SummerProgressionsContent/>
				</DialogContent>
				<DialogActions>
					<Grid container direction='row' justifyContent='center'>
						<Button 
						variant='contained' 
								color='primary' 
								sx={{borderRadius:10, marginLeft:'auto', marginRight:'auto'}} 
								onClick={()=>{
									artiztaConfetti(null, 'beach');
									setShowDialog(false)}}>
							Let's Go! <DirectionsRunRoundedIcon/>
						</Button>
					</Grid>
				</DialogActions>
			</Dialog>
			<Tooltip title='Summer Progressions'>
				<Button 
					onClick={()=>setShowDialog(true)}
					sx={{
						height: '2.5em',
						padding: 0,
						[theme.breakpoints.down('lg')]: { height:'2em'},
						[theme.breakpoints.down('md')]: { height:'1.5em'},
						[theme.breakpoints.down('sm')]: { height:'1.0em'}}}>
					<img src={summerProgressionsLogo} height='100%'/>
				</Button>
			</Tooltip>
		</React.Fragment>
	)
}