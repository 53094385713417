// react
import React, { useState, useContext, useEffect } from 'react';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale'

//material
import { Grid, TextField, Box, Autocomplete } from '@mui/material';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

// project imports
import ButtonGroupBar from '../../../common/ButtonGroupBar';
import ButtonGroupButton from '../../../common/ButtonGroupButton';
import ArtiztaBorderBox from '../../../common/ArtiztaBorderBox';
import { LocationsContext } from '../../../App.js';

export default function EditEventPerformance({eventPerformance, onSave, onCancel}) {
	const [locations, setLocations] = useContext(LocationsContext);
	const LocationSelect = ({onChange, defaultValue}) => {
		const [location, setLocation] = useState(null);

		return (        
			<Autocomplete 
				id="location-search"
				sx={{width:'100%'}}
				autoHighlight={true}
				options={locations}
				getOptionLabel={option=>option.name}
				isOptionEqualToValue={(option, value) => {return option.id===value.id}}
				renderInput={(params) => <TextField {...params} sx={{width:'100%'}} label="Search for a Location" />}
				defaultValue={defaultValue}
				onChange={(e, newValue)=>{
					setLocation(newValue);
					if(newValue!=null){onChange(newValue)}
				}}
			/>)
	}

	const setPerformanceDate = (newPerformanceDate) => {
		// assume this is a datejs date
		const currentDate = dayjs(eventPerformance.date);
		const date = newPerformanceDate.date();
		const month = newPerformanceDate.month();
		const year = newPerformanceDate.year();
		const hour = currentDate.hour();
		const minute = currentDate.minute();

		const dateStr = `${month+1}-${date}-${year} ${hour}:${minute}`;
		const newDate = dayjs(dateStr).toDate();
		eventPerformance.date=newDate;
	}

	const setPerformanceTime = (newPerformanceTime) => {
		// assume this is a datejs date
		const currentDate = dayjs(eventPerformance.date);
		const date = currentDate.date();
		const month = currentDate.month();
		const year = currentDate.year();
		const hour = newPerformanceTime.hour();
		const minute = newPerformanceTime.minute();
		const dateStr = `${month+1}-${date}-${year} ${hour}:${minute}`;
		const newDate = dayjs(dateStr).toDate();
		eventPerformance.date=newDate;

	}

	const fieldMargin='1em';
			
	const locationForID = (id) => {
		if (locations === null || locations === undefined) {
			return null;
		}
		for(var i=0; i<locations.length; i++) {
			const item=locations[i];
			if (item.id === id) {
				return item;
			}
		}

		return null;
	} 

	const handleLocationSelect = (newLocation) => {
		eventPerformance.locationName = newLocation.name;
		eventPerformance.locationID = newLocation.id;
		eventPerformance.locationShortAddress = `${newLocation.address.street}, ${newLocation.address.city}, ${newLocation.address.state}`;		
	}

	useEffect(() => {
		dayjs.extend(updateLocale);
		dayjs.updateLocale('en', {
			weekStart: 1,
		});
	},[])

	return (
		<ArtiztaBorderBox>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
					<Grid container direction='row' key={`ep-${eventPerformance.id}`}>	
						<Grid item xs={6}>
							<Grid container direction='column'>
								<Grid container direction='row'>
									<Grid item xs={6}>
										<Box sx={{marginRight:fieldMargin}}>
											<DatePicker sx={{width:'100%'}}
												label='Date'
												defaultValue={dayjs(eventPerformance.date)}
												onChange={(newValue)=>setPerformanceDate(newValue)}/>		
										</Box>								
									</Grid>
									<Grid item xs={6}>
										<Box sx={{marginRight:fieldMargin}}>
											<TimePicker sx={{width:'100%'}}
												label="Start Time"
												defaultValue={dayjs(eventPerformance.date)}
												onChange={(newValue)=>setPerformanceTime(newValue)}/>
										</Box>
									</Grid>
								</Grid>
								<Grid item xs={6}>
									<Box sx={{marginTop:fieldMargin, marginRight:fieldMargin}}>
										<LocationSelect onChange={handleLocationSelect} defaultValue={locationForID(eventPerformance.locationID)}/>
									</Box>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={6}>
							<TextField
								sx={{width:'100%', height: '100%', display:'flex', flexGrow:1}}
								InputProps={{
									sx: {
										height: '100%',
										alignItems: 'start'
									}}}
								id="ep-notes"
								name="ep-notes"
								label="notes"
								multiline
								defaultValue={eventPerformance===null || eventPerformance===undefined ? '' : eventPerformance.notes}
							/>
						</Grid>	
					</Grid>
				<ButtonGroupBar>
					<ButtonGroupButton onClick={onSave} toolTip='Save changes for this performance.'>
						<CheckRoundedIcon/>
					</ButtonGroupButton>
					<ButtonGroupButton onClick={onCancel} toolTip='Finish editing this performance, do not save your changes'>
						<CloseRoundedIcon/>
					</ButtonGroupButton>
			</ButtonGroupBar>
			</LocalizationProvider>
		</ArtiztaBorderBox>
	)
}