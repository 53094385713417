

// React imports
import React, { useState, useContext } from 'react';

// material
import { Typography, Grid, Box } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

// Project
import ButtonGroupBar from '../../../common/ButtonGroupBar';
import ButtonGroupButton from '../../../common/ButtonGroupButton';
import EditEventFeaturedPerformer from './EditEventFeaturedPerformer';
import DisplayEventFeaturedPerformer from './DisplayEventFeaturedPerformer';
import { ItemContext } from '../../../common/ArtiztaEditComponent';

export default function EditEventFeaturedPerformers() {
	const [editingItem, setEditingItem] = useContext(ItemContext);
	const [editingEventFeaturedPerformer, setEditingEventFeaturedPerformer] = useState(null);

	/*
	const reorderPerformances = () => {
		
		// based on the date/time
		event.performances.sort((a, b)=> {return a.date-b.date});
		setEventPerformances([...event.performances]); 
	}
	*/

	const handleAddFeaturedPerformer = () => {
		const newPerformer = {new:true};

		if (editingItem.featuredPerformers === null || editingItem.featuredPerformers === undefined) {
			editingItem.featuredPerformers = [newPerformer]
		}
		else {
			editingItem.featuredPerformers.push(newPerformer);
			editingItem.featuredPerformers = [...editingItem.featuredPerformers];
		}

		setEditingEventFeaturedPerformer(newPerformer);
	}

	const handleEditFeaturedPerformer = (featuredPerformer) => {
		setEditingEventFeaturedPerformer(featuredPerformer);
	}
	
	const handleRemoveFeaturedPerformer = (featuredPerformer) => {
		removeEventFeaturedPerformer(featuredPerformer);
	}

	const handleSaveEditFeaturedPerformer = (featuredPerformer) => {
		setEditingEventFeaturedPerformer(null);
		editingItem.featuredPerformers = [...editingItem.featuredPerformers];
		setEditingItem({...editingItem});
		/*
		editingEventPerformance.notes = document.getElementById('ep-notes').value;
		delete editingEventPerformance.new;
		log(editingEventPerformance);
		setEditingEventPerformance(null);
		reorderPerformances();
		*/
	}

	const handleCancelEditFeaturedPerformer = (featuredPerformer) => {
		
		if (featuredPerformer.new) {
			removeEventFeaturedPerformer(featuredPerformer);
		}
		setEditingEventFeaturedPerformer(null);
	}

	const removeEventFeaturedPerformer = (featuredPerformer) => {
		editingItem.featuredPerformers = editingItem.featuredPerformers.filter((item)=> item.name !== featuredPerformer.name);
		setEditingEventFeaturedPerformer(null);
	}

	return (
		<Grid container direction='column'>
			<Grid container direction='row' alignItems='center'>
				<Grid item xs={6}>
					{(editingItem.featuredPerformers !== null && editingItem.featuredPerformers !== undefined) ? 
						<Typography variant='h3'>Number of Featured Performers: {editingItem.featuredPerformers.length}</Typography>
					:
						<Typography variant='h3'>There are no Featured Performers scheduled.</Typography>
					}
				</Grid>
				<Grid item xs={6}>
					<ButtonGroupBar>
						<ButtonGroupButton toolTip='Add a new Featured Performer to the Event' onClick={handleAddFeaturedPerformer}>
							<AddRoundedIcon/>
						</ButtonGroupButton>
					</ButtonGroupBar>
				</Grid>
			</Grid>
			{(editingItem.featuredPerformers !== undefined && editingItem.featuredPerformers !== null) &&
				<Box>
					{ editingItem.featuredPerformers.map((featuredPerformer) => {
						if (editingEventFeaturedPerformer === featuredPerformer) {
							return (<EditEventFeaturedPerformer 
								key={featuredPerformer.name}
								eventFeaturedPerformer={featuredPerformer} 
								onSave={()=>handleSaveEditFeaturedPerformer(featuredPerformer)}
								onCancel={()=>handleCancelEditFeaturedPerformer(featuredPerformer)}/>)
						}
						else {
							return (<DisplayEventFeaturedPerformer 
								key={featuredPerformer.name}
								eventFeaturedPerformer={featuredPerformer} 
								onEdit={()=>handleEditFeaturedPerformer(featuredPerformer)} 
								onDelete={()=>handleRemoveFeaturedPerformer(featuredPerformer)}/>)
						}
					})}
				</Box>
			}
			
		</Grid>		
	)
}