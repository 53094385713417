// material
import { Alert, Button, IconButton, Snackbar } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

import React from "react";


export default function ArtiztaSnackbar({message, open, setOpen, alert='success', anchorOrigin={vertical: 'bottom', horizontal: 'left'}}) {

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={()=>{setOpen(false)}}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Snackbar 
      open={open}
      autoHideDuration={5000}
      onClose={()=>{setOpen(false)}}
      message={message}
      anchorOrigin={anchorOrigin}
      action={action}
    >
        <Alert
          onClose={()=>{setOpen(false)}}
          severity={alert}
        >
          {message}
        </Alert>
    </Snackbar>
  )
}