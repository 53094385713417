// React imports
import React from 'react';

// material
import { Box, Card } from '@mui/material';


export default function ArtiztaCard(props) {

	var sx={};
	if (props.sx!== null && props.sx!== undefined) {
		sx = props.sx;
	}

	return (
		<Card sx={{borderRadius:4, ...sx}}>
			<Box sx={{m:'1em'}}>
				{props.children}
			</Box>
		</Card>
	)
}