// React imports
import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Material Imports
import { Tabs, Tab, Box, Card, SvgIcon, Typography } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Grid from '@mui/material/Unstable_Grid2';
import LibraryMusicRoundedIcon from '@mui/icons-material/LibraryMusicRounded';
import LibraryAddRoundedIcon from '@mui/icons-material/LibraryAddRounded';
// project imports
import ContentBackground from '../../common/ContentBackground';
import WorksSearch from './components/WorksSearch.js';
import ArtiztaIconButton from '../../common/ArtiztaIconButton.js';
import { OrganizationContext } from '../../utils/OrganizationContext.js';
import DanielsOnlineSearch from './components/DanielsOnlineSearch.js';
import EditWorkComponent from './components/EditWorkComponent.js';
import WorksClient from './WorksClient.js';
import ArtiztaCard from '../../common/ArtiztaCard.js';
import ArtiztaPageTitle from '../../common/ArtiztaPageTitle.js';
import OrganizationName from '../organizations/components/OrganizationName.js';
import ArtiztaEditDialog from '../../common/ArtiztaEditDialog.js';

// figure this out later
// import { ReactComponent as DanielsOnlineIcon } from '../../assets/DanielsOnline.svg' 

//export const WorkContext = React.createContext();
//export const EditWorkWindowContext = React.createContext();

export default function WorksPage(props) {
  const [currentWork, setCurrentWork] = useState(null);
	const [currentTab, setCurrentTab] = useState(0);
	const [pageMessage, setPageMessage] = useState(null);
	const [currentOrganization, setCurrentOrganization] = useContext(OrganizationContext);

	const navigate = useNavigate();

	useEffect(()=> {
		setPageMessage(null);
	}, [currentWork]);

	const handleTabChange = (event, newValue) => {
		setCurrentWork(null);
		setPageMessage(null);
		setCurrentTab(newValue);
	}

	const handleAddWork = () => {
		console.log('handleAddWork');
		setPageMessage(null);
		setCurrentWork({});
	}

	const handleSaveWork = async (newWork) => {
		const savedWork = await WorksClient.add(currentOrganization, newWork);
		setPageMessage(`'${savedWork.title}' added with id ${savedWork.id}`)

		console.log('handleSaveWork', newWork);
		
		setCurrentWork(null);
	}

	const handleCancelWorkEdit = (work) => {
		setCurrentWork(null);
		setPageMessage(null);
	}

	return (
		<ContentBackground>
			<ArtiztaCard>
				<Grid container justifyContent='space-between' alignItems='center' direction='row'>
					<ArtiztaPageTitle>Works</ArtiztaPageTitle>
					<ArtiztaIconButton 
						toolTip='Add a new Work to your Library' 
						disabled={currentOrganization===null} 
						onClick={handleAddWork}>
						<AddRoundedIcon/>
					</ArtiztaIconButton>
				</Grid>
				<Grid container justifyContent='space-between'>
					{/* <Tabs value={currentTab} onChange={handleTabChange}>
							<Tab label="Library" icon={<LibraryMusicRoundedIcon/>} iconPosition='end'/>
							<Tab label="Daniels Online" icon={<LibraryAddRoundedIcon/>} iconPosition='end'/>
					</Tabs> */}
					<OrganizationName/>
				</Grid>
				<WorksSearch/>

				{/* {currentTab===0 &&
				}
				{currentTab===1 &&
					<DanielsOnlineSearch/>
				} */}
				{pageMessage !== null &&
					<Typography fontSize={16}>{pageMessage}</Typography>
				}
			</ArtiztaCard>
			<ArtiztaEditDialog 
				item={currentWork}
				title='Add New Work to your Library'
				onSave={handleSaveWork}
				onCancel={handleCancelWorkEdit}>
				<EditWorkComponent />
			</ArtiztaEditDialog>
		</ContentBackground>
	)
}