
const ballURL = 'https://firebasestorage.googleapis.com/v0/b/symphonease-pilot-f30e7.appspot.com/o/images%2Fconfetti%2FsummerProgressions%2Fball.png?alt=media&token=c27f6536-9752-441d-ab78-8deccd32a48d'
const crabURL='https://firebasestorage.googleapis.com/v0/b/symphonease-pilot-f30e7.appspot.com/o/images%2Fconfetti%2FsummerProgressions%2Fcrab.png?alt=media&token=98d64eb5-3b80-4da7-ae7b-4035f9e4ce33'
const lifeBouyURL='https://firebasestorage.googleapis.com/v0/b/symphonease-pilot-f30e7.appspot.com/o/images%2Fconfetti%2FsummerProgressions%2Flifebuoy.png?alt=media&token=5284c625-4107-45b6-a149-ab5eb3e0f2d6'
const mermaidURL='https://firebasestorage.googleapis.com/v0/b/symphonease-pilot-f30e7.appspot.com/o/images%2Fconfetti%2FsummerProgressions%2Fmermaid.png?alt=media&token=358db054-cd96-45b1-afd9-4ea81357c7c6'
const pantsURL='https://firebasestorage.googleapis.com/v0/b/symphonease-pilot-f30e7.appspot.com/o/images%2Fconfetti%2FsummerProgressions%2Fpants.png?alt=media&token=18d37243-0f32-413c-aedb-33e869535673'
const policeURL='https://firebasestorage.googleapis.com/v0/b/symphonease-pilot-f30e7.appspot.com/o/images%2Fconfetti%2FsummerProgressions%2Fpolice.png?alt=media&token=40bd93bc-8aca-473e-95fd-beeae8dd8737'
const poseidonURL='https://firebasestorage.googleapis.com/v0/b/symphonease-pilot-f30e7.appspot.com/o/images%2Fconfetti%2FsummerProgressions%2Fposeidon.png?alt=media&token=0636a687-be1e-47ba-abcd-97022804da15'
const sharkURL='https://firebasestorage.googleapis.com/v0/b/symphonease-pilot-f30e7.appspot.com/o/images%2Fconfetti%2FsummerProgressions%2Fshark.png?alt=media&token=e5180351-3f81-4b34-b5c4-21ba1aff5d70'
const sodaCanURL='https://firebasestorage.googleapis.com/v0/b/symphonease-pilot-f30e7.appspot.com/o/images%2Fconfetti%2FsummerProgressions%2Fsoda-can.png?alt=media&token=05b9e4ed-2d9b-4065-ac5a-d4ed37552b4a'
const sunscreenURL='https://firebasestorage.googleapis.com/v0/b/symphonease-pilot-f30e7.appspot.com/o/images%2Fconfetti%2FsummerProgressions%2Fsun-protection.png?alt=media&token=f180af0e-62e5-4e11-bfe1-cc2d362c393e'
const fish1URL='https://firebasestorage.googleapis.com/v0/b/symphonease-pilot-f30e7.appspot.com/o/images%2Fconfetti%2FsummerProgressions%2Ffish_1.png?alt=media&token=81d4a850-35d8-4534-ad8d-920aa8820d5f'
const fish2URL='https://firebasestorage.googleapis.com/v0/b/symphonease-pilot-f30e7.appspot.com/o/images%2Fconfetti%2FsummerProgressions%2Ffish_2.png?alt=media&token=748a2fca-c8a1-4213-9c4a-4e04f0e78533'
const seasnailURL='https://firebasestorage.googleapis.com/v0/b/symphonease-pilot-f30e7.appspot.com/o/images%2Fconfetti%2FsummerProgressions%2Fsea-snail.png?alt=media&token=0bf88e2d-d2d3-4226-b6ee-071e5559b857'
const seashell1URL='https://firebasestorage.googleapis.com/v0/b/symphonease-pilot-f30e7.appspot.com/o/images%2Fconfetti%2FsummerProgressions%2Fseashell_1.png?alt=media&token=391ab936-0c5f-423d-a4fc-074e27144d6d'
const seashell2URL='https://firebasestorage.googleapis.com/v0/b/symphonease-pilot-f30e7.appspot.com/o/images%2Fconfetti%2FsummerProgressions%2Fseashell_2.png?alt=media&token=b6f6ee5f-a3ab-4322-9082-3af1c28e0686'
const starfishURL='https://firebasestorage.googleapis.com/v0/b/symphonease-pilot-f30e7.appspot.com/o/images%2Fconfetti%2FsummerProgressions%2Fstarfish.png?alt=media&token=88cdcf43-d9f8-4eb9-a0a7-fbb273f69f69'
const pinkSunglassesURL='https://firebasestorage.googleapis.com/v0/b/symphonease-pilot-f30e7.appspot.com/o/images%2Fconfetti%2FsummerProgressions%2Fsunglasses_1.png?alt=media&token=20f84a27-2906-4fd9-b92f-99f5d5dd308c'
const redSunglassesURL='https://firebasestorage.googleapis.com/v0/b/symphonease-pilot-f30e7.appspot.com/o/images%2Fconfetti%2FsummerProgressions%2Fsunglasses_2.png?alt=media&token=9710098d-07e2-4318-88a2-67fb4ee6780e'
const surfBoardURL='https://firebasestorage.googleapis.com/v0/b/symphonease-pilot-f30e7.appspot.com/o/images%2Fconfetti%2FsummerProgressions%2Fsurfing-board.png?alt=media&token=735b4d0e-24d7-407b-8a0b-431578fe2ccb'
const swimsuitURL='https://firebasestorage.googleapis.com/v0/b/symphonease-pilot-f30e7.appspot.com/o/images%2Fconfetti%2FsummerProgressions%2Fswim-suit.png?alt=media&token=78978a43-8a4d-4aea-b4e3-8928ba9b5078'
const umbrellaURL='https://firebasestorage.googleapis.com/v0/b/symphonease-pilot-f30e7.appspot.com/o/images%2Fconfetti%2FsummerProgressions%2Fumbrella.png?alt=media&token=54ae25a4-da01-4799-86d8-5ad220f10eed'
const waveURL='https://firebasestorage.googleapis.com/v0/b/symphonease-pilot-f30e7.appspot.com/o/images%2Fconfetti%2FsummerProgressions%2Fwave.png?alt=media&token=8ca05db0-3bc2-46dd-9858-edbb6eef9640'

function randomInRange(min, max) {
  return Math.random() * (max - min) + min;
}

const count = 200,
  defaults = {
    origin: { y: 0.7 },
  };

function fire(particleRatio, opts) {
	if (window.confetti === undefined) {
		return;
	}
  window.confetti(
    Object.assign({}, defaults, opts, {
      particleCount: Math.floor(count * particleRatio),
    })
  );
}

export default function artiztaConfetti(e, mode='confetti') {
	let theEvent = e;
	if (e === null || e === undefined) {
		theEvent = {clientX: window.innerWidth/2, clientY: window.innerHeight/2};
	}

	if (mode === 'confetti') {
		shootConfetti(theEvent);
	}
	else if (mode === 'stars') {
		shootStars(theEvent);
	}
	else if (mode === 'beach') {
		beachConfetti(theEvent);
	}
}

const shootConfetti = (e) => {
	fire(0.25, {
		spread: 26,
		startVelocity: 55,
		origin: { x: e.clientX/window.innerWidth, y: e.clientY/window.innerHeight }
	});

	fire(0.2, {
		spread: 60,
		origin: { x: e.clientX/window.innerWidth, y: e.clientY/window.innerHeight }
	});

	fire(0.35, {
		spread: 100,
		decay: 0.91,
		scalar: 0.8,
		origin: { x: e.clientX/window.innerWidth, y: e.clientY/window.innerHeight }
	});

	fire(0.1, {
		spread: 120,
		startVelocity: 25,
		decay: 0.92,
		scalar: 1.2,
		origin: { x: e.clientX/window.innerWidth, y: e.clientY/window.innerHeight }
	});

	fire(0.1, {
		spread: 120,
		startVelocity: 45,
		origin: { x: e.clientX/window.innerWidth, y: e.clientY/window.innerHeight }
	});
}

const starsDefaults = {
  spread: 360,
  ticks: 50,
  gravity: 0,
  decay: 0.94,
  startVelocity: 30,
  shapes: ["star"],
  colors: ["FFE400", "FFBD00", "E89400", "FFCA6C", "FDFFB8"],
};

function shoot(e) {
  window.confetti({
    ...starsDefaults,
    particleCount: 40,
    scalar: 1.2,
    shapes: ["star"],
		origin: { x: e.clientX/window.innerWidth, y: e.clientY/window.innerHeight }
  });

  window.confetti({
    ...starsDefaults,
    particleCount: 10,
    scalar: 0.75,
    shapes: ["circle"],
		origin: { x: e.clientX/window.innerWidth, y: e.clientY/window.innerHeight }
  });
}

const shootStars = (e) => {
	setTimeout(()=>shoot(e), 0);
	setTimeout(()=>shoot(e), 100);
	setTimeout(()=>shoot(e), 200);
}

const beachConfetti = (e) => {
	window.confetti({
		spread: 360,
		ticks: 100,
		gravity: .1,
		decay: .96,
		startVelocity: 29,
		particleCount: 110,
		scalar: 5,
		shapes: ["image"],
		shapeOptions: {
			image: [{
					src: ballURL,
					width: 32,
					height: 32,
				},
				{
					src: crabURL,
					width: 32,
					height: 32,
				},
				{
					src: lifeBouyURL,
					width: 32,
					height: 32,
				},
				{
					src: mermaidURL,
					width: 32,
					height: 32,
				},
				{
					src: pantsURL,
					width: 32,
					height: 32,
				},
				{
					src: policeURL,
					width: 40,
					height: 40,
				},
				{
					src: poseidonURL,
					width: 32,
					height: 32,
				},
				{
					src: sharkURL,
					width: 48,
					height: 48,
				},
				{
					src: sodaCanURL,
					width: 32,
					height: 32,
				},
				{
					src: sunscreenURL,
					width: 32,
					height: 32,
				},
				{
					src: fish1URL,
					width: 32,
					height: 32,
				},
				{
					src: fish2URL,
					width: 32,
					height: 32,
				},
				{
					src: seasnailURL,
					width: 32,
					height: 32,
				},
				{
					src: seashell1URL,
					width: 32,
					height: 32,
				},
				{
					src: seashell2URL,
					width: 32,
					height: 32,
				},
				{
					src: starfishURL,
					width: 42,
					height: 42,
				},
				{
					src: pinkSunglassesURL,
					width: 32,
					height: 32,
				},
				{
					src: redSunglassesURL,
					width: 32,
					height: 32,
				},
				{
					src: surfBoardURL,
					width: 32,
					height: 32,
				},
				{
					src: swimsuitURL,
					width: 32,
					height: 32,
				},
				{
					src: umbrellaURL,
					width: 32,
					height: 32,
				},
				{
					src: waveURL,
					width: 48,
					height: 48,
				},
			],
		},
	});
}
