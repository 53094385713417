// React imports
import React, { useRef, useContext, useCallback, useState } from 'react';
import { useDrag, useDrop } from "react-dnd"

// material
import { Typography, Grid } from '@mui/material';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';

// Project
import { ItemTypes } from '../../sandbox/DragNDropDemo/ItemTypes';
import ButtonGroupBar from '../../../common/ButtonGroupBar';
import ButtonGroupButton from '../../../common/ButtonGroupButton';
import { WorksHoverContext } from './EditEventWorksComponent.js';

export default function DisplayEventWork ({eventWork, index, onReorder, onEdit, onDelete}) {
	const [hoverIndicatorIndex, setHoverIndicatorIndex] = useContext(WorksHoverContext);

	const ref = useRef(null)
	
	const [{ handlerId }, drop] = useDrop({
		accept: ItemTypes.GRID,
		drop(item) {
			const itemOrder = item.order;
			const eventWorkOrder = eventWork.order;
			onReorder(itemOrder, eventWorkOrder);
			setHoverIndicatorIndex(null);
		},
		collect(monitor) {
			return {
				handlerId: monitor.getHandlerId(),
			}
		},
		hover(item, monitor) {
			if (!ref.current) {
				return
			}
			const dragIndex = item.index
			const hoverIndex = index

			// Determine rectangle on screen
			const hoverBoundingRect = ref.current?.getBoundingClientRect()
			// Get vertical middle
			const hoverMiddleY =
				(hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
			// Determine mouse position
			const clientOffset = monitor.getClientOffset()
			// Get pixels to the top
			const hoverClientY = clientOffset.y - hoverBoundingRect.top

			// Time to actually perform the action
			if (dragIndex !== hoverIndex) {
				setHoverIndicatorIndex(hoverIndex);
			}
			//moveEventWork(dragIndex, hoverIndex)
			// Note: we're mutating the monitor item here!
			// Generally it's better to avoid mutations,
			// but it's good here for the sake of performance
			// to avoid expensive index searches.
			item.index = hoverIndex
		},
	});

	const [{ isDragging }, drag] = useDrag({
		type: ItemTypes.GRID,
		/*
		item: () => {
			return { id, index }
		},
		*/
		item: eventWork,
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
	})

	const opacity = isDragging ? 0 : 1

	drag(drop(ref))

	return (
		<Grid ref={ref} data-handler-id={handlerId} item container direction='column' >
			<Grid item container direction='row' key={`ew-${eventWork.order}`} borderTop={hoverIndicatorIndex===eventWork.order ? 3 : 0}
				// sx={{backgroundColor:eventWork.isIntermission ? artiztaLightDimGrey : artiztaPaperBackground}}
				>					
				{eventWork.isIntermission===true ?
				<Grid item container direction='row' justifyContent='center' alignItems='top' xs={11}>
					<Typography><i>Intermission</i></Typography>
				</Grid>
				:
				<React.Fragment>
					<Grid item xs={2.5}>
						{eventWork.title}
					</Grid>
					<Grid item xs={2.5}>
						{eventWork.composer}
					</Grid>
					<Grid item xs={2}>
						{eventWork.duration} Minute{eventWork.duration !== 1 && 's'}					
					</Grid>
					<Grid item xs={4}>
						<Typography fontFamily='Roboto Mono'>{eventWork.instrumentation}</Typography>				
					</Grid>
				</React.Fragment>
				}
				<Grid item xs={1} container justifyContent='flex-end'>
					<Grid item>
					<ButtonGroupBar variant='outline' size='small'>
						{!eventWork.isIntermission &&
							<ButtonGroupButton toolTip={`Edit '${eventWork.title}'`} placement='left' onClick={onEdit}>
								<EditRoundedIcon/>
							</ButtonGroupButton>
						}
						<ButtonGroupButton toolTip={`Remove ${eventWork.title} from this Event`} placement='left-end' onClick={onDelete}>
							<DeleteForeverRoundedIcon/>
						</ButtonGroupButton>
					</ButtonGroupBar>		
					</Grid>	
				</Grid>
			</Grid>
		</Grid>
	)
}