// AuthContext.js
import React, { useState, useEffect } from "react";

// project imports

export const AuthContext = React.createContext();

/**
 * AuthContextProvider
 * This works with the LoginWindow to set the current user
 * This not only stores the user that is returned from Google, but also the person.
 * Here is the format:
 * {
 * 	user: { <- this is the google authentication user, some useful fields:
 * 		accessToken:
 * 		displayName:
 * 		email:
 * 		emailVerified:
 * 		photoURL:
 * 		uid: unique identifier for the user
 * 	}
 * 	person: { <- this is the person that we created and has profile data that we've saved
 * 		email: 
 * 		firstName:
 * 		lastName:
 * 		id: 
 * 		isGlobalAdmin:
 * 	}
 * }
 */
export const AuthContextProvider = ({ children }) => {
	const [currentUser, setCurrentUser] = useState(null);

	return (
		<AuthContext.Provider value={[currentUser, setCurrentUser]}>
			{children}
		</AuthContext.Provider>
	);
};