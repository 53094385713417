import React, { useState, createContext, useEffect } from 'react';
// material
import { Grid, Typography, Box, Divider } from '@mui/material';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

// project imports
import ButtonGroupBar from './ButtonGroupBar.js';
import ButtonGroupButton from './ButtonGroupButton.js';
import artiztaConfetti from '../utils/ArtiztaConfetti.js';

export const ItemContext = createContext();

/**
 * Creates a component that provides an ok and cancel button, and callbacks for each
 * The children need to update the item in real time
 * When the user clicks ok, whatever is in the item will be sent
 * And when the user clicks cancel, the original item will be sent
 * @param {{}} item the item to be edited
 * @param {(item)=>{}} onSave callback function to receive the edited item
 * @param {(item)=>{}} onCancel callback function to determine if cancel was pressed
 * @param {string} title the title of the edit component
 * @param {boolean} overrideSaveDisabled set to true to override the save disabled logic
 */
export default function ArtiztaEditComponent (props) {
	const boxOffset=325;
	const [editingItem, setEditingItem] = useState(null);
	const [saveDisabled, setSaveDisabled] = useState(true);
	const [boxHeight, setBoxHeight] = useState(window.innerHeight-boxOffset);

	useEffect(() => {
		if (editingItem === null && props.item !== null && props.item !== undefined) {
			const clone = structuredClone(props.item);
			setEditingItem(clone);
		}
	}, [props.item]);


	useEffect(() => {
		if (props.overrideSaveDisabled !== undefined && props.overrideSaveDisabled !== null && props.overrideSaveDisabled === true) {
			setSaveDisabled(false);
			return;
		}

		if (JSON.stringify(editingItem) === JSON.stringify(props.item)) {
			setSaveDisabled(true);
		}
		else {
			setSaveDisabled(false);
		}
	}, [editingItem]);

	const displayConfetti = (e) => {
		// determine how different the editing item is from the original item
		let differences=0;
		Object.keys(editingItem).map((key) => {
			if (props.item[key] === undefined || props.item[key] === null) {
				differences++;
			}
		})
		if (differences>4) {
			artiztaConfetti(e, 'confetti');
		}
	}

	return (
		<React.Fragment>
			{editingItem !== null && editingItem !== undefined && 
				<ItemContext.Provider value={[editingItem, setEditingItem]}>
							<Grid container direction='row' justifyContent='space-between' alignItems='center'>
								<Grid item>
									<Typography variant='h2'>{props.title}</Typography>
								</Grid>
								<Grid item>
									<ButtonGroupBar>
										<ButtonGroupButton 
											onClick={(e) => {
													displayConfetti(e);
													props.onSave(editingItem)
												}
											}
											disabled={saveDisabled}
											toolTip={`Save`}
										>
											<CheckRoundedIcon/>
										</ButtonGroupButton>
										<ButtonGroupButton 
											onClick={() => {
												setEditingItem(props.item);
												props.onCancel(props.item);
											}}
											toolTip={`Cancel`}
										>
											<CloseRoundedIcon/>
										</ButtonGroupButton>
									</ButtonGroupBar>
								</Grid>
							</Grid>
							<Divider/>
							<Box sx={{maxHeight:boxHeight+'px', overflowY: "auto"}}>
							{props.children}
							</Box>
				</ItemContext.Provider>
			}
		</React.Fragment>
	)
}