// React imports
import {React, useState, useEffect} from 'react';

// material imports
import { Box, Typography, Divider, IconButton, Tooltip } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';

// project imports
import ContentBackground from '../../common/ContentBackground';
import LocationsClient from './LocationsClient';
import ArtiztaPageTitle from '../../common/ArtiztaPageTitle';
import ArtiztaCard from '../../common/ArtiztaCard';

export default function LocationsPage(props) {
	const [locations, setLocations] = useState(null);

	const fetchLocations = async () => {
		const locationsArray = await LocationsClient.getAll();
		setLocations(locationsArray);
	}

	useEffect(()=>{
		fetchLocations();
	}, []);

	if (locations === null) {
		return (
			<ContentBackground>
				Locations is null. Riiight.
			</ContentBackground>
		);
	}

	const locationItems = locations.map((location) => {
		return(
			<Box key={location.id} >
			<Grid container direction='row' justifyContent='space-between'>
				<Grid xs={8}>
					<Typography fontSize={16}><b>{location.name}</b></Typography>
					<Box sx={{paddingLeft:'1em'}}>
						{location.desc.slice(0,75)}...
					</Box>
				</Grid>
				<Grid xs={3}>
					{location.address.street}<br/>
					{location.address.city}, {location.address.state} {location.address.zip}
				</Grid>
				<Grid xs={1}>
					<Tooltip title={location.websiteURL} placement='top'>
						<IconButton onClick={() => window.open(location.websiteURL)}>
							<OpenInNewRoundedIcon/>
						</IconButton>
					</Tooltip>
				</Grid>
			</Grid>
			<Divider/>
			</Box>
		)
	});

	return (
		<ContentBackground>
			<ArtiztaCard>
				<ArtiztaPageTitle>Locations</ArtiztaPageTitle>
				<Divider/>
				{locationItems}
			</ArtiztaCard>
		</ContentBackground>
	)
}