
// project imports
import APIClientManager from "../../utils/APIClientManager";

export default class DanielsOnlineClient {

	static url = 'daniels'

	static searchForComposerNameAndTitle = async (danielsOnlineID, danielsOnlineAPIKey, composerName, title, fullDetails=false, callback=null) => {

		// headers
		const params={};

		params.id=danielsOnlineID;
		params.token=danielsOnlineAPIKey;
		if (composerName !== null && composerName !== undefined) {
			params.composer=composerName;
		}
		if (title !== null && title !== undefined) {
			params.title=title;
		}

		if (fullDetails) {
			params.fullDetails='true';
		}
		const result = await APIClientManager.get(DanielsOnlineClient.url, params)
		const json = await result.json();
		if (callback !== null) {
			callback(json);
		}
		return json;
	}

	static getWorkForID = async (danielsOnlineID, danielsOnlineAPIKey, workID) => {

		// headers
		const params={};

		params.id=danielsOnlineID;
		params.token=danielsOnlineAPIKey;

		const result = await APIClientManager.get(`${DanielsOnlineClient.url}/${workID}`, params)
		const json = await result.json();

		return json;
	}

	static getWorkFromDanielsRecord = (danielsOnlineRecord) => {
		var description='';
		if (danielsOnlineRecord.details.remarks !== null && 
				danielsOnlineRecord.details.remarks!== undefined && 
				danielsOnlineRecord.details.remarks!== '') {
					
			description=`Remarks: ${danielsOnlineRecord.details.remarks}`;

			if ((danielsOnlineRecord.details.additional_info !== null && 
					danielsOnlineRecord.details.additional_info!== undefined && 
					danielsOnlineRecord.details.additional_info!== '')) {
				description+='\n';
			}
		}
		if ((danielsOnlineRecord.details.additional_info !== null && 
				danielsOnlineRecord.details.additional_info!== undefined && 
				danielsOnlineRecord.details.additional_info!== '')) {
			description+=`Additional Info: ${danielsOnlineRecord.details.additional_info}`;
		}
		const newWork = {
			title:danielsOnlineRecord.title,
			composer:danielsOnlineRecord.composer,
			instrumentation:danielsOnlineRecord.details.formula,
			percussionInfo:danielsOnlineRecord.details.perc_info,
			duration:danielsOnlineRecord.details.duration,
			desc:description,
			daniels:danielsOnlineRecord
		}

		return newWork;
	}
}