
// material
import {IconButton, Tooltip} from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project imports

/** ArtiztaIconButton
	This is intended to be a simple Icon Button but simulates the 'contained' variant
	usage: 
		@param {function} onClick function to be called when the button is clicked
		@param {string} toolTip string that is used for the tooltip
		@param {string} placement the ToolTip Placement, defaults to 'bottom' if not specified
	The child should be some Icon and will be rendered. 
*/
export default function ArtiztaIconButton({children, onClick, toolTip, placement='bottom', disabled=false}) {
	const theme = useTheme();

	return (
		<Tooltip title={toolTip} placement={placement}>
			<span>
				<IconButton 
					disabled={disabled}
					color='primary' 
					sx={{
							borderRadius:10,
							backgroundColor: theme.palette.primary.main,
							color: theme.palette.primary.contrastText,
							"&:hover": { backgroundColor: theme.palette.primary.dark }
						}}
					onClick={onClick}>
					{children}
				</IconButton>
			</span>
		</Tooltip>
	);
} 
