import dayjs from 'dayjs';

// project imports
import APIClientManager from '../../utils/APIClientManager';

/**
 * 	EventsClient encapsulates the API calls to Events
 * 		getAll - returns all events for an organization. 
 *    get - returns an event given an ID
 *
 */

export default class EventsClient {
	/**
	 * get all the events for firebase
	 * @param {{}} organization - this is the organization to use, required, and it needs to have a valid ID
	 * @param {string} season - optionally pass in a season and will return events only for that season
	 */
	static getAll = async (organization, season=null) => {
		if (organization !== null && organization !== undefined) {
			let url = `events/${organization.id}`;
			if (season!==null) {
				url = `${url}/?season=${season}`;
			}
			const response = await APIClientManager.get(url);
			const json = await response.json();
			return json;
		}
	}
	
	static delete = async (organization, event) => {
		const response = await APIClientManager.delete(`events/${organization.id}/${event.id}`);
		const json = await response.json();
		return json;
	}
	
	static get = async (organization, id) => {
		const response = await APIClientManager.get(`events/${organization.id}/${id}`);
		const json = await response.json();
		return json;
	}

	static sortPerformances = (event) => {
		if (event.performances !== null && event.performances !== undefined) {
			const sortedPerformances = event.performances.sort((a, b) => {
				const aDateJS = dayjs(a.date);
				const bDateJS = dayjs(b.date);
				if (aDateJS.isBefore(bDateJS)) {
					return -1;
				}
				if (aDateJS.isAfter(bDateJS)) {
					return 1;
				}
				return 0;
			})
			event.performances = sortedPerformances;
		}
	}

	static save = async (organization, event) => {
		if (event.new) {
			delete event.new;
		}
		// order the performances
		EventsClient.sortPerformances(event);
		if (event.id === null || event.id === undefined) {
			return EventsClient.add(organization, event);
		}
		const response = await APIClientManager.put(`events/${organization.id}/${event.id}`, event);
		const json = await response.json();
		return json;
	}

	static add = async (organization, event) => {
		if (event.new) {
			delete event.new;
		}
		const response = await APIClientManager.post(`events/${organization.id}`, event);
		const json = await response.json();
		return json;
	}

	static isEventInRange = (event, startDate, endDate) => {
		// need to check the performances
		const performances = event.performances;
		if (performances !== null && performances !== undefined) {
			for (let i=0; i<performances.length; i++) {
				const performance = performances[i];
				if (performance.date === null || performance.date === undefined) {
					return false;
				}
				if (dayjs(performance.date).isBetween(dayjs(startDate), dayjs(endDate), 'day', '[]')) {
					return true;
				}
			}
		}
		return false;
	}

	static findEventsInRange = (events, startDate, endDate) => {
		
		const filterEvent = (event) => {
			return EventsClient.isEventInRange(event, startDate, endDate);
		}

		const eventsInRange = events.filter(filterEvent);

		if (eventsInRange !== undefined && eventsInRange !== null) {
			eventsInRange.sort(EventsClient.compareEvents);
			return eventsInRange;
		}
		else {
			return null;
		}
	}

	static earliestPerformanceDate = (event) => {
		var returnValue = null;
		event.performances.map((performance)=>{
			if (returnValue == null) {
				returnValue = performance.date;
			}
			else {
				if (dayjs(performance.date).isBefore(dayjs(returnValue),'hour')) {
					returnValue = performance.date;
				}
			}
		})
		return returnValue;
	}

	static getFirstPerformanceDate = (event) => {
		const performances = event.performances;
		if (performances !== null && performances !== undefined) {
			if (performances.length > 0) {
				return performances[0].date;
			}
		}
		return null;
	}

	static compareEvents = (a, b) => {
		// logic: order by start dates and if they are the same, then order by name
		const aStartDateJS = dayjs(EventsClient.earliestPerformanceDate(a));
		const bStartDateJS = dayjs(EventsClient.earliestPerformanceDate(b));

		if (aStartDateJS.isBefore(bStartDateJS)) {
			return -420;
		}
		else if (aStartDateJS.isAfter(bStartDateJS)) {
			return 420;
		}
		else {
			return 0;
		}
	}
}